export function getClickPath(el: HTMLElement) {
  const parent = el.parentElement;

  if (el.hasAttribute('aria-label')) {
    return '~~> ' + el.getAttribute('aria-label');
  }

  let elementDesc = el.tagName.toLocaleLowerCase();
  if (el.id) {
    elementDesc += '#' + el.id;
  } else if (el.classList && el.classList.length) {
    elementDesc += '.' + [].slice.apply(el.classList).join('.');
  }

  if (el && el.tagName.toLocaleLowerCase() === 'button') {
    const btnText = el.textContent;
    if (btnText) {
      elementDesc += '=' + btnText.trim();
    }
  }

  let path = [elementDesc];

  if (parent && parent.tagName.toLocaleLowerCase() === 'button') {
    const btnText = parent.textContent;
    if (btnText) {
      path = [btnText];
    }
  }

  while ((el = el.parentElement) && path.length < 3) {
    if (el.hasAttribute('aria-label')) {
      return '~~> ' + el.getAttribute('aria-label');
    }

    const tagName = el.tagName.toLocaleLowerCase();
    if (el.id) {
      path.push('#' + el.id);
    } else if (tagName.startsWith('tg-') && tagName !== 'tg-root') {
      path.push(tagName);
    }
  }

  return path.reverse().join(' > ');
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function retrieveValidationErrorMessage(err, path = []): string {
  path.push(err.property);
  if (err.children && err.children.length > 0) {
    return this.retrieveValidationErrorMessage(err.children[0], path);
  } else {
    return (
      path.join(' > ') +
      ': ' +
      Object.keys(err.constraints)
        .map(k => err.constraints[k])
        .join('; \n')
    );
  }
}

export function isClassValidatorMessage(error) {
  // console.log(error)
  return error.status === 400 && Array.isArray(error.error);
}

export function parseClassValidatorErrorMessage(error) {
  let details = '';
  for (const info of error.error) {
    details += retrieveValidationErrorMessage(info);
  }
  return details;
}
