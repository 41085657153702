import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { getClickPath } from './lib/utils';
import { SharedModule } from './shared/shared.module';
import { SiteModule } from './site/site.module';
import { SitesModule } from './sites/sites.module';

Sentry.init({
  dsn: 'https://e6e47f6b47b2447eb3a2a3c75992a8e8@sentry.io/1385135',
  maxBreadcrumbs: 150,
  enabled: environment.production,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'ui.click') {
      const { target }: { target: HTMLElement } = hint.event;
      // log button name
      try {
        const msg = getClickPath(target);
        if (msg) {
          breadcrumb.message = msg;
        }
      } catch (e) {
        breadcrumb.message = target.tagName;
      }

      // console.log(breadcrumb.message, {target});
    }
    return breadcrumb;
  },
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, SharedModule, CoreModule, AppRoutingModule, SitesModule, SiteModule],
  providers: [{ provide: ErrorHandler, useClass: SentryErrorHandler }],
  bootstrap: [AppComponent],
})
export class AppModule {}
