import Quill from 'quill';
import { AttachItemResponse } from '../../../../../api';
import { CachedAttach } from './cached-attach';

export class TeleplayEmbed extends CachedAttach {
  static blotName = 'attachTeleplay';
  static tagName = 'figure';

  static initSdk(callback) {
    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return callback();
      js = d.createElement(s);
      js.src = 'https://tp.telepost.me/assets/teleplay-game.js';
      js.id = id;
      js.onload = callback;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'teleplay-sdk');
  }

  static render(node: HTMLElement, value: AttachItemResponse) {
    super.render(node, value);
    node.classList.remove(value.width);

    let extra = JSON.parse(value.embedExtra);
    node.innerHTML = `
    <div class="frame">
      <div class="tp-element" data-id="${extra.info._id}"></div>
    </div>
    `;

    setTimeout(() => {
      this.initSdk(() => {
        if (window['___tp__showGame']) {
          window['___tp__showGame'](node.querySelector('.tp-element'));
        }
      });
    }, 20);

    return node;
  }
}

Quill.register(TeleplayEmbed);
