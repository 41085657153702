/**
 * Telesite
 * Platform for fast start your site.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AttachItemResponse } from '../model/attachItemResponse';
import { AttachListResponse } from '../model/attachListResponse';
import { AttachMetaDto } from '../model/attachMetaDto';
import { EmbedCreateDto } from '../model/embedCreateDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AttachService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Delete attach
     * 
     * @param attachId 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(attachId: string, postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public _delete(attachId: string, postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public _delete(attachId: string, postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public _delete(attachId: string, postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attachId === null || attachId === undefined) {
            throw new Error('Required parameter attachId was null or undefined when calling _delete.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling _delete.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling _delete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}/attaches/${encodeURIComponent(String(attachId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create embedded
     * 
     * @param EmbedCreateDto 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEmbed(EmbedCreateDto: EmbedCreateDto, postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<AttachItemResponse>;
    public createEmbed(EmbedCreateDto: EmbedCreateDto, postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AttachItemResponse>>;
    public createEmbed(EmbedCreateDto: EmbedCreateDto, postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AttachItemResponse>>;
    public createEmbed(EmbedCreateDto: EmbedCreateDto, postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (EmbedCreateDto === null || EmbedCreateDto === undefined) {
            throw new Error('Required parameter EmbedCreateDto was null or undefined when calling createEmbed.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling createEmbed.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling createEmbed.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AttachItemResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}/attaches/embedded`,
            EmbedCreateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload attach image
     * 
     * @param photo 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPhoto(photo: Blob, postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<AttachItemResponse>;
    public createPhoto(photo: Blob, postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AttachItemResponse>>;
    public createPhoto(photo: Blob, postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AttachItemResponse>>;
    public createPhoto(photo: Blob, postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (photo === null || photo === undefined) {
            throw new Error('Required parameter photo was null or undefined when calling createPhoto.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling createPhoto.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling createPhoto.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (photo !== undefined) {
            formParams.append('photo', <any>photo);
        }

        return this.httpClient.post<AttachItemResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}/attaches/photo`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get post attaches list
     * 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAll(postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<AttachListResponse>;
    public getAll(postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AttachListResponse>>;
    public getAll(postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AttachListResponse>>;
    public getAll(postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling getAll.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling getAll.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AttachListResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}/attaches`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update attach
     * 
     * @param attachId 
     * @param AttachMetaDto 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMeta(attachId: string, AttachMetaDto: AttachMetaDto, postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<AttachItemResponse>;
    public updateMeta(attachId: string, AttachMetaDto: AttachMetaDto, postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AttachItemResponse>>;
    public updateMeta(attachId: string, AttachMetaDto: AttachMetaDto, postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AttachItemResponse>>;
    public updateMeta(attachId: string, AttachMetaDto: AttachMetaDto, postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attachId === null || attachId === undefined) {
            throw new Error('Required parameter attachId was null or undefined when calling updateMeta.');
        }

        if (AttachMetaDto === null || AttachMetaDto === undefined) {
            throw new Error('Required parameter AttachMetaDto was null or undefined when calling updateMeta.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling updateMeta.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling updateMeta.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<AttachItemResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}/attaches/${encodeURIComponent(String(attachId))}`,
            AttachMetaDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
