/**
 * Telesite
 * Platform for fast start your site.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CreateSiteDto { 
    name: string;
    titleTemplate?: string;
    subDomain: string;
    lang?: CreateSiteDto.LangEnum;
}
export namespace CreateSiteDto {
    export type LangEnum = 'ru' | 'en';
    export const LangEnum = {
        Ru: 'ru' as LangEnum,
        En: 'en' as LangEnum
    };
}
