<mat-toolbar>
  Черновики
</mat-toolbar>
<div class="posts-list" style="padding: 32px">
  <div *ngIf="posts$ | async as posts; else loading">
    <table style="width: 100%;" class="posts-table">
      <thead>
        <tr>
          <th width="150">Тип</th>
          <th>Заголовок</th>
          <th width="200">Фото</th>
          <th width="200">Дата</th>
          <th width="300">~</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let post of posts.items">
          <td style="text-align: center;">{{ post.section?.header }}</td>
          <td>
            <b>{{ post.title }}</b>
            {{ post.subTitle }}
          </td>
          <td style="text-align: center">
            <div *ngIf="post.mainImage">
              <img
                [src]="
                  post.mainImage.photo.sizes
                    ? post.mainImage.photo.sizes.feed250.url
                    : post.mainImage.publicUrl
                "
                style="max-width: 150px; max-height: 50px;"
              />
            </div>
          </td>
          <td style="text-align: center">
            <b>{{ post.createdAt | date: 'medium' }}</b> <br />
            {{ post.creator.displayName }}
          </td>
          <td style="text-align: center">
            <a
              mat-stroked-button
              routerLink="../editor-v2/{{ post.id }}"
              *ngIf="!post.legacy"
              style="margin-right: 16px"
              >Редактировать</a
            >
            <a color="warn" mat-stroked-button (click)="remove(post.id)" *ngIf="!post.legacy">Удалить</a>
            <br />
            <span *ngIf="post.legacy">LEGACY</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #loading>
    <mat-progress-spinner
      style="margin: 0 auto"
      mode="indeterminate"
      [strokeWidth]="4"
    ></mat-progress-spinner>
  </ng-template>
</div>
