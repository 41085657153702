import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import * as Sentry from '@sentry/browser';

import Quill from 'quill';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { AttachItemResponse } from '../../../../api';
import { capitalize } from '../../../../lib/utils';
import { NgxInstService } from '../../../../shared/ngx-inst.service';
import { AttachDeleted } from '../../../state/post-editor.actions';
import { PostEditorState } from '../../../state/post-editor.state';
import { DragService } from '../../drag.service';
import { LinkDialogComponent } from '../link-dialog/link-dialog.component';
import './blots/facebook';
import './blots/hr';
import './blots/instagram';
import './blots/photo';
import './blots/teleplay';
import './blots/twitter';
import './blots/vimeo';
import './blots/youtube';
import { DropLayer, DropLayerResult } from './drop-layer';

const icons = Quill.import('ui/icons');
icons.header[3] = `<svg viewBox="0 0 18 18">
<path class="ql-fill" d="M16.65186,12.30664a2.6742,2.6742,0,0,1-2.915,2.68457,3.96592,3.96592,0,0,1-2.25537-.6709.56007.56007,0,0,1-.13232-.83594L11.64648,13c.209-.34082.48389-.36328.82471-.1543a2.32654,2.32654,0,0,0,1.12256.33008c.71484,0,1.12207-.35156,1.12207-.78125,0-.61523-.61621-.86816-1.46338-.86816H13.2085a.65159.65159,0,0,1-.68213-.41895l-.05518-.10937a.67114.67114,0,0,1,.14307-.78125l.71533-.86914a8.55289,8.55289,0,0,1,.68213-.7373V8.58887a3.93913,3.93913,0,0,1-.748.05469H11.9873a.54085.54085,0,0,1-.605-.60547V7.59863a.54085.54085,0,0,1,.605-.60547h3.75146a.53773.53773,0,0,1,.60547.59375v.17676a1.03723,1.03723,0,0,1-.27539.748L14.74854,10.0293A2.31132,2.31132,0,0,1,16.65186,12.30664ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z"/>
  </svg>`;
icons.header[4] = `<svg viewBox="0 0 18 18">
  <path class="ql-fill" d="M10,4V14a1,1,0,0,1-2,0V10H3v4a1,1,0,0,1-2,0V4A1,1,0,0,1,3,4V8H8V4a1,1,0,0,1,2,0Zm7.05371,7.96582v.38477c0,.39648-.165.60547-.46191.60547h-.47314v1.29785a.54085.54085,0,0,1-.605.60547h-.69336a.54085.54085,0,0,1-.605-.60547V12.95605H11.333a.5412.5412,0,0,1-.60547-.60547v-.15332a1.199,1.199,0,0,1,.22021-.748l2.56348-4.05957a.7819.7819,0,0,1,.72607-.39648h1.27637a.54085.54085,0,0,1,.605.60547v3.7627h.33008A.54055.54055,0,0,1,17.05371,11.96582ZM14.28125,8.7207h-.022a4.18969,4.18969,0,0,1-.38525.81348l-1.188,1.80469v.02246h1.5293V9.60059A7.04058,7.04058,0,0,1,14.28125,8.7207Z"/>
</svg>`;

const Clipboard = Quill.import('modules/clipboard');

class PlainTextClipboard extends Clipboard {
  onPaste(e) {
    let range = this.quill.getSelection();
    if (!range) {
      return;
    }
    const leftBlock = document.querySelector('html');
    const scrollTop = leftBlock.scrollTop;
    super.onPaste(e);
    setTimeout(() => {
      if (scrollTop) {
        leftBlock.scrollTop = scrollTop;
      }
    });
  }
}

Quill.register('modules/clipboard', PlainTextClipboard, true);

@Component({
  selector: 'tsa-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class QuillEditorComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('container', { static: true }) container: ElementRef<HTMLDivElement>;

  @Input()
  control: FormControl;

  quill: Quill;

  destroy$ = new Subject();

  lastScrollTop = 0;

  post = this.store.selectSnapshot(PostEditorState.post);

  private dropLayer: DropLayer;

  constructor(
    public ds: DragService,
    private actions$: Actions,
    private store: Store,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private _zone: NgZone,
    private instagram: NgxInstService
  ) {}

  ngOnInit() {
    this.ds.attachChanges.pipe(takeUntil(this.destroy$)).subscribe(attachData => {
      this.checkQuillAttaches(attachData);
    });

    this.ds.dragAttach.pipe(takeUntil(this.destroy$)).subscribe(({ action, attach }) => {
      console.log('dragAttach', action, attach);
      if (action === 'start') {
        this.enableBlockMode();
        this.createDropZoneLayer(drop => {
          let index = this.quill.getIndex(drop.line);

          // attachPhoto, attachYoutube etc
          let embedName = `attach${capitalize(attach.kind)}`;
          if (drop.before) {
            this.quill.insertEmbed(index, embedName, attach);
          } else {
            index = index + drop.line.length();
            this.quill.insertEmbed(index, embedName, attach);
          }
          this.disableBlockMode();
          this.deleteDropLayer();
        });
      }
      if (action === 'end') {
        this.disableBlockMode();
        this.deleteDropLayer();
        this.instagram.reloadInstagramScript();
      }
    });

    this.ds.blockMode
      .pipe(
        skip(1),
        takeUntil(this.destroy$)
      )
      .subscribe(rs => {
        if (rs) {
          this.enableBlockMode();
        } else {
          this.disableBlockMode();
        }
      });

    this.actions$
      .pipe(
        takeUntil(this.destroy$),
        ofActionDispatched(AttachDeleted)
      )
      .subscribe(res => {
        console.log('AttachDeleted', res.payload);
        this.removeAttach(res.payload);
      });
  }

  dropRoot(event) {
    return false;
  }

  enableBlockMode() {
    console.log('enableBlockMode');
    this.quill.enable(false);
    this.makeLinesDraggable();
  }

  makeLinesDraggable() {
    let lines = this.quill.getLines();
    for (let line of lines) {
      // console.log(line);
      let node = line.domNode as HTMLElement;
      if (node.tagName === 'LI') {
        if (line.prev === null) {
          node = line.parent.domNode;
          line = line.parent;
        } else {
          continue;
        }
      }
      node.setAttribute('draggable', 'true');
      node.ondragstart = (ev: DragEvent) => {
        ev.dataTransfer.effectAllowed = 'move';
        if (ev.target && ev.target['style']) {
          ev.target['style'].backgroundColor = '#f0f8ff';
        }
        setTimeout(() =>
          this.createDropZoneLayer(rs => {
            if (rs.before) {
              rs.node['parentNode'].insertBefore(line.domNode, rs.node);
            } else {
              rs.node['parentNode'].insertBefore(line.domNode, rs.node['nextSibling']);
            }
            this.quill.update('api');
            this.deleteDropLayer();
            if (ev.target && ev.target['style']) {
              ev.target['style'].backgroundColor = 'transparent';
            }
          })
        );
      };
      node.ondragend = (ev: DragEvent) => {
        this.deleteDropLayer();
        if (ev.target && ev.target['style']) {
          ev.target['style'].backgroundColor = 'transparent';
        }
      };
    }
  }

  disableDragLines() {
    for (let line of this.quill.getLines()) {
      let node = line.domNode as HTMLElement;
      if (node.tagName === 'LI') {
        if (line.prev === null) {
          node = line.parent.domNode;
        } else {
          continue;
        }
      }
      node.setAttribute('draggable', 'false');
    }
  }

  createDropZoneLayer(callback: (rs: DropLayerResult) => any) {
    this.dropLayer = new DropLayer(this.container.nativeElement, this.quill);
    this.dropLayer.drop.pipe(takeUntil(this.destroy$)).subscribe(callback);
  }

  deleteDropLayer() {
    this.dropLayer.deleteDropZoneLayer();
  }

  disableBlockMode() {
    console.log('disableBlockMode');
    this.disableDragLines();
    this.quill.enable(true);
  }

  ngOnChanges() {}

  ngAfterViewInit() {
    const hrHandler = range => {
      this.quill.deleteText(range.index - 3, 3);
      this.quill.insertEmbed(range.index - 3, 'hr', '');
      this.quill.setSelection(range.index - 2, 0);
    };

    let bindings = {
      // This will overwrite the default binding also named 'tab'
      enter: {
        key: 13,
        collapsed: true,
        format: { hr: false },
        prefix: /^[*]{3}$/,
        offset: 3,
        handler: function(range) {
          hrHandler(range);
        },
      },
      frenchQuotes: {
        key: 50,
        shortKey: true,
        shiftKey: true,
        handler: (range, context) => {
          console.log(range, context);
          let contents = this.quill.getContents();
          let ops = contents.map(op => {
            if (typeof op.insert === 'string') {
              op.insert = op.insert
                .replace(/(^|\s)'([\wа-яії])/gi, '$1«$2') // opening single
                .replace(/([\wа-яії\.])'($|\s|\.|\,)/gi, '$1»$2') // closing single
                .replace(/(^|\s)"([\wа-яії])/gi, '$1«$2') // opening double
                .replace(/([\wа-яії\.])"($|\s|\.|\,)/gi, '$1»$2') // closing double
                .replace(/--/g, '\u2014'); // em-dash
            }
            return op;
          });
          this.quill.setContents({ ops: ops } as any, 'api');
        },
      },
    };

    let quill = new Quill(this.container.nativeElement, {
      placeholder: 'Введите текст',
      theme: 'bubble',
      formats: [
        'bold',
        'italic',
        'underline',
        'strike',
        'code',
        'link',
        'header',
        'align',
        'blockquote',
        'textBreak',
        'attachPhoto',
        'attachTwitter',
        'attachYoutube',
        'attachVimeo',
        'attachInstagram',
        'attachFacebook',
        'attachTeleplay',
        'hr',
        'code-block',
        'list',
      ],
      modules: {
        toolbar: {
          container: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ header: 2 }, { header: 3 }, { header: 4 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            ['link'],
            ['blockquote'],
            ['clean'],
          ],
          handlers: {
            // handlers object will be merged with default handlers object
            link: value => {
              const range = this.quill['selection'].savedRange;
              const text = this.quill.getText(range.index, range.length);
              const format = this.quill.getFormat(range);
              if (value) {
                this.processLinkUpdate(text, format.link, range);
              }
            },
            align: value => {
              const scrollBlock = document.querySelector('html');
              this.quill.format('align', value, 'user');
              scrollBlock.scrollTop = this.lastScrollTop;
              // setTimeout(() => {
              //   if (this.lastScrollTop) {
              //     scrollBlock.scrollTop = this.lastScrollTop;
              //   }
              // });
            },
          },
        },
        keyboard: {
          bindings: bindings,
        },
      },
    });

    this.quill = quill;

    quill.keyboard.addBinding(
      { key: ' ' },
      {
        collapsed: true,
        format: { hr: false },
        prefix: /^[*]{3}$/,
        offset: 3,
      },
      function(range, context) {
        hrHandler(range);
      }
    );

    quill.on('text-change', (delta, oldDelta, source) => {
      this.control.setValue(JSON.stringify(quill.getContents().ops));
    });

    quill.on('selection-change', (range, oldRange, source) => {
      if (range) {
        if (range.length == 0) {
          const [link, offset] = (this.quill['editor'] as any).scroll.descendant(
            bolt => bolt && bolt.statics.blotName === 'link',
            range.index
          );
          this.cd.detectChanges();
          this.ds.linkForPreview.next({ range, oldRange, source, link, offset });
        } else {
          var text = quill.getText(range.index, range.length);
          console.log('User has highlighted', text);
        }
      } else {
        const scrollBlock = document.querySelector('html');
        this.lastScrollTop = scrollBlock.scrollTop;
        console.log('Cursor not in the editor');
      }
    });

    if (this.post.legacy) {
      let html = JSON.parse(this.post.legacy).body.replace(/src="(\/[^"]+)"/g, 'src="https://tgraph.io$1"');
      quill.pasteHTML(html, 'silent');
    }
    if (this.control.value) {
      try {
        quill.setContents(JSON.parse(this.control.value), 'silent');
      } catch (e) {
        console.error('cant parse editor text', e);
        Sentry.captureException(e);
      }
    }
  }

  removeAttach(attach: AttachItemResponse) {
    let blotName = `attach${capitalize(attach.kind)}`;
    let contents = this.quill.getContents();
    let newOps = [];
    let update = false;
    for (let o of contents.ops) {
      if (o.insert && o.insert[blotName] && o.insert[blotName].id == attach.id) {
        update = true;
        continue;
      }
      newOps.push(o);
    }

    if (update) {
      this.quill.setContents(newOps as any, 'api');
    }
  }

  checkQuillAttaches({ attach, data }) {
    let blotName = `attach${capitalize(attach.kind)}`;
    let contents = this.quill.getContents();
    let _attachExists = contents.ops.find(
      o => o.insert && o.insert[blotName] && o.insert[blotName].id == attach.id
    );
    if (!_attachExists) {
      return;
    }
    let newOps = [];
    for (let op of contents.ops) {
      if (op.insert && op.insert[blotName] && op.insert[blotName].id == attach.id) {
        op.insert[blotName] = {
          ...attach,
          ...data,
        };
      }
      newOps.push(op);
    }
    this.quill.setContents(newOps as any, 'api');
  }

  editLink(range) {
    const text = this.quill.getText(range.index, range.length);
    const format = this.quill.getFormat(range);
    this._zone.run(() => {
      this.processLinkUpdate(text, format.link, range);
    });
  }

  removeLink(event) {
    this.quill.removeFormat(event.index, event.length);
  }

  processLinkUpdate(text, link, range) {
    this.openLinkDialog({ text, url: link || '' })
      .afterClosed()
      .subscribe(rs => {
        if (rs) {
          this.quill.deleteText(range.index, range.length, 'silent');
          this.quill.insertText(range.index, rs.text, 'link', rs.url, 'api');
        }
      });
  }

  openLinkDialog(item?: { url: string; text: string }) {
    return this.dialog.open(LinkDialogComponent, {
      minWidth: '550px',
      data: item || {},
      position: { top: '20vh' },
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }
}
