<div fxLayout="column" fxLayoutAlign="space-between center" style="height: 100%">
  <div>
    <div style="padding: 15px 10px; font-weight: bold;">
      <a style="font-size: 24px; color: #d54a36; text-decoration: none" routerLink="/sites">TS</a>
    </div>
    <a
      routerLink="create-draft-publication"
      mat-button
      matTooltip="Создать черновик"
      matTooltipPosition="right"
    >
      <mat-icon>create</mat-icon>
    </a>

    <button routerLink="published" mat-button matTooltip="Опубликованые" matTooltipPosition="right">
      <mat-icon>public</mat-icon>
    </button>

    <button routerLink="queue" mat-button matTooltip="Запланированые" matTooltipPosition="right">
      <mat-icon>schedule</mat-icon>
    </button>

    <button routerLink="drafts" mat-button matTooltip="Черновики" matTooltipPosition="right">
      <mat-icon>drafts</mat-icon>
    </button>

    <button mat-button matTooltip="Настройки сайта" matTooltipPosition="right" routerLink="settings">
      <mat-icon>settings</mat-icon>
    </button>
  </div>

  <div class="vertical-logo">
    {{ origin }}
  </div>
</div>
