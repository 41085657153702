import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TagsService } from '../api';
import { CurrentSiteState } from './state/current-site.state';

@Injectable()
export class SiteTagsResolver implements Resolve<any> {
  constructor(private api: TagsService, private store: Store) {}

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    let siteId = this.store.selectSnapshot(CurrentSiteState.id);
    return this.api.getAll(siteId);
  }
}
