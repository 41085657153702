import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'tsa-seo-settings-form',
  templateUrl: './seo-settings-form.component.html',
  styleUrls: [
    './seo-settings-form.component.scss',
    '../feed-settings-form/feed-settings-form.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeoSettingsFormComponent implements OnInit {
  @Input() form: FormGroup;

  @Output() nextTab = new EventEmitter();

  constructor() {}

  ngOnInit() {
    let values = this.form.value;
    if (values.title && !values.pageTitle) {
      this.form.patchValue({ pageTitle: values.title });
    }
    if (values.description && !values.pageDescription) {
      this.form.patchValue({ pageDescription: values.description });
    }
  }
}
