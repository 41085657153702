import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AttachItemResponse } from '../../api';

@Injectable({
  providedIn: 'root',
})
export class DragService {
  blockMode = new BehaviorSubject(false);
  dragAttach = new Subject<{ action: 'start' | 'end'; attach?: AttachItemResponse }>();

  attachChanges: Subject<any> = new Subject();

  linkForPreview: Subject<any> = new Subject();

  constructor() {}
}
