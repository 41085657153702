<h2 mat-dialog-title>Укажите данные ссылки</h2>
<mat-dialog-content>
  <div class="create-button-form" fxLayout="column" fxLayoutGap="16px" fxFlex="1 1 100%" [formGroup]="form">
    <div fxLayout="column" fxFlex="1 1 100%">
      <div fxLayout="row" fxFlex="1 1 100%" fxLayoutGap="8px">
        <mat-form-field appearance="standard" class="inline-selector" *ngIf="lastLinksText.length">
          <mat-select (selectionChange)="selectLinkText($event)">
            <mat-option *ngFor="let item of lastLinksText" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="never" fxFlex="1 1 100%">
          <input
            matInput
            #linkText
            type="text"
            placeholder="Текст ссылки"
            style="line-height: 1.2em"
            [tabindex]="data.text ? -1 : 1"
            formControlName="text"
            (keyup.enter)="save()"
          />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxFlex="1 1 100%" fxLayoutGap="8px">
        <mat-form-field appearance="standard" class="inline-selector" *ngIf="lastLinksUrl.length">
          <mat-select (selectionChange)="selectLinkUrl($event)">
            <mat-option *ngFor="let item of lastLinksUrl" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="never" fxFlex="1 1 100%">
          <input
            #linkUrl
            matInput
            type="text"
            placeholder="URL ссылки (https://telesite.me)"
            style="line-height: 1.2em"
            formControlName="url"
            [tabindex]="data.text ? 1 : -1"
            title="URL ссылки (https://telepost.me)"
            (paste)="onChangeLink($event)"
            (keyup.enter)="save()"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="8px">
    <button mat-button color="secondary" class="add-button" mat-dialog-close>Отмена</button>
    <button
      id="saveCreatedLinkBtn"
      color="primary"
      class="add-button"
      mat-button
      [disabled]="!form.valid"
      (click)="save()"
    >
      Сохранить
    </button>
  </div>
</mat-dialog-actions>
