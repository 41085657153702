/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./site-root.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../components/left-menu/left-menu.component.ngfactory";
import * as i5 from "../../components/left-menu/left-menu.component";
import * as i6 from "@ngxs/store";
import * as i7 from "@angular/router";
import * as i8 from "./site-root.component";
var styles_SiteRootComponent = [i0.styles];
var RenderType_SiteRootComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SiteRootComponent, data: {} });
export { RenderType_SiteRootComponent as RenderType_SiteRootComponent };
export function View_SiteRootComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["fxLayout", "row"], ["fxLayoutAlign", "start stretch"], ["style", "min-height: 100vh"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "tsa-left-menu", [["style", "position: fixed; height: 100%"]], null, null, null, i4.View_LeftMenuComponent_0, i4.RenderType_LeftMenuComponent)), i1.ɵdid(4, 114688, null, 0, i5.LeftMenuComponent, [i6.Store], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["fxFlex", ""], ["style", "padding-left: 80px;"]], null, null, null, null, null)), i1.ɵdid(6, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start stretch"; _ck(_v, 2, 0, currVal_1); _ck(_v, 4, 0); var currVal_2 = ""; _ck(_v, 6, 0, currVal_2); _ck(_v, 8, 0); }, null); }
export function View_SiteRootComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tsa-site-root", [], null, null, null, View_SiteRootComponent_0, RenderType_SiteRootComponent)), i1.ɵdid(1, 114688, null, 0, i8.SiteRootComponent, [i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SiteRootComponentNgFactory = i1.ɵccf("tsa-site-root", i8.SiteRootComponent, View_SiteRootComponent_Host_0, {}, {}, []);
export { SiteRootComponentNgFactory as SiteRootComponentNgFactory };
