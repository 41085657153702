<mat-toolbar color="primary">
  Telesite
  <span style="flex: 1 1 auto;"></span>

  <div style="height: 100%">
    <mat-menu #userTopMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="user-top-menu">
      <a mat-menu-item routerLink="/sites"> <mat-icon mat-list-icon>android</mat-icon> Сайты </a>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="signOut()"><mat-icon>exit_to_app</mat-icon> Выйти</button>
    </mat-menu>

    <button
      mat-button
      #trigger="matMenuTrigger"
      [matMenuTriggerFor]="userTopMenu"
      style="height: 100%;"
      [class.menuOpen]="trigger.menuOpen"
    >
      <ng-container>
        <img class="circle-img" [src]="user.photo" width="40" height="40" alt="" />
        <span fxHide.xs="" style="display: inline-block; max-width: 150px" class="short-string">{{
          user.displayName
        }}</span>
      </ng-container>
    </button>
  </div>
</mat-toolbar>
