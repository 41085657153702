import Quill from 'quill';
import { AttachItemResponse } from '../../../../../api';
import { CachedAttach } from './cached-attach';

export class YoutubeVideo extends CachedAttach {
  static blotName = 'attachVimeo';
  static tagName = 'figure';

  static render(node: HTMLElement, value: AttachItemResponse) {
    // console.log('vimeo render', value);
    super.render(node, value);

    let extra = JSON.parse(value.embedExtra);

    // console.log('extra', extra);

    node.innerHTML = `
    <div class="frame">
      ${extra.html}
    </div>
    <figcaption>
        <div class="caption">${value.caption || ''}</div>
        <div class="copyright">${value.copyright || ''}</div>
    </figcaption>`;

    setTimeout(() => {
      this.updateFrameWidth(node, extra);
    }, 10);

    return node;
  }

  static updateFrameWidth(node, extra) {
    let wrap = node.querySelector('.frame');
    // console.log('updateFrameWidth', wrap.clientWidth, extra.width);
    let frame = wrap.querySelector('iframe');
    frame.setAttribute('width', String(wrap.clientWidth));
    let height = extra.height / (extra.width / wrap.clientWidth);
    frame.setAttribute('height', String(height));
  }

  static updateState(node: HTMLElement, oldData: AttachItemResponse, newData: AttachItemResponse) {
    if (oldData.width !== newData.width) {
      let extra = JSON.parse(newData.embedExtra);
      setTimeout(() => {
        this.updateFrameWidth(node, extra);
      }, 10);
    }
    return super.updateState(node, oldData, newData);
  }
}

Quill.register(YoutubeVideo);
