import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetAuthResult, UserSignInSuccess } from '../../state/user.actions';
import { UserState } from '../../state/user.state';

@Component({
  selector: 'tsa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit() {
    let state = this.store.selectSnapshot(UserState);
    if (state.isAuth) {
      setTimeout(() => this.store.dispatch(new UserSignInSuccess()), 1);
    }
  }

  signIn() {
    // this.store.dispatch(new UserFakeSignIn());

    const url = `${location.origin}/api/auth/google`;
    let width = 610;
    let height = 500;
    let left = screen.width / 2 - width / 2;
    let top = (screen.height / 2 - height / 2) / 2;
    let popup = window.open(
      url,
      '',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, left=${left}, top=${top}`
    );
    // let intnerval = setInterval(() => {
    //   console.log(popup.closed);
    //
    // }, 500)

    window.onmessage = e => {
      console.log(e.data);
      if (e.data && e.data['authResult']) {
        popup.close();
        this.store.dispatch(new SetAuthResult(e.data['authResult']));
      }
    };
  }
}
