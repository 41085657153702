/**
 * Telesite
 * Platform for fast start your site.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddSectionDto } from '../model/addSectionDto';
import { CreateSiteDto } from '../model/createSiteDto';
import { SectionItem } from '../model/sectionItem';
import { SiteCreateResponse } from '../model/siteCreateResponse';
import { SiteGetByIdResponse } from '../model/siteGetByIdResponse';
import { SiteSectionsResponse } from '../model/siteSectionsResponse';
import { SitesListResponse } from '../model/sitesListResponse';
import { UpdateSectionDto } from '../model/updateSectionDto';
import { UpdateSiteDto } from '../model/updateSiteDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SitesService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add sections
     * 
     * @param AddSectionDto 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addSection(AddSectionDto: AddSectionDto, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<SectionItem>;
    public addSection(AddSectionDto: AddSectionDto, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SectionItem>>;
    public addSection(AddSectionDto: AddSectionDto, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SectionItem>>;
    public addSection(AddSectionDto: AddSectionDto, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (AddSectionDto === null || AddSectionDto === undefined) {
            throw new Error('Required parameter AddSectionDto was null or undefined when calling addSection.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling addSection.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SectionItem>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/sections`,
            AddSectionDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create site
     * 
     * @param CreateSiteDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSite(CreateSiteDto: CreateSiteDto, observe?: 'body', reportProgress?: boolean): Observable<SiteCreateResponse>;
    public createSite(CreateSiteDto: CreateSiteDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SiteCreateResponse>>;
    public createSite(CreateSiteDto: CreateSiteDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SiteCreateResponse>>;
    public createSite(CreateSiteDto: CreateSiteDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (CreateSiteDto === null || CreateSiteDto === undefined) {
            throw new Error('Required parameter CreateSiteDto was null or undefined when calling createSite.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SiteCreateResponse>(`${this.basePath}/sites`,
            CreateSiteDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete section
     * 
     * @param sectionId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSection(sectionId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<SectionItem>;
    public deleteSection(sectionId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SectionItem>>;
    public deleteSection(sectionId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SectionItem>>;
    public deleteSection(sectionId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sectionId === null || sectionId === undefined) {
            throw new Error('Required parameter sectionId was null or undefined when calling deleteSection.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling deleteSection.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<SectionItem>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/sections/${encodeURIComponent(String(sectionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get site info by site id
     * 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(siteId: string, observe?: 'body', reportProgress?: boolean): Observable<SiteGetByIdResponse>;
    public getById(siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SiteGetByIdResponse>>;
    public getById(siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SiteGetByIdResponse>>;
    public getById(siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SiteGetByIdResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get site sections
     * 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSections(siteId: string, observe?: 'body', reportProgress?: boolean): Observable<SiteSectionsResponse>;
    public getSections(siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SiteSectionsResponse>>;
    public getSections(siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SiteSectionsResponse>>;
    public getSections(siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling getSections.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SiteSectionsResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/sections`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get sites list
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSites(observe?: 'body', reportProgress?: boolean): Observable<SitesListResponse>;
    public getSites(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SitesListResponse>>;
    public getSites(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SitesListResponse>>;
    public getSites(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SitesListResponse>(`${this.basePath}/sites`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update site info
     * 
     * @param UpdateSiteDto 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(UpdateSiteDto: UpdateSiteDto, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<SiteGetByIdResponse>;
    public update(UpdateSiteDto: UpdateSiteDto, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SiteGetByIdResponse>>;
    public update(UpdateSiteDto: UpdateSiteDto, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SiteGetByIdResponse>>;
    public update(UpdateSiteDto: UpdateSiteDto, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (UpdateSiteDto === null || UpdateSiteDto === undefined) {
            throw new Error('Required parameter UpdateSiteDto was null or undefined when calling update.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling update.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<SiteGetByIdResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}`,
            UpdateSiteDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update section
     * 
     * @param sectionId 
     * @param UpdateSectionDto 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSection(sectionId: string, UpdateSectionDto: UpdateSectionDto, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<SectionItem>;
    public updateSection(sectionId: string, UpdateSectionDto: UpdateSectionDto, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SectionItem>>;
    public updateSection(sectionId: string, UpdateSectionDto: UpdateSectionDto, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SectionItem>>;
    public updateSection(sectionId: string, UpdateSectionDto: UpdateSectionDto, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sectionId === null || sectionId === undefined) {
            throw new Error('Required parameter sectionId was null or undefined when calling updateSection.');
        }

        if (UpdateSectionDto === null || UpdateSectionDto === undefined) {
            throw new Error('Required parameter UpdateSectionDto was null or undefined when calling updateSection.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling updateSection.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<SectionItem>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/sections/${encodeURIComponent(String(sectionId))}`,
            UpdateSectionDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
