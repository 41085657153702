<h1 style="text-align: center;">Desktop</h1>
<iframe [src]="frameUrl" width="800" height="700" frameborder="0"></iframe>
<h1 style="text-align: center; margin-top: 32px">Mobile</h1>
<iframe
  [src]="frameUrl"
  width="375"
  height="667"
  frameborder="0"
  style="margin: 0 auto;display: block;"
></iframe>
