/**
 * Telesite
 * Platform for fast start your site.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface TagItem { 
    id: string;
    usageCount: number;
    name: string;
    path: string;
    pageTitle?: string;
    pageH1?: string;
    pageDescription?: string;
    optimizedTextEditorJs?: string;
    optimizedTextPosition?: TagItem.OptimizedTextPositionEnum;
    isNew?: boolean;
    feedArticleStyle?: TagItem.FeedArticleStyleEnum;
    feedArticlesCount: number;
}
export namespace TagItem {
    export type OptimizedTextPositionEnum = 'top' | 'bottom';
    export const OptimizedTextPositionEnum = {
        Top: 'top' as OptimizedTextPositionEnum,
        Bottom: 'bottom' as OptimizedTextPositionEnum
    };
    export type FeedArticleStyleEnum = 'inside' | 'outside' | 'left' | 'right';
    export const FeedArticleStyleEnum = {
        Inside: 'inside' as FeedArticleStyleEnum,
        Outside: 'outside' as FeedArticleStyleEnum,
        Left: 'left' as FeedArticleStyleEnum,
        Right: 'right' as FeedArticleStyleEnum
    };
}
