import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SitesService } from '../../api';
import { LoadSections, LoadSectionsSuccess, SelectSite, SelectSiteSuccess, UpdateConfig, UpdateConfigFail, UpdateConfigSuccess, } from './current-site.actions';
export class CurrentSiteStateModel {
}
let CurrentSiteState = class CurrentSiteState {
    constructor(api) {
        this.api = api;
    }
    static sections(state) {
        return state.sections;
    }
    static sectionsFlatten(state) {
        return state.sections.items.reduce((acc, curr) => acc.concat(curr, ...curr.subSections), []);
    }
    static data(state) {
        return state.data;
    }
    static origin(state) {
        return state.data.domain ? state.data.domain : state.data.subDomain + '.' + environment.domain;
    }
    static id(state) {
        return state.id;
    }
    load(ctx, action) {
        return this.api.getById(action.payload).pipe(tap(data => ctx.dispatch(new SelectSiteSuccess(data))));
    }
    loadSuccess({ setState }, { payload }) {
        setState({ data: payload, id: payload.id });
    }
    updateConfig({ dispatch, getState }, { payload }) {
        let state = getState();
        return this.api.update(payload, state.id).pipe(tap(site => dispatch(new UpdateConfigSuccess(site))), catchError(err => {
            dispatch(new UpdateConfigFail(err));
            return throwError(err);
        }));
    }
    updateConfigSuccess(ctx, { payload }) {
        ctx.patchState({ data: payload });
    }
    loadSections({ dispatch, getState }) {
        let state = getState();
        return this.api.getSections(state.id).pipe(tap(rs => dispatch(new LoadSectionsSuccess(rs))));
    }
    loadSectionsSuccess(ctx, { payload }) {
        ctx.patchState({ sections: payload });
    }
};
tslib_1.__decorate([
    Action(SelectSite),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SelectSite]),
    tslib_1.__metadata("design:returntype", void 0)
], CurrentSiteState.prototype, "load", null);
tslib_1.__decorate([
    Action(SelectSiteSuccess),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SelectSiteSuccess]),
    tslib_1.__metadata("design:returntype", void 0)
], CurrentSiteState.prototype, "loadSuccess", null);
tslib_1.__decorate([
    Action(UpdateConfig),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateConfig]),
    tslib_1.__metadata("design:returntype", void 0)
], CurrentSiteState.prototype, "updateConfig", null);
tslib_1.__decorate([
    Action(UpdateConfigSuccess),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateConfigSuccess]),
    tslib_1.__metadata("design:returntype", void 0)
], CurrentSiteState.prototype, "updateConfigSuccess", null);
tslib_1.__decorate([
    Action(LoadSections),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CurrentSiteState.prototype, "loadSections", null);
tslib_1.__decorate([
    Action(LoadSectionsSuccess),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, LoadSectionsSuccess]),
    tslib_1.__metadata("design:returntype", void 0)
], CurrentSiteState.prototype, "loadSectionsSuccess", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [CurrentSiteStateModel]),
    tslib_1.__metadata("design:returntype", void 0)
], CurrentSiteState, "sections", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [CurrentSiteStateModel]),
    tslib_1.__metadata("design:returntype", void 0)
], CurrentSiteState, "sectionsFlatten", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [CurrentSiteStateModel]),
    tslib_1.__metadata("design:returntype", void 0)
], CurrentSiteState, "data", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [CurrentSiteStateModel]),
    tslib_1.__metadata("design:returntype", void 0)
], CurrentSiteState, "origin", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [CurrentSiteStateModel]),
    tslib_1.__metadata("design:returntype", void 0)
], CurrentSiteState, "id", null);
CurrentSiteState = tslib_1.__decorate([
    State({
        name: 'currentSite',
        defaults: {
            data: null,
        },
    }),
    tslib_1.__metadata("design:paramtypes", [SitesService])
], CurrentSiteState);
export { CurrentSiteState };
