import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { SiteCreateComponent } from './pages/site-create/site-create.component';
import { SitesListComponent } from './pages/sites-list/sites-list.component';

const routes: Route[] = [
  { path: 'sites', component: SitesListComponent },
  { path: 'sites/new', component: SiteCreateComponent },
];

@NgModule({
  declarations: [SitesListComponent, SiteCreateComponent],
  imports: [SharedModule, RouterModule.forChild(routes)],
})
export class SitesModule {}
