import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { SignOut } from '../../../auth/state/user.actions';
import { UserState, UserStateModel } from '../../../auth/state/user.state';

@Component({
  selector: 'tsa-user-toolbar',
  templateUrl: './user-toolbar.component.html',
  styleUrls: ['./user-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UserToolbarComponent implements OnInit {
  user: UserStateModel = this.store.selectSnapshot(UserState);

  constructor(private store: Store) {}

  ngOnInit() {}

  signOut() {
    this.store.dispatch(new SignOut());
  }
}
