import {
  AttachItemResponse,
  AttachMetaDto,
  EmbedCreateDto,
  MainPhotoItemResponse,
  MainPhotoMetaDto,
  PostItemResponse,
  UpdatePostDto,
} from '../../api';

export class CreateDraftPost {
  static readonly type = '[PostEditor] CreateDraftPost';
  constructor() {}
}

export class LoadPost {
  static readonly type = '[PostEditor] LoadPost';
  constructor(public payload: string) {}
}

export class LoadPostAttaches {
  static readonly type = '[PostEditor] LoadPostAttaches';
  constructor(public payload: string) {}
}

export class UpdatePost {
  static readonly type = '[PostEditor] UpdatePost';
  constructor(public payload: UpdatePostDto) {}
}

export class UpdatePostError {
  static readonly type = '[PostEditor] UpdatePostError';
  constructor(public payload: any) {}
}

export class SetPost {
  static readonly type = '[PostEditor] SetPost';
  constructor(public payload: PostItemResponse) {}
}

export class UploadMainPhoto {
  static readonly type = '[PostEditor] UploadMainPhoto';
  constructor(public payload: File) {}
}

export class SetMainPhoto {
  static readonly type = '[PostEditor] SetMainPhoto';
  constructor(public payload: MainPhotoItemResponse) {}
}

export class UpdateMainPhotoMeta {
  static readonly type = '[PostEditor] UpdateMainPhotoMeta';
  constructor(public payload: MainPhotoMetaDto) {}
}

export class UploadAttaches {
  static readonly type = '[PostEditor] UploadAttaches';
  constructor(public payload: FileList) {}
}

export class AddEmbedAttach {
  static readonly type = '[PostEditor] AddEmbedAttach';
  constructor(public payload: EmbedCreateDto) {}
}

export class UpdateAttachMeta {
  static readonly type = '[PostEditor] UpdateAttachMeta';
  constructor(public id: string, public payload: AttachMetaDto) {}
}

export class DeleteAttach {
  static readonly type = '[PostEditor] DeleteAttach';
  constructor(public payload: string) {}
}

export class AttachDeleted {
  static readonly type = '[PostEditor] AttachDeleted';
  constructor(public payload: AttachItemResponse | any) {}
}

export class AddAttach {
  static readonly type = '[PostEditor] AddAttach';
  constructor(public payload: AttachItemResponse) {}
}
