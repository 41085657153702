<div [formGroup]="form" class="wrap">
  <div class="form-row">
    <mat-form-field>
      <mat-select placeholder="Раздел сайта" formControlName="section">
        <ng-container *ngFor="let section of sections.items">
          <mat-option [value]="section.id">
            {{ section.header }}
          </mat-option>
          <ng-container *ngIf="section.subSections.length">
            <mat-option *ngFor="let section of section.subSections" [value]="section.id">
              &nbsp;&nbsp;- &nbsp; {{ section.header }}
            </mat-option>
          </ng-container>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>Заголовок публикации</mat-label>
      <input matInput placeholder="Как найти иголку в стоге сена?" #title formControlName="title" />
      <span class="mat-secondary" matSuffix>{{ title.value?.length || 0 }}/200</span>
      <mat-hint>Рекомендовано: 35-65 символов</mat-hint>
    </mat-form-field>
  </div>

  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>Подзаголовок</mat-label>
      <input
        matInput
        placeholder="После прочтения твоя жизнь изменится"
        #subTitle
        formControlName="subTitle"
      />
      <span class="mat-secondary" matSuffix>{{ subTitle.value?.length || 0 }}/200</span>
    </mat-form-field>
  </div>

  <div class="form-row">
    <mat-form-field floatLabel="never">
      <textarea
        class="textarea-input"
        placeholder="Описание для ленты"
        formControlName="description"
        maxlength="400"
        matInput
        mat-autosize
        #description
      ></textarea>
      <mat-hint align="end">
        <span class="mat-secondary">{{ description.value?.length || 0 }}/400</span>
      </mat-hint>
      <mat-hint>
        Рекомендовано: 70-320 символов
      </mat-hint>
    </mat-form-field>
  </div>

  <div class="form-row card-image" [formGroup]="mainPhotoForm">
    <header fxLayout="row" fxLayoutAlign="space-between center">
      <b>Заходное фото</b>
      <div>
        <button
          mat-stroked-button
          color="primary"
          [disabled]="uploading"
          style="text-transform: none"
          (click)="inputFile.click()"
          *ngIf="mainImage"
        >
          Изменить фото
        </button>
        <input
          type="file"
          style="width: 0; height: 0;display: none;"
          accept="image/*"
          #inputFile
          (change)="changeMainImage(inputFile.files)"
        />
      </div>
    </header>
    <div *ngIf="!mainImage">
      <tsa-file-uploader (select)="uploadMainPhoto($event)" *ngIf="!uploading"> </tsa-file-uploader>
    </div>
    <div *ngIf="uploading">
      <mat-progress-spinner
        *ngIf="uploading"
        mode="indeterminate"
        strokeWidth="4"
        [diameter]="40"
        style="margin: 0 auto"
      ></mat-progress-spinner>
    </div>
    <div *ngIf="mainImage && !uploading" class="image-preview"><img [src]="mainImage.publicUrl" /></div>
    <div><input class="card-input" placeholder="Описание" formControlName="caption" /></div>
    <div class="options">
      <div class="label">Положение заголовка</div>

      <mat-button-toggle-group
        appearance="legacy"
        formControlName="position"
        style="background-color: white; font-size: 12px"
      >
        <mat-button-toggle value="inside" disableRipple> Внутри </mat-button-toggle>
        <mat-button-toggle value="outside" disableRipple> Снаружи </mat-button-toggle>
        <mat-button-toggle value="left" disableRipple> Слева </mat-button-toggle>
        <mat-button-toggle value="right" disableRipple> Справа </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div align="end">
    <button mat-flat-button color="primary" (click)="nextTab.next()">Дальше</button>
  </div>
</div>
