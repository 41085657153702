<div fxLayout="row" fxLayoutAlign="start start" [formGroup]="form">
  <div class="left-section" [class.full]="hideAttaches">
    <div class="form-row">
      <mat-form-field>
        <mat-select placeholder="Раздел сайта" formControlName="section">
          <ng-container *ngFor="let section of sections.items">
            <mat-option [value]="section.id">
              {{ section.header }}
            </mat-option>
            <ng-container *ngIf="section.subSections.length">
              <mat-option *ngFor="let section of section.subSections" [value]="section.id">
                - {{ section.header }}
              </mat-option>
            </ng-container>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field appearance="fill">
        <mat-label>Заголовок публикации</mat-label>
        <input matInput placeholder="Как найти иголку в стоге сена?" #title formControlName="title" />
        <span class="mat-secondary" matSuffix>{{ title.value?.length || 0 }}/200</span>
        <mat-hint>Рекомендовано: 35-65 символов</mat-hint>
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field appearance="fill">
        <mat-label>Подзаголовок</mat-label>
        <input
          matInput
          placeholder="После прочтения твоя жизнь изменится"
          #subTitle
          formControlName="subTitle"
        />
        <span class="mat-secondary" matSuffix>{{ subTitle.value?.length || 0 }}/200</span>
      </mat-form-field>
    </div>

    <div class="form-row">
      <header><b>Описание для ленты</b></header>
      <mat-form-field floatLabel="never">
        <textarea
          class="textarea-input"
          rows="3"
          formControlName="description"
          maxlength="400"
          matInput
          #description
        ></textarea>
        <mat-hint align="end">
          <span class="mat-secondary">{{ description.value?.length || 0 }}/400</span>
        </mat-hint>
        <mat-hint>
          Рекомендовано: 70-320 символов
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="form-row card-image" [formGroup]="mainPhotoForm">
      <header fxLayout="row" fxLayoutAlign="space-between center">
        <b>Заходное фото</b>
        <div>
          <button
            mat-stroked-button
            color="primary"
            [disabled]="uploading"
            style="text-transform: none"
            (click)="inputFile.click()"
            *ngIf="mainImage"
          >
            Изменить фото
          </button>
          <input
            type="file"
            style="width: 0; height: 0;display: none;"
            accept="image/*"
            #inputFile
            (change)="changeMainImage(inputFile.files)"
          />
        </div>
      </header>
      <div *ngIf="!mainImage">
        <tsa-file-uploader (select)="uploadMainPhoto($event)" *ngIf="!uploading"> </tsa-file-uploader>
      </div>
      <div *ngIf="uploading">
        <mat-progress-spinner
          *ngIf="uploading"
          mode="indeterminate"
          strokeWidth="4"
          [diameter]="40"
          style="margin: 0 auto"
        ></mat-progress-spinner>
      </div>
      <div *ngIf="mainImage && !uploading" class="image-preview"><img [src]="mainImage.publicUrl" /></div>
      <div><input class="card-input" placeholder="Описание" formControlName="caption" /></div>
      <div><input class="card-input" placeholder="Откуда взято" formControlName="copyright" /></div>
      <div class="options">
        <div class="label">Положение заголовка</div>

        <mat-button-toggle-group
          appearance="legacy"
          formControlName="position"
          style="background-color: white; font-size: 12px"
        >
          <mat-button-toggle value="inside" disableRipple> Внутри </mat-button-toggle>
          <mat-button-toggle value="outside" disableRipple> Снаружи </mat-button-toggle>
          <mat-button-toggle value="left" disableRipple> Слева </mat-button-toggle>
          <mat-button-toggle value="right" disableRipple> Справа </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <div class="form-row">
      <header fxLayout="row" fxLayoutAlign="space-between center">
        <b>Тело публикации</b>
        <span class="button-toggle" (click)="toggleBlockMode()" aria-label="Toggle block mode">
          {{ !ds.blockMode.value ? 'Вкл' : 'Выкл' }}ючить блочный режим
        </span>
      </header>
      <tsa-quill-editor [control]="form.get('textOps')"></tsa-quill-editor>
    </div>

    <!--<div class="form-row">-->
    <!--<header><b>Теги</b></header>-->
    <!--<div>-->

    <!--</div>-->
    <!--</div>-->
    <!---->
    <!--<div class="form-row">-->
    <!--<header><b>Блок по теме</b></header>-->
    <!--<div>-->

    <!--</div>-->
    <!--</div>-->
  </div>
  <div class="right-section" [class.closed]="hideAttaches">
    <div style="padding: 16px 8px">
      <button mat-icon-button (click)="hideAttaches = !hideAttaches" class="attach-trigger">
        <mat-icon *ngIf="!hideAttaches">skip_next</mat-icon>
        <mat-icon *ngIf="hideAttaches">skip_previous</mat-icon>
      </button>
      <button mat-stroked-button (click)="inputAttach.click()">
        <mat-icon>attachment</mat-icon>
        Картинка
      </button>
      <button mat-stroked-button style="margin-left: 8px" (click)="addEmbedDialog()">Эмбед</button>
    </div>
    <input
      type="file"
      style="width: 0; height: 0;display: none;"
      accept="image/*"
      multiple
      #inputAttach
      (change)="uploadAttaches(inputAttach.files)"
    />
    <div class="attaches">
      <tsa-attach-item
        *ngFor="let attach of attaches$ | async; trackBy: trackByFn"
        [attach]="attach"
      ></tsa-attach-item>
    </div>

    <!--<pre style="white-space: pre-wrap;">{{  vvv }} </pre>-->
    <!--<pre>{{ mainPhotoForm.value | json }} </pre>-->
  </div>
</div>
