import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NgxFcbkService {
  constructor() {}

  public loadScript() {
    // return Observable.create((observer: Observer<any>) => {
    this._startScriptLoad();
    // });
  }

  private _startScriptLoad() {
    if (document.getElementById('fcbk-wjs')) {
      this.initScript();
      return;
    }
    // console.log('LOAD');
    const firstJSScript = document.getElementsByTagName('script')[0];
    const js = document.createElement('script');
    js.id = 'fcbk-wjs';
    js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
    firstJSScript.parentNode.insertBefore(js, firstJSScript);
    this.initScript();
  }

  public initScript() {
    // console.log('INIT');
    if (window['FB']) {
      window['FB'].init({
        xfbml: true,
        version: 'v3.2',
      });
    }
  }
}
