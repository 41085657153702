/**
 * Telesite
 * Platform for fast start your site.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MainPhotoItemResponse } from './mainPhotoItemResponse';
import { PostListPublicationResponse } from './postListPublicationResponse';
import { SectionItem } from './sectionItem';
import { SiteBasicInfo } from './siteBasicInfo';
import { UserItem } from './userItem';


export interface PostListItemResponse { 
    id: string;
    title: string;
    subTitle?: string;
    mainImage?: MainPhotoItemResponse;
    description?: string;
    pageTitle?: string;
    pageDescription?: string;
    publicationSlug?: string;
    textOps?: string;
    editorJsData?: string;
    tags?: Array<string>;
    state: PostListItemResponse.StateEnum;
    section?: SectionItem;
    creator: UserItem;
    createdAt: string;
    attaches: Array<string>;
    publication: PostListPublicationResponse;
    legacy?: string;
    uuid?: string;
    schedulePublishAt?: string;
    site: SiteBasicInfo;
}
export namespace PostListItemResponse {
    export type StateEnum = 'draft' | 'queue' | 'published';
    export const StateEnum = {
        Draft: 'draft' as StateEnum,
        Queue: 'queue' as StateEnum,
        Published: 'published' as StateEnum
    };
}
