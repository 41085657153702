/**
 * Telesite
 * Platform for fast start your site.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MainPhotoItemResponse } from '../model/mainPhotoItemResponse';
import { MainPhotoMetaDto } from '../model/mainPhotoMetaDto';
import { PostItemResponse } from '../model/postItemResponse';
import { PostsListResponse } from '../model/postsListResponse';
import { PublicationsListResponse } from '../model/publicationsListResponse';
import { ToQueueDto } from '../model/toQueueDto';
import { UpdatePostDto } from '../model/updatePostDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PostsService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create draft post
     * 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDraft(siteId: string, observe?: 'body', reportProgress?: boolean): Observable<PostItemResponse>;
    public createDraft(siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostItemResponse>>;
    public createDraft(siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostItemResponse>>;
    public createDraft(siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling createDraft.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PostItemResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all posts
     * 
     * @param siteId 
     * @param state 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAll(siteId: string, state?: 'draft' | 'queue' | 'published', query?: string, observe?: 'body', reportProgress?: boolean): Observable<PostsListResponse>;
    public getAll(siteId: string, state?: 'draft' | 'queue' | 'published', query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostsListResponse>>;
    public getAll(siteId: string, state?: 'draft' | 'queue' | 'published', query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostsListResponse>>;
    public getAll(siteId: string, state?: 'draft' | 'queue' | 'published', query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling getAll.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (state !== undefined && state !== null) {
            queryParameters = queryParameters.set('state', <any>state);
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PostsListResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get post by id
     * 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<PostItemResponse>;
    public getById(postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostItemResponse>>;
    public getById(postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostItemResponse>>;
    public getById(postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling getById.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PostItemResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get publications
     * 
     * @param siteId 
     * @param sections 
     * @param tags 
     * @param sort 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublications(siteId: string, sections?: Array<string>, tags?: Array<string>, sort?: string, query?: string, observe?: 'body', reportProgress?: boolean): Observable<PublicationsListResponse>;
    public getPublications(siteId: string, sections?: Array<string>, tags?: Array<string>, sort?: string, query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PublicationsListResponse>>;
    public getPublications(siteId: string, sections?: Array<string>, tags?: Array<string>, sort?: string, query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PublicationsListResponse>>;
    public getPublications(siteId: string, sections?: Array<string>, tags?: Array<string>, sort?: string, query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling getPublications.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sections) {
            queryParameters = queryParameters.set('sections', sections.join(COLLECTION_FORMATS['csv']));
        }
        if (tags) {
            queryParameters = queryParameters.set('tags', tags.join(COLLECTION_FORMATS['csv']));
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PublicationsListResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/publications`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Publish post
     * 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publish(postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<PostItemResponse>;
    public publish(postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostItemResponse>>;
    public publish(postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostItemResponse>>;
    public publish(postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling publish.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling publish.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PostItemResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}/publish`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queue post
     * 
     * @param ToQueueDto 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public queue(ToQueueDto: ToQueueDto, postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<PostItemResponse>;
    public queue(ToQueueDto: ToQueueDto, postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostItemResponse>>;
    public queue(ToQueueDto: ToQueueDto, postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostItemResponse>>;
    public queue(ToQueueDto: ToQueueDto, postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ToQueueDto === null || ToQueueDto === undefined) {
            throw new Error('Required parameter ToQueueDto was null or undefined when calling queue.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling queue.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling queue.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PostItemResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}/queue`,
            ToQueueDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete post
     * 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public remove(postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public remove(postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public remove(postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public remove(postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling remove.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling remove.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Publish post
     * 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unPublish(postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<PostItemResponse>;
    public unPublish(postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostItemResponse>>;
    public unPublish(postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostItemResponse>>;
    public unPublish(postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling unPublish.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling unPublish.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PostItemResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}/un-publish`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Un-queue post
     * 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unQueue(postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<PostItemResponse>;
    public unQueue(postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostItemResponse>>;
    public unQueue(postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostItemResponse>>;
    public unQueue(postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling unQueue.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling unQueue.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PostItemResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}/un-queue`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update post data
     * 
     * @param UpdatePostDto 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(UpdatePostDto: UpdatePostDto, postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<PostItemResponse>;
    public update(UpdatePostDto: UpdatePostDto, postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostItemResponse>>;
    public update(UpdatePostDto: UpdatePostDto, postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostItemResponse>>;
    public update(UpdatePostDto: UpdatePostDto, postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (UpdatePostDto === null || UpdatePostDto === undefined) {
            throw new Error('Required parameter UpdatePostDto was null or undefined when calling update.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling update.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling update.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<PostItemResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}`,
            UpdatePostDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update main photo meta
     * 
     * @param MainPhotoMetaDto 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMainPhotoMeta(MainPhotoMetaDto: MainPhotoMetaDto, postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<MainPhotoItemResponse>;
    public updateMainPhotoMeta(MainPhotoMetaDto: MainPhotoMetaDto, postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MainPhotoItemResponse>>;
    public updateMainPhotoMeta(MainPhotoMetaDto: MainPhotoMetaDto, postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MainPhotoItemResponse>>;
    public updateMainPhotoMeta(MainPhotoMetaDto: MainPhotoMetaDto, postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (MainPhotoMetaDto === null || MainPhotoMetaDto === undefined) {
            throw new Error('Required parameter MainPhotoMetaDto was null or undefined when calling updateMainPhotoMeta.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling updateMainPhotoMeta.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling updateMainPhotoMeta.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<MainPhotoItemResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}/main-photo`,
            MainPhotoMetaDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload main image
     * 
     * @param photo 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadMainPhoto(photo: Blob, postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<MainPhotoItemResponse>;
    public uploadMainPhoto(photo: Blob, postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MainPhotoItemResponse>>;
    public uploadMainPhoto(photo: Blob, postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MainPhotoItemResponse>>;
    public uploadMainPhoto(photo: Blob, postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (photo === null || photo === undefined) {
            throw new Error('Required parameter photo was null or undefined when calling uploadMainPhoto.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling uploadMainPhoto.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling uploadMainPhoto.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (photo !== undefined) {
            formParams.append('photo', <any>photo);
        }

        return this.httpClient.post<MainPhotoItemResponse>(`${this.basePath}/sites/${encodeURIComponent(String(siteId))}/posts/${encodeURIComponent(String(postId))}/main-photo`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
