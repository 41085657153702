import { ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngxs/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { TagItem, TagsService } from '../../../../api';
import { CurrentSiteState } from '../../../state/current-site.state';

@Component({
  selector: 'tsa-post-tags',
  templateUrl: './post-tags.component.html',
  styleUrls: ['./post-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostTagsComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;

  site = this.store.selectSnapshot(CurrentSiteState.data);

  separatorKeysCodes: number[] = [ENTER];
  tagsCtrl = new FormControl();
  tagsFiltered: Observable<TagItem[]>;
  tags: TagItem[] = [];

  // all site tags
  allTags: TagItem[] = [];

  // tags that text contains
  suggestedTags: TagItem[] = [];

  saving = false;

  @ViewChild('tagsInput', { static: false }) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  constructor(
    private tagsService: TagsService,
    private store: Store,
    private cd: ChangeDetectorRef,
    private snack: MatSnackBar
  ) {
    this.tagsFiltered = this.tagsCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.getUnusedTags()))
    );
  }

  ngOnInit() {
    this.tagsService.getAll(this.site.id).subscribe(rs => {
      this.allTags = rs.items;
      let formTags = this.form.value.tags;
      if (formTags && formTags.length) {
        this.tags = rs.items.filter(t => formTags.includes(t.id));
      }
      this.tagsCtrl.setValue(null);
      this.suggestedTags = this.suggestTags(this.allTags);
      this.cd.detectChanges();
    });
  }

  suggestTags(tags: TagItem[]) {
    let stripTags = html => {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    };
    let text =
      this.form.value.title +
      ' ' +
      this.form.value.description +
      ' ' +
      this.form.value.pageTitle +
      ' ' +
      this.form.value.pageDescription +
      ' ';

    if (this.form.value.editorJsData) {
      let arr = JSON.parse(this.form.value.editorJsData);
      text += arr.blocks
        .filter(o => ['paragraph', 'header', 'quote', 'list'].includes(o.type))
        .map(o => {
          if (o.type === 'list') {
            return stripTags(o.data.items.join('\n'));
          }
          return stripTags(o.data.text);
        })
        .join('\n');
    }
    text = text.toLowerCase();
    let suggest = [];
    for (let tag of tags) {
      if (text.includes(tag.name.toLowerCase())) {
        suggest.push(tag);
      }
    }
    return suggest;
  }

  getUnusedTags() {
    let usedIds = this.tags.map(t => t.id);
    return this.allTags.slice().filter(t => !usedIds.includes(t.id));
  }

  createTag(value: string) {
    this.saving = true;
    this.tagsService
      .addTag({ name: value }, this.site.id)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.saving = false;
          this.cd.detectChanges();
        })
      )
      .subscribe(
        rs => {
          this.allTags.push(rs);
          this.stageTag(rs);
          this.tagsCtrl.setValue(null);
          this.cd.detectChanges();
        },
        err => {
          this.snack.open('Не удалось создать тег', 'ok', { duration: 6000 });
        }
      );
  }

  stageTag(tag: TagItem) {
    if (this.tags.find(t => t.id === tag.id)) {
      return;
    }
    this.tags.push(tag);
    this.form.patchValue({ tags: this.tags.map(t => t.id) });
    this.tagsCtrl.setValue(null);
  }

  remove(id: string): void {
    const index = this.tags.findIndex(t => t.id === id);

    if (index >= 0) {
      this.tags.splice(index, 1);
      this.form.patchValue({ tags: this.tags.map(t => t.id) });
      this.tagsCtrl.setValue(null);
      this.cd.detectChanges();
    }
  }

  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        this.createTag(value);
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.tagsCtrl.setValue(null);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let tag = this.allTags.find(t => t.id === event.option.value);
    this.stageTag(tag);
    this.fruitInput.nativeElement.value = '';
    this.tagsCtrl.setValue(null);
  }

  private _filter(value: string): TagItem[] {
    const filterValue = value.toLowerCase();

    return this.getUnusedTags().filter(tag => tag.name.toLowerCase().indexOf(filterValue) !== -1);
  }

  ngOnDestroy() {}
}
