import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'tsa-inline-loader',
  template: `
    <div class="loader">
      <mat-spinner [diameter]="40" [strokeWidth]="4"></mat-spinner>
    </div>
  `,
  styleUrls: ['./inline-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
