import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'tsa-error-block',
  templateUrl: './error-block.component.html',
  styleUrls: ['./error-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorBlockComponent implements OnInit, OnChanges {
  @Input()
  errorMessage = '';
  @Input()
  errorDetails = '';

  @Input()
  error: HttpErrorResponse;

  @Input()
  autoDelete: number;

  @Input()
  closeBtn: boolean;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.error && changes.error.currentValue) {
      this.verifyError();
      this.autoRemoveError();
    }
  }

  retrieveValidationErrorMessage(err, path = []): string {
    path.push(err.property);
    if (err.children && err.children.length > 0) {
      return this.retrieveValidationErrorMessage(err.children[0], path);
    } else {
      return (
        path.join(' > ') +
        ': ' +
        Object.keys(err.constraints)
          .map(k => err.constraints[k])
          .join('; \n')
      );
    }
  }

  verifyError() {
    if (this.error.status >= 400 && this.error.status < 500) {
      if (this.error.error.message) {
        this.errorMessage = `${this.error.error.error}`;
        this.errorDetails = `${this.error.error.message}`;
        // ClassValidator error
        if (this.error.status === 400 && Array.isArray(this.error.error.message)) {
          this.errorDetails = '';
          for (const info of this.error.error.message) {
            this.errorDetails += this.retrieveValidationErrorMessage(info);
          }
        }
      } else if (this.error.status === 400 && Array.isArray(this.error.error)) {
        this.errorMessage = 'Проверьте введенные данные';
        this.errorDetails = '';
        for (const info of this.error.error) {
          this.errorDetails += this.retrieveValidationErrorMessage(info);
        }
      } else {
        this.errorMessage = 'Проверьте введенные данные';
      }
    } else if (this.error.status === 24) {
      this.errorMessage = 'Невозможно загрузить файл';
    } else {
      this.errorMessage = 'Неизвестная ошибка';
    }
  }

  autoRemoveError() {
    if (!!this.autoDelete) {
      setTimeout(() => this.removeError(), this.autoDelete * 1000);
    }
  }

  removeError() {
    delete this.error;
    delete this.errorMessage;
    delete this.errorDetails;
    this.cd.detectChanges();
  }
}
