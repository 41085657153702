import Quill from 'quill';
import { AttachItemResponse } from '../../../../../api';
import { CachedAttach } from './cached-attach';

export class InstagramEmbed extends CachedAttach {
  static blotName = 'attachInstagram';
  static tagName = 'figure';

  static render(node: HTMLElement, value: AttachItemResponse) {
    // console.log('instagram render', value);
    super.render(node, value);

    let extra = JSON.parse(value.embedExtra);

    // console.log('extra', extra);

    node.innerHTML = `
    <div class="inst-holder frame">${extra.html}</div>
    <figcaption>
        <div class="caption">${value.caption || ''}</div>
        <div class="copyright">${value.copyright || ''}</div>
    </figcaption>`;

    return node;
  }
}

Quill.register(InstagramEmbed);
