import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NgxInstService {
  private readonly INSTAGRAM_OBJECT = 'instgrm';
  private readonly INSTAGRAM_SCRIPT_ID = 'instgrm-wjs';
  private readonly INSTAGRAM_WIDGET_URL = 'https://instagram.com/embed.js';

  constructor() {}

  public loadScript(): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this._startScriptLoad();
      // window[ this.INSTAGRAM_OBJECT ].ready(this._onTwitterScriptLoadedFactory(observer));
    });
  }

  public reloadInstagramScript() {
    if (window[this.INSTAGRAM_OBJECT]) {
      window[this.INSTAGRAM_OBJECT].Embeds.process();
    }
  }

  private _startScriptLoad() {
    const instagramData = window[this.INSTAGRAM_OBJECT] || {};

    if (this._twitterScriptAlreadyExists()) {
      // window[ this.INSTAGRAM_OBJECT ]['Embeds'] = instagramData;
      // window[this.INSTAGRAM_OBJECT].Embeds.process();
      return;
    }

    this._appendTwitterScriptToDOM();

    instagramData._e = [];

    instagramData.ready = (callback: Function) => {
      instagramData._e.push(callback);
    };
  }

  private _twitterScriptAlreadyExists(): boolean {
    const instagramScript = document.getElementById(this.INSTAGRAM_SCRIPT_ID);
    return instagramScript !== null || typeof instagramScript !== 'object';
  }

  private _appendTwitterScriptToDOM(): void {
    const firstJSScript = document.getElementsByTagName('script')[0];
    const js = document.createElement('script');
    js.id = this.INSTAGRAM_SCRIPT_ID;
    js.src = this.INSTAGRAM_WIDGET_URL;
    firstJSScript.parentNode.insertBefore(js, firstJSScript);
  }

  private _onTwitterScriptLoadedFactory(observer: Observer<any>): Function {
    return (instagramData: any) => {
      observer.next(instagramData);
      observer.complete();
    };
  }
}
