/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quill-editor.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./link-preview/link-preview.component.ngfactory";
import * as i3 from "./link-preview/link-preview.component";
import * as i4 from "../../drag.service";
import * as i5 from "./quill-editor.component";
import * as i6 from "@ngxs/store";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../../shared/ngx-inst.service";
var styles_QuillEditorComponent = [i0.styles];
var RenderType_QuillEditorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_QuillEditorComponent, data: {} });
export { RenderType_QuillEditorComponent as RenderType_QuillEditorComponent };
export function View_QuillEditorComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 0, "div", [["class", "ql-container"]], null, [[null, "drop"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (_co.dropRoot($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "tsa-link-preview", [], null, [[null, "edit"], [null, "remove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("edit" === en)) {
        var pd_0 = (_co.editLink($event) !== false);
        ad = (pd_0 && ad);
    } if (("remove" === en)) {
        var pd_1 = (_co.removeLink($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_LinkPreviewComponent_0, i2.RenderType_LinkPreviewComponent)), i1.ɵdid(3, 245760, null, 0, i3.LinkPreviewComponent, [i4.DragService], { editor: [0, "editor"] }, { edit: "edit", remove: "remove" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.quill; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_QuillEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tsa-quill-editor", [], null, null, null, View_QuillEditorComponent_0, RenderType_QuillEditorComponent)), i1.ɵdid(1, 4964352, null, 0, i5.QuillEditorComponent, [i4.DragService, i6.Actions, i6.Store, i1.ChangeDetectorRef, i7.MatDialog, i1.NgZone, i8.NgxInstService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuillEditorComponentNgFactory = i1.ɵccf("tsa-quill-editor", i5.QuillEditorComponent, View_QuillEditorComponent_Host_0, { control: "control" }, {}, []);
export { QuillEditorComponentNgFactory as QuillEditorComponentNgFactory };
