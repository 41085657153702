import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, EMPTY, Observable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { PostsService, PublicationsListResponse, TagItem } from '../../../api';
import { SiteItem } from '../../../api/model/siteItem';
import { CurrentSiteState } from '../../state/current-site.state';

@Component({
  selector: 'tsa-published-posts',
  templateUrl: './published-posts.component.html',
  styleUrls: ['./published-posts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishedPostsComponent implements OnInit {
  siteId = this.store.selectSnapshot(CurrentSiteState.id);
  site: SiteItem = this.store.selectSnapshot(CurrentSiteState.data);
  tags: TagItem[] = this.route.snapshot.data.tags.items;
  sections = this.store.selectSnapshot(CurrentSiteState.sections);
  search$ = new BehaviorSubject('');

  searchChange$ = this.search$.pipe(
    debounceTime(1000),
    distinctUntilChanged()
  );

  posts$: Observable<PublicationsListResponse>;

  origin = this.store.selectSnapshot(CurrentSiteState.origin);

  form = new FormGroup({
    sections: new FormControl(),
    tags: new FormControl(),
    sort: new FormControl('-createdAt'),
  });

  constructor(
    private postsService: PostsService,
    private store: Store,
    private snack: MatSnackBar,
    private route: ActivatedRoute
  ) {}

  get searchTerm() {
    return this.search$.value;
  }

  highlight(text: string) {
    let html = text;
    let term = this.searchTerm;
    if (text.indexOf(term) !== -1) {
      return text.replace(term, `<mark>${term}</mark>`);
    }
    let phases = term.split(' ').filter(p => p.length > 2);
    for (let phase of phases) {
      if (html.indexOf(phase) !== -1) {
        html = html.replace(phase, `<mark>${phase}</mark>`);
      }
    }

    return html;
  }

  ngOnInit() {
    this.posts$ = combineLatest([
      this.searchChange$,
      this.form.valueChanges.pipe(startWith({ sections: [], tags: [], sort: '-createdAt' })),
    ]).pipe(
      switchMap(val => {
        const [q, form] = val;
        return this.postsService
          .getPublications(this.siteId, form.sections, form.tags, form.sort, q as string)
          .pipe(
            catchError(() => {
              this.snack.open('Request error', 'ok');
              return EMPTY;
            })
          );
      })
    );
  }
}
