import Quill from 'quill';
import { AttachItemResponse } from '../../../../../api';
import { CachedAttach } from './cached-attach';

export class FacebookEmbed extends CachedAttach {
  static blotName = 'attachFacebook';
  static tagName = 'figure';

  static render(node: HTMLElement, value: AttachItemResponse) {
    // console.log('facebook render', value);
    super.render(node, value);

    let extra = JSON.parse(value.embedExtra);
    node.innerHTML = `
    <div class="fcbk-holder frame">${extra.html}</div>
    <figcaption>
        <div class="caption">${value.caption || ''}</div>
        <div class="copyright">${value.copyright || ''}</div>
    </figcaption>`;

    if (window['FB']) {
      setTimeout(() => {
        window['FB'].init({
          xfbml: true,
          version: 'v3.2',
        });
      }, 20);
    }

    return node;
  }
}

Quill.register(FacebookEmbed);
