import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { AttachItemComponent } from './components/attach-item/attach-item.component';
import { CreateEmbedComponent } from './components/create-embed/create-embed.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { LinkDialogComponent } from './components/link-dialog/link-dialog.component';
import { PublicationFormComponent } from './components/publication-form/publication-form.component';
import { LinkPreviewComponent } from './components/quill-editor/link-preview/link-preview.component';
import { QuillEditorComponent } from './components/quill-editor/quill-editor.component';
import { DragService } from './drag.service';

@NgModule({
  declarations: [
    PublicationFormComponent,
    FileUploaderComponent,
    QuillEditorComponent,
    AttachItemComponent,
    CreateEmbedComponent,
    LinkPreviewComponent,
    LinkDialogComponent,
  ],
  providers: [DragService],
  imports: [SharedModule],
  exports: [PublicationFormComponent],
  entryComponents: [CreateEmbedComponent, LinkDialogComponent],
})
export class PublicationEditorModule {}
