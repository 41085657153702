import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
export class NgxTweetService {
    constructor() {
        this.TWITTER_OBJECT = 'twttr';
        this.TWITTER_SCRIPT_ID = 'twitter-wjs';
        this.TWITTER_WIDGET_URL = 'https://platform.twitter.com/widgets.js';
    }
    loadScript() {
        return Observable.create((observer) => {
            this._startScriptLoad();
            window[this.TWITTER_OBJECT].ready(this._onTwitterScriptLoadedFactory(observer));
        });
    }
    _startScriptLoad() {
        const twitterData = window[this.TWITTER_OBJECT] || {};
        if (this._twitterScriptAlreadyExists()) {
            window[this.TWITTER_OBJECT] = twitterData;
            return;
        }
        this._appendTwitterScriptToDOM();
        twitterData._e = [];
        twitterData.ready = (callback) => {
            twitterData._e.push(callback);
        };
        // console.log('twitter data', twitterData);
        window[this.TWITTER_OBJECT] = twitterData;
    }
    _twitterScriptAlreadyExists() {
        const twitterScript = document.getElementById(this.TWITTER_SCRIPT_ID);
        return twitterScript !== null || typeof twitterScript !== 'object';
    }
    _appendTwitterScriptToDOM() {
        const firstJSScript = document.getElementsByTagName('script')[0];
        const js = document.createElement('script');
        js.id = this.TWITTER_SCRIPT_ID;
        js.src = this.TWITTER_WIDGET_URL;
        firstJSScript.parentNode.insertBefore(js, firstJSScript);
    }
    _onTwitterScriptLoadedFactory(observer) {
        return (twitterData) => {
            observer.next(twitterData);
            observer.complete();
        };
    }
}
NgxTweetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NgxTweetService_Factory() { return new NgxTweetService(); }, token: NgxTweetService, providedIn: "root" });
