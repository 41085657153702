import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CurrentSiteState, CurrentSiteStateModel } from '../../state/current-site.state';

@Component({
  selector: 'tsa-site-root',
  templateUrl: './site-root.component.html',
  styleUrls: ['./site-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteRootComponent implements OnInit {
  site$: Observable<CurrentSiteStateModel> = this.store.select(CurrentSiteState);

  constructor(private store: Store) {}

  ngOnInit() {}
}
