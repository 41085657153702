/**
 * Telesite
 * Platform for fast start your site.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PhotoInfo } from './photoInfo';


export interface MainPhotoItemResponse { 
    id: string;
    photo?: PhotoInfo;
    publicUrl: string;
    caption?: string;
    copyright?: string;
    position?: MainPhotoItemResponse.PositionEnum;
}
export namespace MainPhotoItemResponse {
    export type PositionEnum = 'inside' | 'outside' | 'left' | 'right';
    export const PositionEnum = {
        Inside: 'inside' as PositionEnum,
        Outside: 'outside' as PositionEnum,
        Left: 'left' as PositionEnum,
        Right: 'right' as PositionEnum
    };
}
