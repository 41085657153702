<tsa-user-toolbar></tsa-user-toolbar>
<div style="background-color: #eeeeee">
  <div class="container">
    <div style="padding: 56px 0;">
      <h1 class="mat-display-1" style="margin-bottom: 20px">
        Выберите сайт или подключите новый
      </h1>
      <div class="mat-subheading-1" style="max-width: 400px">
        Здесь отображается список сайтов котоые вы создали, а также где вы редактор.
      </div>
    </div>
  </div>
</div>

<div class="container" style="margin-top: 16px;">
  <div *ngIf="sites$ | async as sites">
    <h3 class="mat-secondary" fxLayout="row" fxLayoutAlign="space-between center">
      <span>
        Сайты<span *ngIf="sites.count > 0"> - {{ sites.count }}</span>
      </span>
      <button mat-raised-button color="primary" *ngIf="sites?.count > 0" routerLink="/sites/new">
        Создать сайт
      </button>
    </h3>

    <tsa-inline-loader *ngIf="sites.loading; else sitesList"></tsa-inline-loader>

    <ng-template #sitesList>
      <mat-card style="text-align: center" *ngIf="sites.count == 0">
        <h3>У вас пока нет сайтов.</h3>
        <button mat-raised-button color="accent" routerLink="/sites/new">
          Создать свой перый сайт
        </button>
      </mat-card>

      <div>
        <mat-nav-list>
          <a
            mat-list-item
            [routerLink]="['/site', site.id]"
            *ngFor="let site of sites.items"
            class="mat-elevation-z1"
            style="margin-bottom: 16px"
          >
            <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
              <span>
                {{ site.name }}
                <i class="mat-hint" style="font-size: 12px" matTooltip="Ваша роль">[{{ role(site) }}]</i>
              </span>

              <code class="mat-small">{{ site.origin }}</code>
            </div>
          </a>
        </mat-nav-list>
      </div>
    </ng-template>
  </div>
</div>
