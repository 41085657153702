import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'tsa-link-dialog',
  templateUrl: './link-dialog.component.html',
  styleUrls: ['./link-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;

  isMobile = this.platform.ANDROID || this.platform.IOS;
  lastLinksText = [];
  lastLinksUrl = [];
  destroy$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { url: string; text: string },
    private fb: FormBuilder,
    private platform: Platform,
    private dialogRef: MatDialogRef<LinkDialogComponent>
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      text: this.fb.control(this.data.text, Validators.required),
      url: this.fb.control(this.data.url, [
        Validators.required,
        Validators.pattern(
          '(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9\\][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]\\.[^\\s]{2,})'
        ),
      ]),
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onChangeLink(e: ClipboardEvent) {
    const text = e.clipboardData.getData('text');

    const preLinkRe = /^(?:www\.|(?!www))[a-zA-Z0-9\][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,}/;
    const userNameRe = /^@\w{4,}/;
    if (preLinkRe.test(text) && !text.startsWith('http')) {
      this.form.get('url').patchValue('http://' + text);
      return false;
    }
    if (userNameRe.test(text)) {
      this.form.get('url').patchValue('https://t.me/' + text.replace('@', ''));
      return false;
    }
  }

  selectLinkText(e) {
    this.form.get('text').patchValue(e.value);
  }

  selectLinkUrl(e) {
    this.form.get('url').patchValue(e.value);
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

  closeForm() {
    this.dialogRef.close();
  }
}
