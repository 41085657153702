<mat-toolbar>
  <div>Редактор публикации</div>
  <span style="flex: 1 1 auto;"></span>
  <div style="font-size: 13px; margin-right: 16px">
    <span *ngIf="post.state === 'draft'" matTooltip="Публикация еще не видна на сайте">
      Черновик
    </span>
    <span *ngIf="post.state === 'queue'" matTooltip="Публикации будет автоматически опубликована в: ">
      Запланирован
    </span>
    <span *ngIf="post.state === 'published'" matTooltip="Публикация видна на сайте">
      <a
        href="http://{{ origin }}/p/{{ post.publication.slug }}"
        style="font-size: 13px;margin-right: 16px"
        target="_blank"
      >
        Опубликован
      </a>
    </span>
  </div>
  <div *ngIf="post.state === 'draft'">
    <button
      mat-button
      color="primary"
      (click)="scheduleDialogOpen()"
      [disabled]="form.invalid || publishing"
      style="margin-right: 16px"
    >
      Запланировать
    </button>
    <button mat-flat-button color="primary" (click)="publish()" [disabled]="form.invalid || publishing">
      <mat-icon>publish</mat-icon>
      Опубликовать
    </button>
  </div>
  <div *ngIf="post.state === 'queue'">
    <button
      mat-button
      color="primary"
      (click)="scheduleDialogOpen()"
      [disabled]="form.invalid || publishing"
      style="margin-right: 16px"
    >
      {{ post.schedulePublishAt | date: 'medium' }}
    </button>
    <button mat-flat-button color="primary" (click)="publish()" [disabled]="form.invalid || publishing">
      Опубликовать сейчас
    </button>
  </div>
  <div *ngIf="post.state === 'published'">
    <button
      mat-flat-button
      color=""
      [disabled]="form.invalid || publishing"
      style="margin-right: 16px"
      (click)="deletePublication()"
    >
      Снести с сайта
    </button>
    <button mat-flat-button color="primary" [disabled]="form.invalid || publishing" (click)="publish()">
      Обновить на сайте
    </button>
  </div>
</mat-toolbar>
<mat-tab-group
  backgroundColor="primary"
  color="warn"
  mat-align-tabs="center"
  animationDuration="0ms"
  #tabs
  dynamicHeight
  style="min-height: 500px"
>
  <mat-tab label="Настройки ленты">
    <h2>Настройки ленты</h2>
    <tsa-feed-settings-form [form]="form" (nextTab)="tabs.selectedIndex = 1"></tsa-feed-settings-form>
  </mat-tab>

  <mat-tab label="Контент">
    <ng-template matTabContent>
      <!--    use ng-template for lazy load component-->
      <h2 fxLayout="row">
        Контент
        <span style="flex: 1 1 auto;"></span>
        <button mat-button matTooltip="Типографировать весь контент" (click)="editor.typographyAll()">
          Типографировать
        </button>
      </h2>
      <tsa-content-editor [control]="form.get('editorJsData')" #editor></tsa-content-editor>
      <div align="end">
        <button mat-flat-button color="primary" (click)="tabs.selectedIndex = 2">Дальше</button>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab label="SEO">
    <ng-template matTabContent>
      <h2>SEO</h2>
      <tsa-seo-settings-form [form]="form" (nextTab)="tabs.selectedIndex = 3"></tsa-seo-settings-form>
    </ng-template>
  </mat-tab>

  <mat-tab label="Теги">
    <ng-template matTabContent>
      <h2>Теги</h2>
      <tsa-post-tags [form]="form"></tsa-post-tags>
    </ng-template>
  </mat-tab>

  <mat-tab label="Вид в ленте" [disabled]="form.invalid">
    <ng-template matTabContent>
      <h2>Вид в ленте</h2>
      <tsa-post-preview-feed></tsa-post-preview-feed>
    </ng-template>
  </mat-tab>

  <mat-tab label="Предпросмотр" [disabled]="form.invalid">
    <ng-template matTabContent>
      <h2>Предпросмотр</h2>
      <tsa-post-preview-full></tsa-post-preview-full>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<ng-template #scheduleDialog>
  <h1 mat-dialog-title>Запланировать публикацию</h1>
  <mat-dialog-content [formGroup]="_scheduleForm">
    <p>
      <mat-form-field>
        <input
          matInput
          [min]="minQueueDate"
          [max]="maxQueueDate"
          [matDatepicker]="dp3"
          placeholder="Дата публикации"
          readonly
          formControlName="date"
        />
        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
      </mat-form-field>
      <mat-form-field style="width: 100px; margin-left: 24px">
        <mat-select placeholder="Часы" formControlName="hour">
          <mat-option [value]="h" *ngFor="let h of hours">{{ h < 10 ? '0' + h : h }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="width: 100px; margin-left: 24px">
        <mat-select placeholder="Минуты" formControlName="minutes">
          <mat-option [value]="h" *ngFor="let h of minutes">{{ h < 10 ? '0' + h : h }}</mat-option>
        </mat-select>
      </mat-form-field>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close="">Отмена</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="_scheduleForm.invalid || publishing"
      (click)="schedule()"
    >
      Запланировать
    </button>
  </mat-dialog-actions>
</ng-template>
