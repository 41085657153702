<div style="text-align: center" (click)="input.click()">
  <mat-icon>attachment</mat-icon>
  <br />
  Загрузить
</div>
<input
  type="file"
  style="width: 0; height: 0;display: none;"
  [accept]="accept"
  #input
  (change)="selectFiles(input.files)"
/>
