/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-draft-publication.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./create-draft-publication.component";
import * as i3 from "@ngxs/store";
import * as i4 from "@angular/router";
var styles_CreateDraftPublicationComponent = [i0.styles];
var RenderType_CreateDraftPublicationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateDraftPublicationComponent, data: {} });
export { RenderType_CreateDraftPublicationComponent as RenderType_CreateDraftPublicationComponent };
export function View_CreateDraftPublicationComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" create-draft-publication works!\n"]))], null, null); }
export function View_CreateDraftPublicationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tsa-create-draft-publication", [], null, null, null, View_CreateDraftPublicationComponent_0, RenderType_CreateDraftPublicationComponent)), i1.ɵdid(1, 114688, null, 0, i2.CreateDraftPublicationComponent, [i3.Store, i4.Router, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateDraftPublicationComponentNgFactory = i1.ɵccf("tsa-create-draft-publication", i2.CreateDraftPublicationComponent, View_CreateDraftPublicationComponent_Host_0, {}, {}, []);
export { CreateDraftPublicationComponentNgFactory as CreateDraftPublicationComponentNgFactory };
