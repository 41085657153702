import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import * as Sentry from '@sentry/browser';
import { UserItem, UsersService } from '../../api';
import { SetAuthResult, SignOut, UserSignInSuccess } from './user.actions';

export interface UserStateModel extends Partial<UserItem> {
  token?: string;
  isAuth: boolean;
}

function setSentryContext(auth: UserStateModel) {
  Sentry.configureScope(scope => {
    scope.setUser({
      displayName: auth.displayName,
      email: auth.email,
      id: String(auth.id),
    });
  });
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    isAuth: false,
  },
})
export class UserState implements NgxsOnInit {
  constructor(private api: UsersService, private router: Router, private ngZone: NgZone) {}

  @Selector()
  static getToken(state: UserStateModel) {
    return state.token;
  }

  @Selector()
  static getIsAuth(state: UserStateModel) {
    return state.isAuth;
  }

  ngxsOnInit(ctx: StateContext<UserStateModel>) {
    let auth = ctx.getState();
    if (auth.isAuth) {
      setSentryContext(auth);
    }
  }

  @Action(SetAuthResult)
  setAuthResult(ctx: StateContext<UserStateModel>, { payload }: SetAuthResult) {
    ctx.setState({
      ...payload,
      isAuth: true,
    });
    ctx.dispatch(new UserSignInSuccess());
  }

  @Action(UserSignInSuccess)
  loginSuccess() {
    return this.ngZone.run(() => this.router.navigate(['sites']));
  }

  @Action(SignOut)
  singOut(ctx: StateContext<UserStateModel>) {
    ctx.setState({ isAuth: false });
    return this.ngZone.run(() => this.router.navigate(['/']));
  }
}
