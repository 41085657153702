import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContentEditorComponent } from './components/content-editor/content-editor.component';
import { ErrorBlockComponent } from './components/error-block/error-block.component';
import { InlineLoaderComponent } from './components/inline-loader/inline-loader.component';
import { UserToolbarComponent } from './components/user-toolbar/user-toolbar.component';
import { MaterialModule } from './material/material.module';
import { NgxTweetService } from './ngx-tweet.service';

@NgModule({
  declarations: [InlineLoaderComponent, ErrorBlockComponent, UserToolbarComponent, ContentEditorComponent],
  imports: [CommonModule, RouterModule, ReactiveFormsModule, MaterialModule, FlexLayoutModule],
  providers: [NgxTweetService],
  exports: [
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    InlineLoaderComponent,
    ErrorBlockComponent,
    CommonModule,
    UserToolbarComponent,
    ContentEditorComponent,
  ],
})
export class SharedModule {}
