import Quill from 'quill';
import { AttachItemResponse } from '../../../../../api';

let BlockEmbed = Quill.import('blots/block/embed');

export let cache = new Map<string, { node: HTMLElement; value: AttachItemResponse }>();

export class CachedAttach extends BlockEmbed {
  static render(node: HTMLElement, value: AttachItemResponse): HTMLElement {
    node.setAttribute('data', JSON.stringify(value));
    node.setAttribute('contenteditable', 'false');
    node.setAttribute('attach-id', String(value.id));
    node.classList.add(value.width);
    node.classList.add(`attach-${value.kind}`);
    return node;
  }

  static getKey(value: AttachItemResponse) {
    return `attach-${value.id}`;
  }

  static create(value: AttachItemResponse) {
    let key = this.getKey(value);
    let cached = cache.get(key);
    if (cached) {
      return this.updateState(cached.node, cached.value, value);
    }
    let node = this.render(super.create(), value);
    cache.set(key, { node, value });
    return node;
  }

  static updateState(
    node: HTMLElement,
    oldData: AttachItemResponse,
    newData: AttachItemResponse
  ): HTMLElement {
    let changed = false;
    if (oldData.caption !== newData.caption || oldData.copyright !== newData.copyright) {
      node.querySelector('figcaption').innerHTML = `<div class="caption">${newData.caption || ''}</div>
        <div class="copyright">${newData.copyright || ''}</div>`;
      changed = true;
    }
    if (oldData.width !== newData.width) {
      node.classList.remove(oldData.width);
      node.classList.add(newData.width);
      changed = true;
    }
    if (changed) {
      node.setAttribute('data', JSON.stringify(newData));
      cache.set(this.getKey(oldData), { node, value: newData });
    }
    return node;
  }

  static value(node) {
    return JSON.parse(node.getAttribute('data'));
  }
}
