import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, distinctUntilKeyChanged, filter, finalize } from 'rxjs/operators';
import { MainPhotoItemResponse, PostItemResponse, PostsService } from '../../../../api';
import { CurrentSiteState } from '../../../state/current-site.state';
import { LoadPostAttaches, SetMainPhoto, UpdateMainPhotoMeta } from '../../../state/post-editor.actions';
import { PostEditorState } from '../../../state/post-editor.state';

@Component({
  selector: 'tsa-feed-settings-form',
  templateUrl: './feed-settings-form.component.html',
  styleUrls: ['./feed-settings-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedSettingsFormComponent implements OnInit, OnDestroy {
  sections = this.store.selectSnapshot(CurrentSiteState.sections);
  siteId = this.store.selectSnapshot(CurrentSiteState.id);
  postId = this.store.selectSnapshot(PostEditorState.postId);
  post = this.store.selectSnapshot(PostEditorState.post);

  uploading = false;
  mainImage: MainPhotoItemResponse = this.post.mainImage;

  @Input() form: FormGroup;

  mainPhotoForm = new FormGroup({
    caption: new FormControl(this.mainImage ? this.mainImage.caption : ''),
    copyright: new FormControl(this.mainImage ? this.mainImage.copyright : ''),
    position: new FormControl(this.mainImage ? this.mainImage.position : 'inside'),
  });

  @Output() nextTab = new EventEmitter();

  constructor(private store: Store, private posts: PostsService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.mainPhotoForm.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        filter(() => !!this.mainImage)
      )
      .subscribe(rs => this.store.dispatch(new UpdateMainPhotoMeta(rs)));

    this.store
      .select(PostEditorState.post)
      .pipe(
        untilDestroyed(this),
        filter(x => !!x.mainImage),
        distinctUntilKeyChanged('mainImage', (x, y) => x.id === y.id)
      )
      .subscribe((rs: PostItemResponse) => {
        // main image is changed, need set old caption and copyright
        if (this.mainImage) {
          this.store.dispatch(new UpdateMainPhotoMeta(this.mainPhotoForm.value));
        }
        this.mainImage = rs.mainImage;
        this.cd.detectChanges();
      });
  }

  uploadMainPhoto(files: FileList) {
    this.uploading = true;
    this.posts
      .uploadMainPhoto(files[0], this.postId, this.siteId)
      .pipe(
        finalize(() => {
          this.uploading = false;
          this.cd.detectChanges();
        })
      )
      .subscribe(rs => {
        this.mainImage = rs;
        this.store.dispatch([new SetMainPhoto(rs), new LoadPostAttaches(this.postId)]);
        this.cd.detectChanges();
      });
  }

  changeMainImage(files: FileList) {
    if (files.length === 0) {
      return;
    }
    this.uploadMainPhoto(files);
  }

  public ngOnDestroy(): void {}
}
