import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { CurrentSiteState } from '../../../state/current-site.state';
import { PostEditorState } from '../../../state/post-editor.state';

@Component({
  selector: 'tsa-post-preview-feed',
  templateUrl: './post-preview-feed.component.html',
  styleUrls: ['./post-preview-feed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostPreviewFeedComponent implements OnInit {
  postId = this.store.selectSnapshot(PostEditorState.postId);
  site = this.store.selectSnapshot(CurrentSiteState.data);

  frameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
    `/api/preview/${this.site.id}/post/${this.postId}/feed`
  );

  constructor(private store: Store, private sanitizer: DomSanitizer) {}

  ngOnInit() {}
}
