let replacer = text => {
  let newText = text
    .replace(/(^|\s)'([\wа-яії])/gi, '$1«$2') // opening single
    .replace(/([\wа-яії\.])'($|\s|\.|\,)/gi, '$1»$2') // closing single
    .replace(/(^|\s)"([\wа-яії])/gi, '$1«$2') // opening double
    .replace(/([\wа-яії\.])"($|\s|\.|\,)/gi, '$1»$2') // closing double
    .replace(/--/g, '\u2014');
  if (text !== newText) {
    return [true, newText];
  }
  return [false, text];
};
export function typographyElement(node: HTMLElement | ChildNode) {
  for (let child of Array.from(node.childNodes)) {
    if (child.nodeType === 3) {
      let [changed, newText] = replacer(child.textContent);
      if (changed) {
        child.textContent = newText;
      }
    } else {
      typographyElement(child);
    }
  }
}
