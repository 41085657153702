import * as tslib_1 from "tslib";
import { Action, State, Store } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SitesService } from '../../api';
import { CreateSite, CreateSiteFail, CreateSiteSuccess, LoadSites, LoadSitesFail, LoadSitesSuccess, } from './sites.actions';
export class SitesStateModel {
}
let SitesState = class SitesState {
    constructor(api, store) {
        this.api = api;
        this.store = store;
    }
    ngxsOnInit(ctx) {
        // let isAuth = this.store.selectSnapshot(UserState.getIsAuth);
        // if (isAuth) {
        //   ctx.dispatch(new LoadSites());
        // }
    }
    create(ctx, { payload }) {
        return this.api.createSite(payload).pipe(tap(rs => ctx.dispatch(new CreateSiteSuccess(rs))), catchError(err => {
            ctx.dispatch(new CreateSiteFail(err));
            return throwError(err);
        }));
    }
    createSuccess(ctx, { payload }) {
        let state = ctx.getState();
        ctx.patchState({
            items: [payload, ...state.items],
            count: state.count + 1,
        });
    }
    load(ctx) {
        ctx.patchState({ loading: true });
        return this.api.getSites().pipe(tap(rs => ctx.dispatch(new LoadSitesSuccess(rs))), catchError(err => {
            ctx.dispatch(new LoadSitesFail(err));
            return throwError(err);
        }));
    }
    loadSuccess(ctx, { payload }) {
        ctx.patchState(Object.assign({ loading: false }, payload));
    }
};
tslib_1.__decorate([
    Action(CreateSite),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CreateSite]),
    tslib_1.__metadata("design:returntype", void 0)
], SitesState.prototype, "create", null);
tslib_1.__decorate([
    Action(CreateSiteSuccess),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CreateSiteSuccess]),
    tslib_1.__metadata("design:returntype", void 0)
], SitesState.prototype, "createSuccess", null);
tslib_1.__decorate([
    Action(LoadSites),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SitesState.prototype, "load", null);
tslib_1.__decorate([
    Action(LoadSitesSuccess),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, LoadSitesSuccess]),
    tslib_1.__metadata("design:returntype", void 0)
], SitesState.prototype, "loadSuccess", null);
SitesState = tslib_1.__decorate([
    State({
        name: 'sites',
        defaults: {
            items: [],
            count: 0,
            loading: false,
        },
    }),
    tslib_1.__metadata("design:paramtypes", [SitesService, Store])
], SitesState);
export { SitesState };
