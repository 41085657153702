/**
 * Telesite
 * Platform for fast start your site.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DefaultService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param url 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiEmbedFacebookGet(url: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiEmbedFacebookGet(url: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiEmbedFacebookGet(url: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiEmbedFacebookGet(url: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (url === null || url === undefined) {
            throw new Error('Required parameter url was null or undefined when calling apiEmbedFacebookGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (url !== undefined && url !== null) {
            queryParameters = queryParameters.set('url', <any>url);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/embed/facebook`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param url 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiEmbedInstagramGet(url: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiEmbedInstagramGet(url: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiEmbedInstagramGet(url: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiEmbedInstagramGet(url: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (url === null || url === undefined) {
            throw new Error('Required parameter url was null or undefined when calling apiEmbedInstagramGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (url !== undefined && url !== null) {
            queryParameters = queryParameters.set('url', <any>url);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/embed/instagram`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param gameId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiEmbedTeleplayGet(gameId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiEmbedTeleplayGet(gameId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiEmbedTeleplayGet(gameId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiEmbedTeleplayGet(gameId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (gameId === null || gameId === undefined) {
            throw new Error('Required parameter gameId was null or undefined when calling apiEmbedTeleplayGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gameId !== undefined && gameId !== null) {
            queryParameters = queryParameters.set('gameId', <any>gameId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/embed/teleplay`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param url 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiEmbedTwitterGet(url: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiEmbedTwitterGet(url: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiEmbedTwitterGet(url: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiEmbedTwitterGet(url: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (url === null || url === undefined) {
            throw new Error('Required parameter url was null or undefined when calling apiEmbedTwitterGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (url !== undefined && url !== null) {
            queryParameters = queryParameters.set('url', <any>url);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/embed/twitter`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authGoogleCallbackGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authGoogleCallbackGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authGoogleCallbackGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authGoogleCallbackGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/auth/google/callback`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authGoogleGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authGoogleGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authGoogleGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authGoogleGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/auth/google`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public previewSiteIdPostPostIdFeedGet(postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public previewSiteIdPostPostIdFeedGet(postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public previewSiteIdPostPostIdFeedGet(postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public previewSiteIdPostPostIdFeedGet(postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling previewSiteIdPostPostIdFeedGet.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling previewSiteIdPostPostIdFeedGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/preview/${encodeURIComponent(String(siteId))}/post/${encodeURIComponent(String(postId))}/feed`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param postId 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public previewSiteIdPostPostIdPostGet(postId: string, siteId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public previewSiteIdPostPostIdPostGet(postId: string, siteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public previewSiteIdPostPostIdPostGet(postId: string, siteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public previewSiteIdPostPostIdPostGet(postId: string, siteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling previewSiteIdPostPostIdPostGet.');
        }

        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling previewSiteIdPostPostIdPostGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/preview/${encodeURIComponent(String(siteId))}/post/${encodeURIComponent(String(postId))}/post`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
