export enum BlockType {
  Paragraph = 'paragraph',
  Header = 'header',
  List = 'list',
  Code = 'code',
  Checklist = 'checklist',
  Delimiter = 'delimiter',
  Quote = 'quote',
  Warning = 'warning',
  Embed = 'embed',
  Image = 'image',
  Summary = 'summary',
}
