<div [formGroup]="form" attr.data-attachid="{{ attach.id }}">
  <header fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <ng-container *ngIf="attached$ | async; else dragger">
      <mat-icon matTooltip="Уже используется">done</mat-icon>
    </ng-container>
    <ng-template #dragger>
      <mat-icon class="drag-icon" draggable="true" (dragstart)="dragStart($event)" (dragend)="dragEnd($event)"
        >menu</mat-icon
      >
    </ng-template>
    <div fxFlex="1 1 100%">
      <b fxLayout="row" fxLayoutAlign="start center">
        <span *ngIf="attach.kind === 'photo'">←Картинка</span>
        <span *ngIf="attach.kind === 'facebook'">←Facebook</span>
        <span *ngIf="attach.kind === 'instagram'">←Instagram</span>
        <span *ngIf="attach.kind === 'twitter'">←Twitter</span>
        <span *ngIf="attach.kind === 'vimeo'">←Vimeo</span>
        <span *ngIf="attach.kind === 'youtube'">←Youtube</span>
        <span *ngIf="attach.kind === 'teleplay'">←Teleplay</span>
      </b>
    </div>
    <button
      mat-icon-button
      (click)="deleteItem()"
      matTooltip="Удалить"
      aria-label="Delete attach"
      [disabled]="attach.uploading"
    >
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div *ngIf="attach.uploading" style="padding: 32px">
    <mat-progress-spinner
      mode="indeterminate"
      [strokeWidth]="4"
      [diameter]="40"
      style="margin: 0 auto"
    ></mat-progress-spinner>
    <div style="text-align: center; padding: 8px">{{ attach.progress }}%</div>
  </div>

  <div *ngIf="attach.publicUrl && attach.kind === 'photo'" class="image-preview">
    <img [src]="attach.photo.sizes.mobile.url" draggable="false" />
  </div>

  <div *ngIf="attach.kind === 'youtube'" class="image-preview">
    <iframe
      width="100%"
      height="250"
      [src]="trustedUrl"
      frameborder="0"
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div *ngIf="attach.kind === 'instagram'" class="inst-holder image-preview">
    <!--<iframe class="instagram-media instagram-media-rendered" [src]="trustedUrl" allowtransparency="true" allowfullscreen="true" frameborder="0" height="250" scrolling="no"></iframe>-->
  </div>

  <div *ngIf="attach.kind === 'facebook'" class="fcbk-holder image-preview"></div>

  <div *ngIf="attach.kind === 'twitter'" class="twitter-holder image-preview"></div>

  <div *ngIf="attach.kind === 'vimeo'" class="image-preview">
    <iframe
      [src]="trustedUrl"
      width="100%"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
    ></iframe>
  </div>

  <div *ngIf="attach.kind === 'teleplay'" class="image-preview">
    <h2>{{ extra.info.title }}</h2>
    <img [src]="extra.info.photoUrl" />
  </div>

  <div *ngIf="attach.kind !== 'photo'" style="padding: 8px">
    <textarea rows="2" style="width: 100%;box-sizing: border-box;" readonly>{{ attach.publicUrl }}</textarea>
  </div>

  <!--preload drag image-->
  <img src="assets/drag-and-drop-cover.png" style="display: none" />
  <ng-container *ngIf="attach.kind !== 'teleplay'">
    <div><input class="card-input" placeholder="Описание" formControlName="caption" /></div>
    <div><input class="card-input" placeholder="Откуда взято" formControlName="copyright" /></div>
    <div class="options">
      <!--<div class="label">Ширина</div>-->

      <mat-button-toggle-group
        appearance="legacy"
        formControlName="width"
        style="background-color: white; font-size: 12px"
      >
        <mat-button-toggle value="standard" disableRipple aria-label="Set width standard">
          Стандартная
        </mat-button-toggle>
        <mat-button-toggle value="wide" disableRipple aria-label="Set width wide">
          Широкая
        </mat-button-toggle>
        <mat-button-toggle value="super_wide" disableRipple aria-label="Set width super_wide">
          Суперширокая
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </ng-container>
</div>
