<tsa-user-toolbar></tsa-user-toolbar>
<div class="container">
  <h1>Создание нового сайта</h1>

  <mat-card style="font-size: 16px; padding: 32px">
    <tsa-error-block *ngIf="error$ | async as createError" [error]="createError"></tsa-error-block>
    <div class="container" style="width: 600px" [formGroup]="form">
      <h2>Как ваш сайт будет называтся:</h2>
      <mat-form-field>
        <input formControlName="name" matInput placeholder="Введите название сайта" #name />
        <mat-hint>Название будет использовано в заголовках сайта</mat-hint>
        <mat-hint align="end">{{ name.value?.length || 0 }}/65</mat-hint>
        <mat-error *ngIf="nameCtrl.invalid">
          <span *ngIf="nameCtrl.hasError('maxlength')">Максимум 65 символов</span>
          <span *ngIf="nameCtrl.hasError('minlength')">Минимум 5 символов</span>
          <span *ngIf="nameCtrl.hasError('required')">Заполните поле</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-select formControlName="lang" placeholder="Язык сайта">
          <mat-option value="ru">ru</mat-option>
          <mat-option value="en">en</mat-option>
        </mat-select>
        <mat-hint>Нужно для коректного отображения дат на сайте</mat-hint>
      </mat-form-field>

      <h2>Короткое имя сайта:</h2>
      <p style="font-size: 15px">
        Каждый сайт имеет свой адрес фортама <code>username</code>.{{ domain }}. Позже вы сможите настроить
        свой домен.
      </p>
      <mat-form-field>
        <input
          formControlName="subDomain"
          maxlength="20"
          matInput
          placeholder="Адрес сайта"
          autocomplete="off"
        />
        <mat-hint>Только английские буквы, цыфры и смволы "_" "-", минимум 5 знаков</mat-hint>
        <span matSuffix>.{{ domain }}</span>

        <mat-error *ngIf="subDomainCtrl.invalid">
          <span *ngIf="subDomainCtrl.hasError('maxlength')">Максимум 20 символов</span>
          <span *ngIf="subDomainCtrl.hasError('minlength')">Минимум 5 символов</span>
          <span *ngIf="subDomainCtrl.hasError('required')">Заполните поле</span>
          <span *ngIf="subDomainCtrl.hasError('pattern')">Неверный формат</span>
        </mat-error>
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        [disabled]="form.invalid || (loading$ | async)"
        (click)="save()"
      >
        <mat-spinner *ngIf="loading$ | async"></mat-spinner>
        Создать сайт
      </button>
      <button mat-button routerLink="/sites">Отмена</button>
    </div>
  </mat-card>
</div>
