import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { debounceTime, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AttachService } from '../../../../api';
import { CurrentSiteState } from '../../../state/current-site.state';
import { PostEditorState } from '../../../state/post-editor.state';

const youtubeRE_first = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/i;
const youtubeRE_second = /^(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]+)/i;
const vimeoRE = /^(?:https?:\/\/)?(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(\d+)/i;
const instagramRE = /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/p\/([a-z\d\_\-]+)/i;
const twitterRE = /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/([a-z\d\_\-]+\/status\/\d+)/i;
const facebookRE = /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/([a-z\_\-\.\d]+\/posts\/\d+)/i;
const teleplayRE = /^(?:https?:\/\/)?tp\.telepost\.me\/game\/([a-f\d]+)/i;

@Component({
  selector: 'tsa-create-embed',
  templateUrl: './create-embed.component.html',
  styleUrls: ['./create-embed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateEmbedComponent implements OnInit, OnDestroy {
  form = new FormGroup({
    link: new FormControl('', this.validate),
  });

  destroy$ = new Subject();

  siteId = this.store.selectSnapshot(CurrentSiteState.id);
  postId = this.store.selectSnapshot(PostEditorState.postId);

  loading = false;
  err: any;

  constructor(
    private store: Store,
    private api: AttachService,
    private ref: MatDialogRef<CreateEmbedComponent>
  ) {}

  ngOnInit() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(200),
        tap(() => {
          this.loading = true;
        }),
        switchMap(rs => this.api.createEmbed({ url: rs.link }, this.postId, this.siteId)),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(rs => this.ref.close(rs), err => (this.err = err));
  }

  validate(control: AbstractControl) {
    if (!control.value) return null;
    if (control.value.match(youtubeRE_first)) {
      return null;
    }
    if (control.value.match(youtubeRE_second)) {
      return null;
    }
    if (control.value.match(vimeoRE)) {
      return null;
    }
    if (control.value.match(instagramRE)) {
      return null;
    }
    if (control.value.match(twitterRE)) {
      return null;
    }
    if (control.value.match(facebookRE)) {
      return null;
    }
    if (control.value.match(teleplayRE)) {
      return null;
    }

    return { unknownLink: true };
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
