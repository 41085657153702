import { UserItem } from '../../api';

export class SetAuthResult {
  static readonly type = '[User] SetAuthResult';
  constructor(public payload: UserItem) {}
}

export class UserSignInSuccess {
  static readonly type = '[User] SignInSuccess';
}

export class SignOut {
  static readonly type = '[User] SingOut';
}
