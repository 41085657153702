import { Route } from '@angular/router';
import { LoadPublicationAttachesResolver } from './load-publication-attaches.resolver';
import { LoadPublicationResolver } from './load-publication.resolver';
import { CreateDraftPublicationComponent } from './pages/create-draft-publication/create-draft-publication.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DraftPostsComponent } from './pages/draft-posts/draft-posts.component';
import { PublicationEditorV2Component } from './pages/publication-editor-v2/publication-editor-v2.component';
import { PublicationEditorComponent } from './pages/publication-editor/publication-editor.component';
import { PublishedPostsComponent } from './pages/published-posts/published-posts.component';
import { QueuePostsComponent } from './pages/queue-posts/queue-posts.component';
import { SiteRootComponent } from './pages/site-root/site-root.component';
import { SiteSectionsResolver } from './site-sections.resolver';
import { SiteTagsResolver } from './site-tags.resolver';
import { SiteResolver } from './site.resolver';
const ɵ0 = () => import("./../site-settings/site-settings.module.ngfactory").then(m => m.SiteSettingsModuleNgFactory);
const routes = [
    {
        path: 'site/:id',
        component: SiteRootComponent,
        resolve: { site: SiteResolver },
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
            { path: 'create-draft-publication', component: CreateDraftPublicationComponent },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'drafts', component: DraftPostsComponent },
            {
                path: 'published',
                resolve: {
                    sections: SiteSectionsResolver,
                    tags: SiteTagsResolver,
                },
                component: PublishedPostsComponent,
            },
            { path: 'queue', component: QueuePostsComponent },
            {
                path: 'settings',
                loadChildren: ɵ0,
            },
            {
                path: 'editor/:publicationId',
                resolve: {
                    sections: SiteSectionsResolver,
                    publication: LoadPublicationResolver,
                    attaches: LoadPublicationAttachesResolver,
                },
                component: PublicationEditorComponent,
            },
            {
                path: 'editor-v2/:publicationId',
                resolve: {
                    sections: SiteSectionsResolver,
                    publication: LoadPublicationResolver,
                    attaches: LoadPublicationAttachesResolver,
                },
                component: PublicationEditorV2Component,
            },
        ],
    },
];
export class SiteModule {
}
export { ɵ0 };
