/**
 * Telesite
 * Platform for fast start your site.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CreateEditorDto { 
    email: string;
    role?: CreateEditorDto.RoleEnum;
}
export namespace CreateEditorDto {
    export type RoleEnum = 'trainee';
    export const RoleEnum = {
        Trainee: 'trainee' as RoleEnum
    };
}
