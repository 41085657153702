<div [formGroup]="form">
  <h2>Добавить ембед</h2>
  <mat-dialog-content>
    Вставьте ссылку на публикацию одной из соц-сетей <br />
    <br />
    <mat-form-field style="width: 400px">
      <textarea
        matInput
        placeholder="Вставьте ссылку"
        rows="2"
        formControlName="link"
        [disabled]="loading"
      ></textarea>
      <mat-hint>Youtube, Vimeo, Twitter, Facebook, Instagram</mat-hint>
      <!--<mat-hint>Youtube, Twitter</mat-hint>-->
      <mat-error *ngIf="form.get('link').invalid">Неизвестная ссылка</mat-error>
    </mat-form-field>
    <div *ngIf="form.get('link').invalid" style="padding: 8px 0; font-size: 12px">
      Допустимые сылки:
      <div><code>https://www.youtube.com/watch?v=_uzndl724L1</code></div>
      <div><code>https://youtu.be/_uzndl724L1</code></div>
      <!--<div><code>https://www.player.vimeo.com/video/12345</code></div>-->
      <div><code>https://www.vimeo.com/12345</code></div>
      <div><code>https://www.instagram.com/p/BsuT1p71gP1/</code></div>
      <div><code>https://twitter.com/driaala_rs/status/101280795783680</code></div>
      <div><code>https://www.facebook.com/micha_dsjj/posts/1256213680201</code></div>
    </div>
  </mat-dialog-content>
</div>
