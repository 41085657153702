import { HttpEventType } from '@angular/common/http';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AttachItemResponse, AttachService, PostItemResponse, PostsService } from '../../api';
import { CurrentSiteState } from './current-site.state';
import {
  AddAttach,
  AddEmbedAttach,
  AttachDeleted,
  CreateDraftPost,
  DeleteAttach,
  LoadPost,
  LoadPostAttaches,
  SetMainPhoto,
  SetPost,
  UpdateAttachMeta,
  UpdateMainPhotoMeta,
  UpdatePost,
  UpdatePostError,
  UploadAttaches,
  UploadMainPhoto,
} from './post-editor.actions';

export interface UploadingAttachItem {
  kind: AttachItemResponse.KindEnum;
  uploading: boolean;
  progress: number;
  file: File;
}

export interface PostFormStateModel {
  postId?: string;
  post?: PostItemResponse;
  attaches: (AttachItemResponse | UploadingAttachItem)[];
}

@State<PostFormStateModel>({
  name: 'publicationEditor',
  defaults: {
    postId: null,
    post: null,
    attaches: [],
  },
})
export class PostEditorState {
  constructor(private store: Store, private posts: PostsService, private attaches: AttachService) {}

  @Selector()
  public static getState(state: PostFormStateModel) {
    return state;
  }

  @Selector()
  public static postId(state: PostFormStateModel) {
    return state.postId;
  }

  @Selector()
  public static post(state: PostFormStateModel) {
    return state.post;
  }

  @Selector()
  public static attaches(state: PostFormStateModel) {
    return state.attaches;
  }

  @Action(CreateDraftPost)
  createDraft({ dispatch, getState, setState }: StateContext<PostFormStateModel>) {
    let siteId = this.store.selectSnapshot(CurrentSiteState.id);
    return this.posts
      .createDraft(siteId)
      .pipe(tap(rs => setState({ postId: rs.id, post: rs, attaches: [] })));
  }

  @Action(LoadPost)
  loadPost(
    { dispatch, getState, setState, patchState }: StateContext<PostFormStateModel>,
    { payload }: LoadPost
  ) {
    let siteId = this.store.selectSnapshot(CurrentSiteState.id);
    return this.posts.getById(payload, siteId).pipe(tap(rs => patchState({ postId: rs.id, post: rs })));
  }

  @Action(LoadPostAttaches)
  loadPostAttaches(
    { dispatch, getState, setState }: StateContext<PostFormStateModel>,
    { payload }: LoadPostAttaches
  ) {
    let siteId = this.store.selectSnapshot(CurrentSiteState.id);
    return this.attaches
      .getAll(payload, siteId)
      .pipe(tap(rs => setState({ ...getState(), attaches: rs.items })));
  }

  @Action(UpdatePost, { cancelUncompleted: true })
  updatePost(
    { dispatch, getState, setState, patchState }: StateContext<PostFormStateModel>,
    { payload }: UpdatePost
  ) {
    let siteId = this.store.selectSnapshot(CurrentSiteState.id);
    let postId = getState().postId;
    return this.posts.update(payload, postId, siteId).pipe(
      catchError(err => {
        dispatch(new UpdatePostError(err));
        return throwError(err);
      }),
      tap(rs => dispatch(new SetPost(rs)))
    );
  }

  @Action(SetPost)
  setPost(
    { dispatch, getState, setState, patchState }: StateContext<PostFormStateModel>,
    { payload }: SetPost
  ) {
    patchState({ post: payload, postId: payload.id });
  }

  @Action(UploadMainPhoto, { cancelUncompleted: true })
  uploadMainPhoto(
    { dispatch, getState, setState, patchState }: StateContext<PostFormStateModel>,
    { payload }: UploadMainPhoto
  ) {
    let siteId = this.store.selectSnapshot(CurrentSiteState.id);
    let postId = getState().postId;
    return this.posts.uploadMainPhoto(payload, postId, siteId).pipe(
      tap(rs => {
        dispatch([new SetMainPhoto(rs), new LoadPostAttaches(postId)]);
      })
    );
  }

  @Action(SetMainPhoto)
  setMainPhoto(
    { dispatch, getState, setState, patchState }: StateContext<PostFormStateModel>,
    { payload }: SetMainPhoto
  ) {
    patchState({ post: { ...getState().post, mainImage: payload } });
  }

  @Action(UpdateMainPhotoMeta, { cancelUncompleted: true })
  updatePostMainPhoto(
    { dispatch, getState, setState, patchState }: StateContext<PostFormStateModel>,
    { payload }: UpdateMainPhotoMeta
  ) {
    let siteId = this.store.selectSnapshot(CurrentSiteState.id);
    let postId = getState().postId;
    return this.posts
      .updateMainPhotoMeta(payload, postId, siteId)
      .pipe(tap(rs => patchState({ post: { ...getState().post, mainImage: rs } })));
  }

  @Action(UploadAttaches)
  uploadAttaches(
    { dispatch, getState, setState, patchState }: StateContext<PostFormStateModel>,
    { payload }: UploadAttaches
  ) {
    let siteId = this.store.selectSnapshot(CurrentSiteState.id);
    let postId = getState().postId;
    let requests: Observable<any>[] = [];
    for (let i = 0; i < payload.length; i++) {
      let file = payload.item(i);
      let uploadingAttach = { kind: 'photo' as any, uploading: true, progress: 0, file: file };
      patchState({ attaches: [...getState().attaches, uploadingAttach] });
      requests.push(
        this.attaches.createPhoto(file, postId, siteId, 'events', true).pipe(
          tap(rs => {
            if (rs.type === HttpEventType.UploadProgress) {
              let state = getState();
              let progress = +((rs.loaded / rs.total) * 100).toFixed(2);
              let attachIndex = state.attaches.findIndex(i => i['file'] === file);
              let newArr = Array.from(state.attaches);
              newArr[attachIndex] = { ...uploadingAttach, progress };
              patchState({ attaches: newArr });
            } else if (rs.type === HttpEventType.Response) {
              let state = getState();
              let attaches = state.attaches.filter(i => i['file'] !== file);
              patchState({ attaches: [...attaches, rs.body] });
            }
          })
        )
      );
    }

    return forkJoin(requests);
  }

  @Action(AddEmbedAttach)
  addEmbedAttach(
    { dispatch, getState, setState, patchState }: StateContext<PostFormStateModel>,
    { payload }: AddEmbedAttach
  ) {
    let siteId = this.store.selectSnapshot(CurrentSiteState.id);
    let postId = getState().postId;
    return this.attaches.createEmbed(payload, postId, siteId).pipe(tap(rs => dispatch(new AddAttach(rs))));
  }

  @Action(AddAttach)
  addAttach(
    { dispatch, getState, setState, patchState }: StateContext<PostFormStateModel>,
    { payload }: AddAttach
  ) {
    patchState({ attaches: [...getState().attaches, payload] });
  }

  @Action(DeleteAttach)
  deleteAttach(
    { dispatch, getState, setState, patchState }: StateContext<PostFormStateModel>,
    { payload }: DeleteAttach
  ) {
    let siteId = this.store.selectSnapshot(CurrentSiteState.id);
    let postId = getState().postId;
    let oldAttaches = getState().attaches;
    let attaches = getState().attaches.filter(i => i['id'] !== payload);
    let attach = getState().attaches.find(i => i['id'] === payload);
    patchState({ attaches: attaches });
    return this.attaches._delete(payload, postId, siteId).pipe(
      tap(rs => dispatch(new AttachDeleted(attach))),
      catchError(err => {
        patchState({ attaches: oldAttaches });
        return throwError(err);
      })
    );
  }

  @Action(UpdateAttachMeta)
  updateAttachMeta(
    { dispatch, getState, setState, patchState }: StateContext<PostFormStateModel>,
    { payload, id }: UpdateAttachMeta
  ) {
    let siteId = this.store.selectSnapshot(CurrentSiteState.id);
    let postId = getState().postId;

    let oldAttaches = Array.from(getState().attaches);
    let newAttaches = [];
    for (let attach of oldAttaches) {
      if (attach['id'] === id) {
        newAttaches.push({ ...attach, ...payload });
      } else {
        newAttaches.push(attach);
      }
    }
    patchState({ attaches: newAttaches });
    return this.attaches.updateMeta(id, payload, postId, siteId).pipe(
      catchError(err => {
        patchState({ attaches: oldAttaches });
        return throwError(err);
      })
    );
  }
}
