import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { RangeStatic } from 'quill';
import { fromEvent, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { DragService } from '../../../drag.service';

@Component({
  selector: 'tsa-link-preview',
  template: ``,
  styleUrls: ['./link-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LinkPreviewComponent implements OnInit, OnDestroy {
  @Input()
  editor;

  root = document.createElement('div');
  previewLink = document.createElement('a');
  editAction = document.createElement('span');
  removeAction = document.createElement('span');

  destroy$ = new Subject();

  linkRange: RangeStatic;

  @Output()
  edit = fromEvent(this.editAction, 'click').pipe(
    takeUntil(this.destroy$),
    map(() => this.linkRange),
    tap(() => this.hide())
  );

  @Output()
  remove = fromEvent(this.removeAction, 'click').pipe(
    takeUntil(this.destroy$),
    map(() => this.linkRange),
    tap(() => this.hide())
  );

  constructor(private ds: DragService) {}

  ngOnInit() {
    this.root.classList.add('tsa-link-preview');
    this.previewLink.setAttribute('target', '_blank');
    this.root.style.display = 'none';
    this.editAction.classList.add('edit-action');
    this.removeAction.classList.add('remove-action');
    this.root.appendChild(this.previewLink);
    this.root.appendChild(this.editAction);
    this.root.appendChild(this.removeAction);

    // this.editor.onEditorCreated.pipe(take(1)).subscribe(() => {
    //   // console.log(this.editor.quillEditor);
    //   // console.log(this.editor.bounds);
    //   this.editor.editorElem.appendChild(this.root);
    // });

    // this.editor.on('editor-change', (eventName, ...args) => {
    //   console.log(eventName);
    // });

    // this.editor.onSelectionChanged.pipe(takeUntil(this.destroy$)).subscribe(e => this.onSelectionChanged(e));

    this.ds.linkForPreview.subscribe(link => {
      if (this.editor) {
        this.editor.container.appendChild(this.root);
        this.onSelectionChanged(link);
      }
    });
  }

  onSelectionChanged(e) {
    const { range, oldRange, source, link, offset } = e;
    if (range == null) return;
    if (range.length === 0 && source === 'user') {
      const [link, offset] = (this.editor.editor as any).scroll.descendant(
        bolt => bolt && bolt.statics.blotName === 'link',
        range.index
      );
      if (link != null) {
        this.linkRange = { index: range.index - offset, length: link.length() };
        // console.log(this.editor.quillEditor.getBounds(this.linkRange.index, this.linkRange.length));
        this.previewLink.textContent = link.domNode.href;
        this.previewLink.setAttribute('href', link.domNode.href);
        this.show();
        if (this.editor) {
          this.position(this.editor.getBounds(this.linkRange));
        }
        return;
      }
    } else {
      delete this.linkRange;
    }
    this.hide();
  }

  show() {
    this.root.style.display = 'block';
  }

  hide() {
    this.root.style.display = 'none';
  }

  position(reference) {
    const left = reference.left + reference.width / 2 - this.root.offsetWidth / 2;
    // root.scrollTop should be 0 if scrollContainer !== root
    const top = reference.bottom + this.editor.root.scrollTop;
    this.root.style.left = `${left}px`;
    this.root.style.top = `${top}px`;
    this.root.classList.remove('ql-flip');
    const containerBounds = (this.editor.container as HTMLElement).getBoundingClientRect();
    const rootBounds = this.root.getBoundingClientRect();
    let shift = 0;
    if (rootBounds.right > containerBounds.right) {
      shift = containerBounds.right - rootBounds.right;
      this.root.style.left = `${left + shift}px`;
    }
    if (rootBounds.left < containerBounds.left) {
      shift = containerBounds.left - rootBounds.left;
      this.root.style.left = `${left + shift}px`;
    }
    if (rootBounds.bottom > containerBounds.bottom) {
      const height = rootBounds.bottom - rootBounds.top;
      const verticalShift = reference.bottom - reference.top + height;
      this.root.style.top = `${top - verticalShift}px`;
      this.root.classList.add('ql-flip');
    }
    return shift;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
