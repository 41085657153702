<mat-toolbar color="primary" class="mat-elevation-z1">
  Опубликованые
  <tsa-search-input style="margin-left: 96px;" (keyUp)="search$.next($event)"></tsa-search-input>

  <!--<span style="flex: 1 1 auto;"></span>-->
</mat-toolbar>
<div fxLayout="row" style="padding: 16px 32px 0 32px" [formGroup]="form">
  <div class="form-coll">
    <mat-form-field>
      <mat-select placeholder="Раздел сайта" multiple formControlName="sections">
        <ng-container *ngFor="let section of sections.items">
          <mat-option [value]="section.id">
            {{ section.header }}
          </mat-option>
          <ng-container *ngIf="section.subSections.length">
            <mat-option *ngFor="let section of section.subSections" [value]="section.id">
              &nbsp;&nbsp;- &nbsp; {{ section.header }}
            </mat-option>
          </ng-container>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form-coll">
    <mat-form-field>
      <mat-select placeholder="Теги" multiple formControlName="tags">
        <ng-container *ngFor="let tag of tags">
          <mat-option [value]="tag.id">
            {{ tag.name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form-coll">
    <mat-form-field>
      <mat-select placeholder="Сортовать" formControlName="sort">
        <mat-option value="-createdAt"> Сначала новые </mat-option>
        <mat-option value="createdAt"> Сначала старые </mat-option>
        <mat-option value="score"> Совпадение поиска </mat-option>
        <mat-option value="views"> Просмотров </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="posts-list" style="padding: 32px; padding-top: 16px">
  <div *ngIf="posts$ | async as posts; else loading">
    <table style="width: 100%;" class="posts-table">
      <thead>
        <tr>
          <th width="150">Раздел</th>
          <th>Заголовок</th>
          <th width="200">Фото</th>
          <th width="200">Опубликован</th>
          <th width="200">~</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let pub of posts.items">
          <td style="text-align: center;">{{ pub.section?.header }}</td>
          <td>
            <b [innerHTML]="highlight(pub.title)"></b>
            {{ pub.subTitle }}
          </td>
          <td style="text-align: center">
            <div *ngIf="pub.mainImage">
              <img [src]="pub.mainImage.publicUrl" style="max-width: 150px; max-height: 50px;" />
            </div>
            <div matTooltip="Просмотров публикации">{{ pub.viewsCount | number }}</div>
          </td>
          <td style="text-align: center">
            <b>{{ pub.createdAt | date: 'medium' }}</b> <br />
            {{ pub.publisher.displayName }}
          </td>
          <td style="text-align: center">
            <span *ngIf="pub.legacy" style="color: red">LEGACY</span> <br />
            <a mat-stroked-button color="primary" routerLink="../editor-v2/{{ pub.post }}">Изменить</a>
            <div>
              <a [href]="'http://' + origin + '/p/' + pub.slug" target="_blank" mat-flat-button>
                Ссылка
                <mat-icon>open_in_new</mat-icon>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #loading>
    <mat-progress-spinner
      style="margin: 0 auto"
      mode="indeterminate"
      [strokeWidth]="4"
    ></mat-progress-spinner>
  </ng-template>
</div>
