import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit, } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import EditorJS from '@editorjs/editorjs';
import { Store } from '@ngxs/store';
import { UserState } from '../../../auth/state/user.state';
import { typographyElement } from '../../../lib/typography';
import { CurrentSiteState } from '../../../site/state/current-site.state';
import { PostEditorState } from '../../../site/state/post-editor.state';
import { BlockType } from './block-type';
import { SummaryBlock } from './summary-block';
const Header = require('@editorjs/header');
const Image = require('@editorjs/image');
const List = require('@editorjs/list');
const CodeTool = require('@editorjs/code');
const Checklist = require('@editorjs/checklist');
const Marker = require('@editorjs/marker');
const Delimiter = require('@editorjs/delimiter');
const InlineCode = require('@editorjs/inline-code');
const Quote = require('@editorjs/quote');
const Warning = require('@editorjs/warning');
const Embed = require('@editorjs/embed');
Image.prototype.uploadingFailed = function (error) {
    console.log('Image Tool: uploading failed because of', error);
    let reason = '';
    let header = 'Не удалось сохранить файл, попробуйте другой.';
    if (error.body && error.body.message) {
        reason = error.body.message;
    }
    if (error.code === 400) {
        header = 'Не удалось сохранить файл, попробуйте другой.';
        reason = `<p>Ошибка: ${error.body.message}</p>`;
    }
    this.api.notifier.show({
        message: `${header}
    ${reason}
    `,
        style: 'error',
    });
    this.ui.hidePreloader();
};
class MyHeader extends Header {
    get levels() {
        return super.levels.filter((l) => [2, 3, 4].includes(l.number));
    }
    get defaultLevel() {
        /**
         * Use H2 as default header
         */
        return this.levels[0];
    }
}
export class ContentEditorComponent {
    constructor(store, cd) {
        this.store = store;
        this.cd = cd;
        this.authToken = this.store.selectSnapshot(UserState.getToken);
        this.siteId = this.store.selectSnapshot(CurrentSiteState.id);
        this.postId = this.store.selectSnapshot(PostEditorState.postId);
        this.placeholder = 'Let`s write an awesome story!';
        this.enableImages = true;
        this.imageToolEndpoints = {
            byFile: `/api/sites/${this.siteId}/posts/${this.postId}/upload/file`,
            byUrl: `/api/sites/${this.siteId}/posts/${this.postId}/upload/fetchUrl`,
        };
    }
    /**
     * replace quotes to french quotes
     * @param ev
     */
    onKeyUp(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (ev.ctrlKey && ev.shiftKey && ev.code === 'Digit2') {
                let current = this.editor.blocks.getCurrentBlockIndex();
                let block = this.editor.blocks.getBlockByIndex(current);
                typographyElement(block);
            }
        });
    }
    typographyAll() {
        let count = this.editor.blocks.getBlocksCount();
        for (let i = 0; i < count; i++) {
            let block = this.editor.blocks.getBlockByIndex(i);
            typographyElement(block);
        }
    }
    ngOnInit() { }
    ngOnDestroy() {
        this.editor.destroy();
    }
    ngAfterViewInit() {
        let data = this.control.value ? JSON.parse(this.control.value) : null;
        if (this.enableImages && (!data || data.blocks.length === 0)) {
            let attaches = this.store.selectSnapshot(PostEditorState.attaches);
            if (attaches.length) {
                let attach = attaches[0];
                data = {
                    blocks: [
                        {
                            type: 'image',
                            data: {
                                file: Object.assign({ id: attach.id, url: attach.publicUrl }, attach),
                                caption: '',
                                withBorder: false,
                                stretched: false,
                                withBackground: false,
                            },
                        },
                    ],
                };
            }
        }
        this.editor = new EditorJS({
            holder: 'editor-el',
            autofocus: false,
            placeholder: this.placeholder,
            initialBlock: BlockType.Paragraph,
            data: data,
            onChange: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let data = yield this.editor.saver.save();
                this.editor.emit('dataSaved', data);
                this.control.setValue(JSON.stringify(data));
                this.control.markAsDirty();
                this.cd.markForCheck();
            }),
            tools: this.tools,
        });
    }
    get tools() {
        let tools = {
            marker: {
                class: Marker,
                shortcut: 'CMD+SHIFT+M',
            },
            inlineCode: {
                class: InlineCode,
            },
            [BlockType.Header]: {
                class: MyHeader,
                shortcut: 'CMD+SHIFT+H',
            },
            [BlockType.List]: {
                class: List,
                inlineToolbar: true,
            },
            [BlockType.Code]: CodeTool,
            [BlockType.Checklist]: {
                class: Checklist,
                inlineToolbar: true,
            },
            [BlockType.Delimiter]: Delimiter,
            [BlockType.Quote]: {
                class: Quote,
                inlineToolbar: true,
                shortcut: 'CMD+SHIFT+O',
                config: {
                    quotePlaceholder: 'Ведите цитату',
                    captionPlaceholder: 'Автор цытаты',
                },
            },
            [BlockType.Warning]: {
                class: Warning,
                inlineToolbar: true,
                shortcut: 'CMD+SHIFT+W',
                config: {
                    titlePlaceholder: 'Title',
                    messagePlaceholder: 'Message',
                },
            },
            [BlockType.Summary]: {
                class: SummaryBlock,
                inlineToolbar: false,
            },
            [BlockType.Embed]: {
                class: Embed,
                inlineToolbar: true,
                config: {
                    services: {
                        youtube: true,
                        vimeo: true,
                        twitter: {
                            regex: /(https?:\/\/twitter.com\/.+)/,
                            embedUrl: '/api/embed/twitter/?url=<%= remote_id %>',
                            html: "<iframe height='300' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='width: 100%;'></iframe>",
                            height: 300,
                            width: 580,
                            id: (groups) => groups[0],
                        },
                        instagram: {
                            regex: /(https?:\/\/(?:www\.)instagram.com\/.+)/,
                            embedUrl: '/api/embed/instagram/?url=<%= remote_id %>',
                            html: "<iframe height='300' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='width: 100%'></iframe>",
                            height: 300,
                            width: 580,
                            id: (groups) => groups[0],
                        },
                        facebook: {
                            regex: /(https?:\/\/(?:www\.)facebook.com\/.+)/,
                            embedUrl: '/api/embed/facebook/?url=<%= remote_id %>',
                            html: "<iframe height='300' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='width: 100%'></iframe>",
                            height: 300,
                            width: 580,
                            id: (groups) => groups[0],
                        },
                        teleplay: {
                            regex: /^(?:https?:\/\/)?tp\.telepost\.me\/game\/([a-f\d]+)/i,
                            embedUrl: '/api/embed/teleplay/?gameId=<%= remote_id %>',
                            html: "<iframe height='300' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='width: 100%'></iframe>",
                            height: 300,
                            width: 580,
                            id: (groups) => groups[0],
                        },
                    },
                },
            },
        };
        if (this.enableImages) {
            tools[BlockType.Image] = {
                class: Image,
                inlineToolbar: true,
                config: {
                    endpoints: this.imageToolEndpoints,
                    additionalRequestHeaders: { Authorization: 'Bearer ' + this.authToken },
                },
            };
        }
        return tools;
    }
}
function _resizeIframe(window, width, height) {
    console.log('_resizeIframe', width, height);
    let frames = document.querySelectorAll('iframe');
    frames.forEach(frame => {
        let wind = null;
        try {
            wind = frame.contentWindow;
        }
        catch (r) { }
        if (wind && wind == window) {
            const i = height / width;
            // frame.setAttribute('width', '500');
            frame.setAttribute('height', height + '');
        }
    });
}
window._resizeIframe = _resizeIframe;
