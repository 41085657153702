import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PostsService } from '../../../api';
import { isClassValidatorMessage, parseClassValidatorErrorMessage } from '../../../lib/utils';
import { CurrentSiteState } from '../../state/current-site.state';
import { SetPost, UpdatePost, UpdatePostError } from '../../state/post-editor.actions';
import { PostEditorState } from '../../state/post-editor.state';

@Component({
  selector: 'tsa-publication-editor',
  templateUrl: './publication-editor.component.html',
  styleUrls: ['./publication-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationEditorComponent implements OnInit, OnDestroy {
  form: FormGroup;
  destroy$ = new Subject();

  post = this.store.selectSnapshot(PostEditorState.post);
  site = this.store.selectSnapshot(CurrentSiteState.data);
  origin = this.store.selectSnapshot(CurrentSiteState.origin);

  publishing = false;

  @ViewChild('scheduleDialog', { static: true }) scheduleDialog: TemplateRef<any>;

  constructor(
    private store: Store,
    private actions$: Actions,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private api: PostsService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private snack: MatSnackBar
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      section: [this.post.section ? this.post.section.id : null, Validators.required],
      title: [this.post.title, [Validators.required, Validators.maxLength(200)]],
      subTitle: [this.post.subTitle, Validators.maxLength(200)],
      description: [this.post.description, Validators.maxLength(400)],
      textOps: [this.post.textOps, Validators.required],
      tags: this.post.tags,
    });

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500)
      )
      .subscribe(rs => {
        this.store.dispatch(new UpdatePost(rs));
        this.cd.detectChanges();
      });
    this.actions$
      .pipe(
        takeUntil(this.destroy$),
        ofActionDispatched(UpdatePostError)
      )
      .subscribe(rs => {
        if (isClassValidatorMessage(rs.payload)) {
          this.snack.open('Ошибка: ' + parseClassValidatorErrorMessage(rs.payload), 'ok', {
            duration: 5000,
          });
        } else {
          this.snack.open('Не удалось сохранить пост ', 'ok', { duration: 5000 });
        }
      });
  }

  hours = Array.from(Array(24).keys());
  minutes = Array.from(Array(60).keys());

  minQueueDate = new Date();
  maxQueueDate = new Date(Date.now() + 30 * 24 * 3600 * 1000);

  _scheduleForm = new FormGroup({
    date: new FormControl(),
    hour: new FormControl(),
    minutes: new FormControl(),
  });

  scheduleDialogOpen() {
    let date = new Date(Date.now() + 10 * 3600 * 100);
    if (this.post.schedulePublishAt) {
      date = new Date(this.post.schedulePublishAt);
    }
    this._scheduleForm = new FormGroup({
      date: new FormControl(date, Validators.required),
      hour: new FormControl(date.getHours(), Validators.required),
      minutes: new FormControl(date.getMinutes(), Validators.required),
    });

    this.dialog.open(this.scheduleDialog, {
      width: '550px',
    });
  }

  schedule() {
    let { date: _date, hour, minutes } = this._scheduleForm.value;
    let date = new Date(_date);
    let dateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minutes);
    this.form.disable({ emitEvent: false });
    this.publishing = true;
    this.api.queue({ dateTime: dateTime.toISOString() }, this.post.id, this.post.site.id).subscribe(rs => {
      this.store.dispatch(new SetPost(rs));
      this.publishing = false;
      this.dialog.closeAll();
      this.router.navigate(['../..', 'queue'], { relativeTo: this.route });
    });
  }

  publish() {
    this.form.disable({ emitEvent: false });
    this.publishing = true;
    this.api.publish(this.post.id, this.post.site.id).subscribe(rs => {
      this.store.dispatch(new SetPost(rs));
      this.publishing = false;
      this.router.navigate(['../..', 'published'], { relativeTo: this.route });
    });
  }

  deletePublication() {
    if (confirm('Снести публикацию?')) {
      this.form.disable({ emitEvent: false });
      this.publishing = true;
      this.api.unPublish(this.post.id, this.post.site.id).subscribe(rs => {
        this.form.enable({ emitEvent: false });
        this.store.dispatch(new SetPost(rs));
        this.post = rs;
        this.publishing = false;
      });
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
