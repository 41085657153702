<div [formGroup]="form" class="wrap">
  <mat-form-field class="tags-list">
    <mat-chip-list #chipList aria-label="Tags selection">
      <mat-chip *ngFor="let tag of tags" [selectable]="true" [removable]="true" (removed)="remove(tag.id)">
        {{ tag.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Новый тег..."
        #tagsInput
        [formControl]="tagsCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let tag of tagsFiltered | async" [value]="tag.id">
        {{ tag.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <h3>Предложенные теги</h3>

  <mat-chip-list aria-label="Предложенные теги">
    <mat-chip *ngFor="let t of suggestedTags" (click)="stageTag(t)">{{ t.name }}</mat-chip>
  </mat-chip-list>

  <!--  <mat-form-field style="width: 100%;">-->
  <!--    <mat-select placeholder="Новости по теме" multiple>-->
  <!--      <ng-container *ngFor="let tag of tags">-->
  <!--        <mat-option [value]="tag.id">-->
  <!--          {{ tag.name }}-->
  <!--        </mat-option>-->
  <!--      </ng-container>-->
  <!--    </mat-select>-->
  <!--  </mat-form-field>-->
</div>
