import Quill from 'quill';

const Embed = Quill.import('blots/block/embed');

export class Hr extends Embed {
  static blotName = 'hr';
  static className = 'my-hr';
  static tagName = 'hr';

  static create(value) {
    let node = super.create(value);
    // give it some margin
    node.setAttribute(
      'style',
      'width: 92%;' +
        'margin: 30px auto;' +
        'height: 1px;' +
        'border: none;' +
        'background-image:' +
        'linear-gradient(to right,' +
        'rgba(0, 0, 0, 0) 0%,' +
        'rgb(120, 120, 120) 15%,' +
        'rgb(120, 120, 120) 85%,' +
        'rgba(0, 0, 0, 0));'
    );
    return node;
  }
}

Quill.register({
  'formats/hr': Hr,
});
