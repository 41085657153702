import Quill from 'quill';
import { CachedAttach } from './cached-attach';

export class Photo extends CachedAttach {
  static blotName = 'attachPhoto';
  static tagName = 'figure';

  static render(node, value) {
    super.render(node, value);

    node.innerHTML = `<img src="${value.publicUrl}" alt="${value.caption || ''}">
    <figcaption>
        <div class="caption">${value.caption || ''}</div>
        <div class="copyright">${value.copyright || ''}</div>
    </figcaption>`;

    return node;
  }
}

Quill.register(Photo);
