import EditorJS from '@editorjs/editorjs';
import { BlockType } from './block-type';

export class SummaryBlock {
  public api: EditorJS.API;
  public data: any;
  public wrapper: HTMLDivElement;

  constructor(ctx) {
    this.api = ctx.api;
    this.data = ctx.data.headers;
  }

  destroy() {
    this.api.events.off('dataSaved', this.updateContent.bind(this));
  }

  updateContent(data) {
    this.data = this.getNestedHeaderBlocks(data);
    let html = this.renderNested(this.data);
    if (html!==this.wrapper.innerHTML) {
      this.wrapper.innerHTML = this.renderNested(this.data);
    }
  }

  getNestedHeaderBlocks(data) {
    let headers = data.blocks
      .map((bl, index) => {
        if (bl.type === BlockType.Header) {
          let header = bl.data['text'];
          return {
            header,
            level: bl.data['level'],
            index,
            children: [],
          };
        }
        return null;
      })
      .filter(h => h !== null);
    if (!headers.length) {
      return [];
    }

    function makeNested(inputHeaders) {
      // console.log(inputHeaders);
      let items = [];
      while (inputHeaders.length) {
        let current = inputHeaders.shift();
        let sameLevel = inputHeaders.findIndex(i => i.level === current.level);
        let parentLevel = inputHeaders.findIndex(i => i.level < current.level);
        if (parentLevel !== -1) {
          items.push(current);
          continue;
        }
        if (sameLevel !== -1) {
          current.children = makeNested(inputHeaders.slice(0, sameLevel));
          inputHeaders = inputHeaders.slice(sameLevel);
          items.push(current);
          continue;
        }
        current.children = makeNested(inputHeaders);
        inputHeaders = [];

        items.push(current);
      }
      return items;
    }

    return makeNested(headers);
  }

  renderNested(nested: any[]) {
    let getList = (arr: any[]) => {
      let html = '';
      for (let item of arr) {
        let nested = '';
        if (item.children.length) {
          nested = getList(item.children);
        }
        html += `<li><a href="#loc-${item.index}">${item.header}</a>${
          nested ? `\n<ul>\n${nested}</ul>\n` : ''
        }</li>\n`;
      }
      return html;
    };
    return `<ul class="cdx-block cdx-list cdx-list--unordered telex-summary">${getList(nested)}</ul>\n`;
  }

  static get toolbox() {
    return {
      title: 'Оглавление',
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M2 17h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2v1zm5-6v2h14V5H7zm0 14h14v-2H7v2zm0-6h14v-2H7v2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>',
    };
  }

  render() {
    let div = document.createElement('div');
    div.className = 'ce-paragraph cdx-block';
    this.wrapper = div;
    if (this.data) {
      div.innerHTML = this.renderNested(this.data);
    } else {
      this.api.saver.save().then(rs => {
        this.data = this.getNestedHeaderBlocks(rs);
        div.innerHTML = this.renderNested(this.data);
      });
    }
    this.api.events.on('dataSaved', this.updateContent.bind(this));
    return div;
  }

  save(blockContent) {
    return {
      headers: this.data,
    };
  }
}
