import { HttpErrorResponse } from '@angular/common/http';
import { SiteGetByIdResponse, SiteSectionsResponse, UpdateSiteDto } from '../../api';

export class SelectSite {
  static readonly type = '[CurrentSite] Load';
  constructor(public payload: string) {}
}

export class SelectSiteSuccess {
  static readonly type = '[CurrentSite] Load success';
  constructor(public payload: SiteGetByIdResponse) {}
}

export class UpdateConfig {
  static readonly type = '[CurrentSite] Update config';
  constructor(public payload: UpdateSiteDto) {}
}

export class UpdateConfigSuccess {
  static readonly type = '[CurrentSite] Update config success';
  constructor(public payload: SiteGetByIdResponse) {}
}

export class UpdateConfigFail {
  static readonly type = '[CurrentSite] Update config fail';
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadSections {
  static readonly type = '[CurrentSite] Load sections';
}

export class LoadSectionsSuccess {
  static readonly type = '[CurrentSite] Load sections success';
  constructor(public payload: SiteSectionsResponse) {}
}
