import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SiteListItemResponse, SitesListResponse, SitesService } from '../../api';
import {
  CreateSite,
  CreateSiteFail,
  CreateSiteSuccess,
  LoadSites,
  LoadSitesFail,
  LoadSitesSuccess,
} from './sites.actions';

export class SitesStateModel implements SitesListResponse {
  loading: boolean;
  count: number;
  items: Array<SiteListItemResponse>;
}

@State<SitesStateModel>({
  name: 'sites',
  defaults: {
    items: [],
    count: 0,
    loading: false,
  },
})
export class SitesState implements NgxsOnInit {
  constructor(private api: SitesService, private store: Store) {}

  ngxsOnInit(ctx: StateContext<SitesStateModel>) {
    // let isAuth = this.store.selectSnapshot(UserState.getIsAuth);
    // if (isAuth) {
    //   ctx.dispatch(new LoadSites());
    // }
  }

  @Action(CreateSite)
  create(ctx: StateContext<SitesStateModel>, { payload }: CreateSite) {
    return this.api.createSite(payload).pipe(
      tap(rs => ctx.dispatch(new CreateSiteSuccess(rs))),
      catchError(err => {
        ctx.dispatch(new CreateSiteFail(err));
        return throwError(err);
      })
    );
  }

  @Action(CreateSiteSuccess)
  createSuccess(ctx: StateContext<SitesStateModel>, { payload }: CreateSiteSuccess) {
    let state = ctx.getState();
    ctx.patchState({
      items: [payload, ...state.items],
      count: state.count + 1,
    });
  }

  @Action(LoadSites)
  load(ctx: StateContext<SitesStateModel>) {
    ctx.patchState({ loading: true });
    return this.api.getSites().pipe(
      tap(rs => ctx.dispatch(new LoadSitesSuccess(rs))),
      catchError(err => {
        ctx.dispatch(new LoadSitesFail(err));
        return throwError(err);
      })
    );
  }

  @Action(LoadSitesSuccess)
  loadSuccess(ctx: StateContext<SitesStateModel>, { payload }: LoadSitesSuccess) {
    ctx.patchState({ loading: false, ...payload });
  }
}
