import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'tsa-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements OnInit {
  @Output() keyUp = new Subject();

  focused = false;

  @ViewChild('element', { static: true }) element: ElementRef;

  constructor() {}

  ngOnInit() {}
}
