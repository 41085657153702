import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CreateSite, CreateSiteFail, CreateSiteSuccess } from '../../state/sites.actions';

@Component({
  selector: 'tsa-site-create',
  templateUrl: './site-create.component.html',
  styleUrls: ['./site-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteCreateComponent implements OnInit, OnDestroy {
  domain = environment.domain;
  destroyed$ = new Subject();

  loading$ = this.actions$.pipe(
    ofActionDispatched(CreateSite, CreateSiteFail, CreateSiteSuccess),
    map(rs => rs instanceof CreateSite)
  );

  error$ = this.actions$.pipe(
    ofActionDispatched(CreateSiteFail),
    map(e => e.payload)
  );

  form = new FormGroup({
    name: new FormControl('', [Validators.maxLength(65), Validators.minLength(5), Validators.required]),
    lang: new FormControl('ru', Validators.required),
    subDomain: new FormControl('', [
      Validators.minLength(5),
      Validators.maxLength(20),
      Validators.pattern(/^[a-z\d][a-z_\-\d]+$/),
      Validators.required,
    ]),
  });

  constructor(private store: Store, private actions$: Actions, private router: Router) {}

  get nameCtrl() {
    return this.form.get('name');
  }

  get subDomainCtrl() {
    return this.form.get('subDomain');
  }

  ngOnInit() {
    // remove trailing whitespaces
    this.subDomainCtrl.valueChanges.pipe<string>(takeUntil(this.destroyed$)).subscribe(value => {
      if (value !== value.trim()) {
        this.subDomainCtrl.patchValue(value.trim());
      }
    });
    // redirect after success create
    this.actions$
      .pipe(
        takeUntil(this.destroyed$),
        ofActionDispatched(CreateSiteSuccess),
        tap(rs => this.router.navigate(['sites']))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  save() {
    this.store.dispatch(new CreateSite(this.form.value));
  }
}
