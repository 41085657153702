import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserState } from './state/user.state';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private state: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.state.selectSnapshot(UserState.getToken);
    if (!token) {
      return next.handle(req);
    }
    // Clone the request to add the new header.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });

    // Pass on the cloned request instead of the original request.
    return next.handle(authReq);
  }
}
