import * as i0 from "@angular/core";
export class NgxFcbkService {
    constructor() { }
    loadScript() {
        // return Observable.create((observer: Observer<any>) => {
        this._startScriptLoad();
        // });
    }
    _startScriptLoad() {
        if (document.getElementById('fcbk-wjs')) {
            this.initScript();
            return;
        }
        // console.log('LOAD');
        const firstJSScript = document.getElementsByTagName('script')[0];
        const js = document.createElement('script');
        js.id = 'fcbk-wjs';
        js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
        firstJSScript.parentNode.insertBefore(js, firstJSScript);
        this.initScript();
    }
    initScript() {
        // console.log('INIT');
        if (window['FB']) {
            window['FB'].init({
                xfbml: true,
                version: 'v3.2',
            });
        }
    }
}
NgxFcbkService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NgxFcbkService_Factory() { return new NgxFcbkService(); }, token: NgxFcbkService, providedIn: "root" });
