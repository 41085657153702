import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SiteListItemResponse } from '../../../api';
import { UserState } from '../../../auth/state/user.state';
import { LoadSites } from '../../state/sites.actions';
import { SitesState, SitesStateModel } from '../../state/sites.state';

@Component({
  selector: 'tsa-sites-list',
  templateUrl: './sites-list.component.html',
  styleUrls: ['./sites-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SitesListComponent implements OnInit {
  domain = environment.domain;

  rolesMap = {
    trainee: 'Стажер',
    editor: 'Редактор',
    main_editor: 'Главный редактор',
    maintainer: 'Управляющий',
    owner: 'Создатель',
  };

  sites$: Observable<SitesStateModel> = this.store.select(SitesState);

  user = this.store.selectSnapshot(UserState);

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(new LoadSites());
  }

  role(site: SiteListItemResponse) {
    let part = site.participants.find(p => p.user === this.user.id);
    return this.rolesMap[part.role];
  }
}
