import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import localeRu from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { environment } from '../../environments/environment';
import { ApiModule, Configuration } from '../api';
import { AuthInterceptor } from '../auth/auth.interceptor';
import { AuthModule } from '../auth/auth.module';
import { Error401Interceptor } from '../auth/error-401.interceptor';
import { UserState } from '../auth/state/user.state';
import { CurrentSiteState } from '../site/state/current-site.state';
import { PostEditorState } from '../site/state/post-editor.state';
import { SitesState } from '../sites/state/sites.state';
import { AppState } from './state/app.state';

registerLocaleData(localeRu);

export function apiFactory() {
  return new Configuration({
    basePath: environment.apiPath,
    apiKeys: {},
  });
}

@NgModule({
  declarations: [],
  imports: [
    AuthModule,
    CommonModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    ApiModule.forRoot(apiFactory),
    NgxsModule.forRoot([AppState, UserState, SitesState, CurrentSiteState, PostEditorState], {
      developmentMode: !environment.production,
      executionStrategy: NoopNgxsExecutionStrategy,
    }),
    NgxsLoggerPluginModule.forRoot({
      logger: console,
      disabled: environment.production,
      collapsed: false,
    }),
    NgxsStoragePluginModule.forRoot({
      key: 'user',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Error401Interceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'ru' },
  ],
  exports: [AuthModule, LoadingBarHttpClientModule],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(`CoreModule has already been loaded. Import Core modules in the AppModule only.`);
    }
  }
}
