import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions } from '@ngxs/store';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  destroy$ = new Subject();

  constructor(private actions$: Actions) {}

  public ngOnInit() {
    this.ravenLogActions();
  }

  ravenLogActions() {
    this.actions$
      .pipe(
        takeUntil(this.destroy$),
        filter(rs => rs.status === 'DISPATCHED')
      )
      .subscribe(rs => {
        Sentry.addBreadcrumb({
          message: rs.action.constructor.type,
          category: 'ngxs',
          level: Severity.Info,
          data: rs.action.payload,
        });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
