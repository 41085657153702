import { Action, Selector, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SiteGetByIdResponse, SiteSectionsResponse, SitesService } from '../../api';
import {
  LoadSections,
  LoadSectionsSuccess,
  SelectSite,
  SelectSiteSuccess,
  UpdateConfig,
  UpdateConfigFail,
  UpdateConfigSuccess,
} from './current-site.actions';

export class CurrentSiteStateModel {
  id?: string;
  data: SiteGetByIdResponse;
  sections?: SiteSectionsResponse;
}

@State<CurrentSiteStateModel>({
  name: 'currentSite',
  defaults: {
    data: null,
  },
})
export class CurrentSiteState {
  @Selector()
  static sections(state: CurrentSiteStateModel) {
    return state.sections;
  }

  @Selector()
  static sectionsFlatten(state: CurrentSiteStateModel) {
    return state.sections.items.reduce((acc, curr) => acc.concat(curr, ...curr.subSections), []);
  }

  @Selector()
  static data(state: CurrentSiteStateModel) {
    return state.data;
  }

  @Selector()
  static origin(state: CurrentSiteStateModel) {
    return state.data.domain ? state.data.domain : state.data.subDomain + '.' + environment.domain;
  }

  @Selector()
  static id(state: CurrentSiteStateModel) {
    return state.id;
  }

  constructor(private api: SitesService) {}

  @Action(SelectSite)
  load(ctx: StateContext<CurrentSiteStateModel>, action: SelectSite) {
    return this.api.getById(action.payload).pipe(tap(data => ctx.dispatch(new SelectSiteSuccess(data))));
  }

  @Action(SelectSiteSuccess)
  loadSuccess({ setState }: StateContext<CurrentSiteStateModel>, { payload }: SelectSiteSuccess) {
    setState({ data: payload, id: payload.id });
  }

  @Action(UpdateConfig)
  updateConfig({ dispatch, getState }: StateContext<CurrentSiteStateModel>, { payload }: UpdateConfig) {
    let state = getState();
    return this.api.update(payload, state.id).pipe(
      tap(site => dispatch(new UpdateConfigSuccess(site))),
      catchError(err => {
        dispatch(new UpdateConfigFail(err));
        return throwError(err);
      })
    );
  }

  @Action(UpdateConfigSuccess)
  updateConfigSuccess(ctx: StateContext<CurrentSiteStateModel>, { payload }: UpdateConfigSuccess) {
    ctx.patchState({ data: payload });
  }

  @Action(LoadSections)
  loadSections({ dispatch, getState }: StateContext<CurrentSiteStateModel>) {
    let state = getState();
    return this.api.getSections(state.id).pipe(tap(rs => dispatch(new LoadSectionsSuccess(rs))));
  }

  @Action(LoadSectionsSuccess)
  loadSectionsSuccess(ctx: StateContext<CurrentSiteStateModel>, { payload }: LoadSectionsSuccess) {
    ctx.patchState({ sections: payload });
  }
}
