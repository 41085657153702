import Quill from 'quill';
import { AttachItemResponse } from '../../../../../api';
import { CachedAttach } from './cached-attach';

export class TwitterEmbed extends CachedAttach {
  static blotName = 'attachTwitter';
  static tagName = 'figure';

  static render(node: HTMLElement, value: AttachItemResponse) {
    super.render(node, value);

    // console.log('render value', value)

    let extra = JSON.parse(value.embedExtra);
    node.innerHTML = `
    <div class="twit-holder frame">${extra.html}</div>
    <figcaption>
        <div class="caption">${value.caption || ''}</div>
        <div class="copyright">${value.copyright || ''}</div>
    </figcaption>`;

    if ((<any>window).twttr.widgets && (<any>window).twttr.widgets.createTweet) {
      let bq = node.querySelector('.twit-holder');
      bq.innerHTML = '';
      (<any>window).twttr.widgets.createTweet(value.embedId.split('/').pop(), bq);
    }

    return node;
  }
}

Quill.register(TwitterEmbed);
