import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
export class NgxInstService {
    constructor() {
        this.INSTAGRAM_OBJECT = 'instgrm';
        this.INSTAGRAM_SCRIPT_ID = 'instgrm-wjs';
        this.INSTAGRAM_WIDGET_URL = 'https://instagram.com/embed.js';
    }
    loadScript() {
        return Observable.create((observer) => {
            this._startScriptLoad();
            // window[ this.INSTAGRAM_OBJECT ].ready(this._onTwitterScriptLoadedFactory(observer));
        });
    }
    reloadInstagramScript() {
        if (window[this.INSTAGRAM_OBJECT]) {
            window[this.INSTAGRAM_OBJECT].Embeds.process();
        }
    }
    _startScriptLoad() {
        const instagramData = window[this.INSTAGRAM_OBJECT] || {};
        if (this._twitterScriptAlreadyExists()) {
            // window[ this.INSTAGRAM_OBJECT ]['Embeds'] = instagramData;
            // window[this.INSTAGRAM_OBJECT].Embeds.process();
            return;
        }
        this._appendTwitterScriptToDOM();
        instagramData._e = [];
        instagramData.ready = (callback) => {
            instagramData._e.push(callback);
        };
    }
    _twitterScriptAlreadyExists() {
        const instagramScript = document.getElementById(this.INSTAGRAM_SCRIPT_ID);
        return instagramScript !== null || typeof instagramScript !== 'object';
    }
    _appendTwitterScriptToDOM() {
        const firstJSScript = document.getElementsByTagName('script')[0];
        const js = document.createElement('script');
        js.id = this.INSTAGRAM_SCRIPT_ID;
        js.src = this.INSTAGRAM_WIDGET_URL;
        firstJSScript.parentNode.insertBefore(js, firstJSScript);
    }
    _onTwitterScriptLoadedFactory(observer) {
        return (instagramData) => {
            observer.next(instagramData);
            observer.complete();
        };
    }
}
NgxInstService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NgxInstService_Factory() { return new NgxInstService(); }, token: NgxInstService, providedIn: "root" });
