/**
 * Telesite
 * Platform for fast start your site.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UpdateSiteDto { 
    name?: string;
    titleTemplate?: string;
    lang?: UpdateSiteDto.LangEnum;
    googleAnalitics?: string;
    yandexMetrica?: number;
    logoSvg?: string;
    showRecentNewsByTags?: boolean;
    recentNewsByTagsCount?: number;
    publicationTagsPosition?: any;
    pageTitle?: string;
    pageH1?: string;
    pageDescription?: string;
    nightModeButton?: boolean;
}
export namespace UpdateSiteDto {
    export type LangEnum = 'ru' | 'en';
    export const LangEnum = {
        Ru: 'ru' as LangEnum,
        En: 'en' as LangEnum
    };
}
