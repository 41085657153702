/**
 * Telesite
 * Platform for fast start your site.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PhotoInfo } from './photoInfo';


export interface AttachItemResponse { 
    id: string;
    kind: AttachItemResponse.KindEnum;
    photo?: PhotoInfo;
    embedId?: string;
    embedExtra?: string;
    publicUrl: string;
    caption?: string;
    copyright?: string;
    width: AttachItemResponse.WidthEnum;
}
export namespace AttachItemResponse {
    export type KindEnum = 'photo' | 'youtube' | 'vimeo' | 'twitter' | 'facebook' | 'instagram' | 'teleplay';
    export const KindEnum = {
        Photo: 'photo' as KindEnum,
        Youtube: 'youtube' as KindEnum,
        Vimeo: 'vimeo' as KindEnum,
        Twitter: 'twitter' as KindEnum,
        Facebook: 'facebook' as KindEnum,
        Instagram: 'instagram' as KindEnum,
        Teleplay: 'teleplay' as KindEnum
    };
    export type WidthEnum = 'standard' | 'wide' | 'super_wide';
    export const WidthEnum = {
        Standard: 'standard' as WidthEnum,
        Wide: 'wide' as WidthEnum,
        SuperWide: 'super_wide' as WidthEnum
    };
}
