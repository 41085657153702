<div [formGroup]="form" class="wrap">
  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>Title</mat-label>
      <input matInput #title formControlName="pageTitle" />
      <span class="mat-secondary" matSuffix>{{ title.value?.length || 0 }}/200</span>
      <mat-hint>&lt;title&gt; - Рекомендовано: 35-65 символов</mat-hint>
    </mat-form-field>
  </div>

  <div class="form-row">
    <mat-form-field floatLabel="never">
      <textarea
        placeholder="Description"
        class="textarea-input"
        rows="4"
        formControlName="pageDescription"
        maxlength="400"
        matInput
        #description
      ></textarea>
      <mat-hint align="end">
        <span class="mat-secondary">{{ description.value?.length || 0 }}/400</span>
      </mat-hint>
      <mat-hint>
        &lt;description&gt; - Рекомендовано: 70-320 символов
      </mat-hint>
    </mat-form-field>
  </div>
  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>url</mat-label>
      <input matInput #slug formControlName="publicationSlug" />
      <span class="mat-secondary" matSuffix>{{ slug.value?.length || 0 }}/200</span>
      <mat-hint>разрешено: латинские букы цифры и "-"</mat-hint>
    </mat-form-field>
  </div>
  <div align="end">
    <button mat-flat-button color="primary" (click)="nextTab.next()">Дальше</button>
  </div>
</div>
