<mat-toolbar>
  <div>Создание поста</div>
  <!--<mat-tab-group>-->
  <!--&lt;!&ndash;TODO: redo&ndash;&gt;-->
  <!--<mat-tab label="Редактор"></mat-tab>-->
  <!--<mat-tab label="Превью"></mat-tab>-->
  <!--<mat-tab label="SEO"></mat-tab>-->
  <!--</mat-tab-group>-->
  <span style="flex: 1 1 auto;"></span>
  <div *ngIf="form.valid">
    <a
      href="http://{{ origin }}/p/draft-{{ post.id }}-{{ post.uuid || 'null' }}"
      style="font-size: 13px;margin-right: 16px"
      target="_blank"
    >
      на сайте
    </a>
  </div>
  <div style="font-size: 13px; margin-right: 16px">
    <span *ngIf="post.state === 'draft'">
      Черновик
    </span>
    <span *ngIf="post.state === 'queue'">
      Запланирован
    </span>
    <span *ngIf="post.state === 'published'">
      <a
        href="http://{{ origin }}/p/{{ post.publication.slug }}"
        style="font-size: 13px;margin-right: 16px"
        target="_blank"
      >
        Опубликован
      </a>
    </span>
  </div>
  <div *ngIf="post.state === 'draft'">
    <button
      mat-button
      color="primary"
      (click)="scheduleDialogOpen()"
      [disabled]="form.invalid || publishing"
      style="margin-right: 16px"
    >
      Запланировать
    </button>
    <button mat-flat-button color="primary" (click)="publish()" [disabled]="form.invalid || publishing">
      Опубликовать
    </button>
  </div>
  <div *ngIf="post.state === 'queue'">
    <button
      mat-button
      color="primary"
      (click)="scheduleDialogOpen()"
      [disabled]="form.invalid || publishing"
      style="margin-right: 16px"
    >
      {{ post.schedulePublishAt | date: 'medium' }}
    </button>
    <button mat-flat-button color="primary" (click)="publish()" [disabled]="form.invalid || publishing">
      Опубликовать сейчас
    </button>
  </div>
  <div *ngIf="post.state === 'published'">
    <button
      mat-flat-button
      color=""
      [disabled]="form.invalid || publishing"
      style="margin-right: 16px"
      (click)="deletePublication()"
    >
      Снести
    </button>
    <button mat-flat-button color="primary" [disabled]="form.invalid || publishing" (click)="publish()">
      Обновить
    </button>
  </div>
</mat-toolbar>
<tsa-publication-form [form]="form"></tsa-publication-form>

<ng-template #scheduleDialog>
  <h1 mat-dialog-title>Запланировать публикацию</h1>
  <mat-dialog-content [formGroup]="_scheduleForm">
    <p>
      <mat-form-field>
        <input
          matInput
          [min]="minQueueDate"
          [max]="maxQueueDate"
          [matDatepicker]="dp3"
          placeholder="Дата публикации"
          readonly
          formControlName="date"
        />
        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
      </mat-form-field>
      <mat-form-field style="width: 100px; margin-left: 24px">
        <mat-select placeholder="Часы" formControlName="hour">
          <mat-option [value]="h" *ngFor="let h of hours">{{ h < 10 ? '0' + h : h }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="width: 100px; margin-left: 24px">
        <mat-select placeholder="Минуты" formControlName="minutes">
          <mat-option [value]="h" *ngFor="let h of minutes">{{ h < 10 ? '0' + h : h }}</mat-option>
        </mat-select>
      </mat-form-field>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close="">Отмена</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="_scheduleForm.invalid || publishing"
      (click)="schedule()"
    >
      Запланировать
    </button>
  </mat-dialog-actions>
</ng-template>
