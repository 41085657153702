/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./link-preview.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./link-preview.component";
import * as i3 from "../../../drag.service";
var styles_LinkPreviewComponent = [i0.styles];
var RenderType_LinkPreviewComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LinkPreviewComponent, data: {} });
export { RenderType_LinkPreviewComponent as RenderType_LinkPreviewComponent };
export function View_LinkPreviewComponent_0(_l) { return i1.ɵvid(2, [], null, null); }
export function View_LinkPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tsa-link-preview", [], null, null, null, View_LinkPreviewComponent_0, RenderType_LinkPreviewComponent)), i1.ɵdid(1, 245760, null, 0, i2.LinkPreviewComponent, [i3.DragService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LinkPreviewComponentNgFactory = i1.ɵccf("tsa-link-preview", i2.LinkPreviewComponent, View_LinkPreviewComponent_Host_0, { editor: "editor" }, { edit: "edit", remove: "remove" }, []);
export { LinkPreviewComponentNgFactory as LinkPreviewComponentNgFactory };
