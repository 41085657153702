import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { MainPhotoItemResponse, PostItemResponse, PostsService } from '../../../../api';
import { CurrentSiteState } from '../../../state/current-site.state';
import {
  AddAttach,
  LoadPostAttaches,
  SetMainPhoto,
  UpdateMainPhotoMeta,
  UploadAttaches,
} from '../../../state/post-editor.actions';
import { PostEditorState } from '../../../state/post-editor.state';
import { DragService } from '../../drag.service';
import { CreateEmbedComponent } from '../create-embed/create-embed.component';

@Component({
  selector: 'tsa-publication-form',
  templateUrl: './publication-form.component.html',
  styleUrls: ['./publication-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationFormComponent implements OnInit, OnDestroy {
  sections = this.store.selectSnapshot(CurrentSiteState.sections);
  siteId = this.store.selectSnapshot(CurrentSiteState.id);
  postId = this.store.selectSnapshot(PostEditorState.postId);
  post = this.store.selectSnapshot(PostEditorState.post);
  attaches$ = this.store.select(PostEditorState.attaches);

  uploading = false;
  mainImage: MainPhotoItemResponse = this.post.mainImage;

  @Input() form: FormGroup;

  mainPhotoForm = new FormGroup({
    caption: new FormControl(this.mainImage ? this.mainImage.caption : ''),
    copyright: new FormControl(this.mainImage ? this.mainImage.copyright : ''),
    position: new FormControl(this.mainImage ? this.mainImage.position : 'inside'),
  });

  destroy$ = new Subject();

  hideAttaches = false;

  constructor(
    private store: Store,
    private posts: PostsService,
    private cd: ChangeDetectorRef,
    public ds: DragService,
    private dialog: MatDialog
  ) {}

  trackByFn(index, item) {
    if (item.id) {
      return item.id;
    }
    return item.file;
  }

  get vvv() {
    return JSON.stringify(JSON.parse(this.form.value.textOps), null, 2);
  }

  addEmbedDialog() {
    this.dialog
      .open(CreateEmbedComponent)
      .afterClosed()
      .subscribe(rs => {
        if (rs) {
          this.store.dispatch(new AddAttach(rs));
        }
      });
  }

  ngOnInit() {
    this.mainPhotoForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        filter(() => !!this.mainImage)
      )
      .subscribe(rs => this.store.dispatch(new UpdateMainPhotoMeta(rs)));

    this.store
      .select(PostEditorState.post)
      .pipe(
        takeUntil(this.destroy$),
        filter(x => !!x.mainImage),
        distinctUntilKeyChanged('mainImage', (x, y) => x.id === y.id)
      )
      .subscribe((rs: PostItemResponse) => {
        // main image is changed, need set old caption and copyright
        if (this.mainImage) {
          this.store.dispatch(new UpdateMainPhotoMeta(this.mainPhotoForm.value));
        }
        this.mainImage = rs.mainImage;
        this.cd.detectChanges();
      });
  }

  uploadMainPhoto(files: FileList) {
    this.uploading = true;
    this.posts
      .uploadMainPhoto(files[0], this.postId, this.siteId)
      .pipe(
        finalize(() => {
          this.uploading = false;
          this.cd.detectChanges();
        })
      )
      .subscribe(rs => {
        this.store.dispatch([new SetMainPhoto(rs), new LoadPostAttaches(this.postId)]);
      });
  }

  changeMainImage(files: FileList) {
    if (files.length === 0) {
      return;
    }
    this.uploadMainPhoto(files);
  }

  uploadAttaches(files: FileList) {
    if (files.length === 0) {
      return;
    }
    this.store.dispatch(new UploadAttaches(files));
  }

  toggleBlockMode() {
    this.ds.blockMode.next(!this.ds.blockMode.value);
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
