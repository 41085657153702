/**
 * Telesite
 * Platform for fast start your site.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ParticipantOnlyIdsResponse { 
    user: string;
    role: ParticipantOnlyIdsResponse.RoleEnum;
    creator: string;
    createdAt: string;
}
export namespace ParticipantOnlyIdsResponse {
    export type RoleEnum = 'trainee' | 'editor' | 'main_editor' | 'maintainer' | 'owner';
    export const RoleEnum = {
        Trainee: 'trainee' as RoleEnum,
        Editor: 'editor' as RoleEnum,
        MainEditor: 'main_editor' as RoleEnum,
        Maintainer: 'maintainer' as RoleEnum,
        Owner: 'owner' as RoleEnum
    };
}
