import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CreateDraftPost } from '../../state/post-editor.actions';
import { PostEditorState } from '../../state/post-editor.state';

@Component({
  selector: 'tsa-create-draft-publication',
  templateUrl: './create-draft-publication.component.html',
  styleUrls: ['./create-draft-publication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDraftPublicationComponent implements OnInit {
  constructor(private store: Store, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.store.dispatch(new CreateDraftPost()).subscribe(rs => {
      let postId = this.store.selectSnapshot(PostEditorState.postId);
      this.router.navigate(['..', 'editor-v2', postId], { relativeTo: this.route });
    });
  }
}
