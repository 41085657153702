/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./content-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./content-editor.component";
import * as i3 from "@ngxs/store";
var styles_ContentEditorComponent = [i0.styles];
var RenderType_ContentEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentEditorComponent, data: {} });
export { RenderType_ContentEditorComponent as RenderType_ContentEditorComponent };
export function View_ContentEditorComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["id", "editor-el"], ["tabindex", "-1"]], null, null, null, null, null))], null, null); }
export function View_ContentEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tsa-content-editor", [], null, [["document", "keyup"]], function (_v, en, $event) { var ad = true; if (("document:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ContentEditorComponent_0, RenderType_ContentEditorComponent)), i1.ɵdid(1, 4440064, null, 0, i2.ContentEditorComponent, [i3.Store, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContentEditorComponentNgFactory = i1.ɵccf("tsa-content-editor", i2.ContentEditorComponent, View_ContentEditorComponent_Host_0, { control: "control", placeholder: "placeholder", enableImages: "enableImages", imageToolEndpoints: "imageToolEndpoints" }, {}, []);
export { ContentEditorComponentNgFactory as ContentEditorComponentNgFactory };
