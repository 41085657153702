import * as tslib_1 from "tslib";
import { HttpEventType } from '@angular/common/http';
import { Action, Selector, State, Store } from '@ngxs/store';
import { forkJoin, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AttachService, PostsService } from '../../api';
import { CurrentSiteState } from './current-site.state';
import { AddAttach, AddEmbedAttach, AttachDeleted, CreateDraftPost, DeleteAttach, LoadPost, LoadPostAttaches, SetMainPhoto, SetPost, UpdateAttachMeta, UpdateMainPhotoMeta, UpdatePost, UpdatePostError, UploadAttaches, UploadMainPhoto, } from './post-editor.actions';
let PostEditorState = class PostEditorState {
    constructor(store, posts, attaches) {
        this.store = store;
        this.posts = posts;
        this.attaches = attaches;
    }
    static getState(state) {
        return state;
    }
    static postId(state) {
        return state.postId;
    }
    static post(state) {
        return state.post;
    }
    static attaches(state) {
        return state.attaches;
    }
    createDraft({ dispatch, getState, setState }) {
        let siteId = this.store.selectSnapshot(CurrentSiteState.id);
        return this.posts
            .createDraft(siteId)
            .pipe(tap(rs => setState({ postId: rs.id, post: rs, attaches: [] })));
    }
    loadPost({ dispatch, getState, setState, patchState }, { payload }) {
        let siteId = this.store.selectSnapshot(CurrentSiteState.id);
        return this.posts.getById(payload, siteId).pipe(tap(rs => patchState({ postId: rs.id, post: rs })));
    }
    loadPostAttaches({ dispatch, getState, setState }, { payload }) {
        let siteId = this.store.selectSnapshot(CurrentSiteState.id);
        return this.attaches
            .getAll(payload, siteId)
            .pipe(tap(rs => setState(Object.assign({}, getState(), { attaches: rs.items }))));
    }
    updatePost({ dispatch, getState, setState, patchState }, { payload }) {
        let siteId = this.store.selectSnapshot(CurrentSiteState.id);
        let postId = getState().postId;
        return this.posts.update(payload, postId, siteId).pipe(catchError(err => {
            dispatch(new UpdatePostError(err));
            return throwError(err);
        }), tap(rs => dispatch(new SetPost(rs))));
    }
    setPost({ dispatch, getState, setState, patchState }, { payload }) {
        patchState({ post: payload, postId: payload.id });
    }
    uploadMainPhoto({ dispatch, getState, setState, patchState }, { payload }) {
        let siteId = this.store.selectSnapshot(CurrentSiteState.id);
        let postId = getState().postId;
        return this.posts.uploadMainPhoto(payload, postId, siteId).pipe(tap(rs => {
            dispatch([new SetMainPhoto(rs), new LoadPostAttaches(postId)]);
        }));
    }
    setMainPhoto({ dispatch, getState, setState, patchState }, { payload }) {
        patchState({ post: Object.assign({}, getState().post, { mainImage: payload }) });
    }
    updatePostMainPhoto({ dispatch, getState, setState, patchState }, { payload }) {
        let siteId = this.store.selectSnapshot(CurrentSiteState.id);
        let postId = getState().postId;
        return this.posts
            .updateMainPhotoMeta(payload, postId, siteId)
            .pipe(tap(rs => patchState({ post: Object.assign({}, getState().post, { mainImage: rs }) })));
    }
    uploadAttaches({ dispatch, getState, setState, patchState }, { payload }) {
        let siteId = this.store.selectSnapshot(CurrentSiteState.id);
        let postId = getState().postId;
        let requests = [];
        for (let i = 0; i < payload.length; i++) {
            let file = payload.item(i);
            let uploadingAttach = { kind: 'photo', uploading: true, progress: 0, file: file };
            patchState({ attaches: [...getState().attaches, uploadingAttach] });
            requests.push(this.attaches.createPhoto(file, postId, siteId, 'events', true).pipe(tap(rs => {
                if (rs.type === HttpEventType.UploadProgress) {
                    let state = getState();
                    let progress = +((rs.loaded / rs.total) * 100).toFixed(2);
                    let attachIndex = state.attaches.findIndex(i => i['file'] === file);
                    let newArr = Array.from(state.attaches);
                    newArr[attachIndex] = Object.assign({}, uploadingAttach, { progress });
                    patchState({ attaches: newArr });
                }
                else if (rs.type === HttpEventType.Response) {
                    let state = getState();
                    let attaches = state.attaches.filter(i => i['file'] !== file);
                    patchState({ attaches: [...attaches, rs.body] });
                }
            })));
        }
        return forkJoin(requests);
    }
    addEmbedAttach({ dispatch, getState, setState, patchState }, { payload }) {
        let siteId = this.store.selectSnapshot(CurrentSiteState.id);
        let postId = getState().postId;
        return this.attaches.createEmbed(payload, postId, siteId).pipe(tap(rs => dispatch(new AddAttach(rs))));
    }
    addAttach({ dispatch, getState, setState, patchState }, { payload }) {
        patchState({ attaches: [...getState().attaches, payload] });
    }
    deleteAttach({ dispatch, getState, setState, patchState }, { payload }) {
        let siteId = this.store.selectSnapshot(CurrentSiteState.id);
        let postId = getState().postId;
        let oldAttaches = getState().attaches;
        let attaches = getState().attaches.filter(i => i['id'] !== payload);
        let attach = getState().attaches.find(i => i['id'] === payload);
        patchState({ attaches: attaches });
        return this.attaches._delete(payload, postId, siteId).pipe(tap(rs => dispatch(new AttachDeleted(attach))), catchError(err => {
            patchState({ attaches: oldAttaches });
            return throwError(err);
        }));
    }
    updateAttachMeta({ dispatch, getState, setState, patchState }, { payload, id }) {
        let siteId = this.store.selectSnapshot(CurrentSiteState.id);
        let postId = getState().postId;
        let oldAttaches = Array.from(getState().attaches);
        let newAttaches = [];
        for (let attach of oldAttaches) {
            if (attach['id'] === id) {
                newAttaches.push(Object.assign({}, attach, payload));
            }
            else {
                newAttaches.push(attach);
            }
        }
        patchState({ attaches: newAttaches });
        return this.attaches.updateMeta(id, payload, postId, siteId).pipe(catchError(err => {
            patchState({ attaches: oldAttaches });
            return throwError(err);
        }));
    }
};
tslib_1.__decorate([
    Action(CreateDraftPost),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState.prototype, "createDraft", null);
tslib_1.__decorate([
    Action(LoadPost),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, LoadPost]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState.prototype, "loadPost", null);
tslib_1.__decorate([
    Action(LoadPostAttaches),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, LoadPostAttaches]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState.prototype, "loadPostAttaches", null);
tslib_1.__decorate([
    Action(UpdatePost, { cancelUncompleted: true }),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdatePost]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState.prototype, "updatePost", null);
tslib_1.__decorate([
    Action(SetPost),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetPost]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState.prototype, "setPost", null);
tslib_1.__decorate([
    Action(UploadMainPhoto, { cancelUncompleted: true }),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UploadMainPhoto]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState.prototype, "uploadMainPhoto", null);
tslib_1.__decorate([
    Action(SetMainPhoto),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetMainPhoto]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState.prototype, "setMainPhoto", null);
tslib_1.__decorate([
    Action(UpdateMainPhotoMeta, { cancelUncompleted: true }),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateMainPhotoMeta]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState.prototype, "updatePostMainPhoto", null);
tslib_1.__decorate([
    Action(UploadAttaches),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UploadAttaches]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState.prototype, "uploadAttaches", null);
tslib_1.__decorate([
    Action(AddEmbedAttach),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AddEmbedAttach]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState.prototype, "addEmbedAttach", null);
tslib_1.__decorate([
    Action(AddAttach),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AddAttach]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState.prototype, "addAttach", null);
tslib_1.__decorate([
    Action(DeleteAttach),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DeleteAttach]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState.prototype, "deleteAttach", null);
tslib_1.__decorate([
    Action(UpdateAttachMeta),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateAttachMeta]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState.prototype, "updateAttachMeta", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState, "getState", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState, "postId", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState, "post", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PostEditorState, "attaches", null);
PostEditorState = tslib_1.__decorate([
    State({
        name: 'publicationEditor',
        defaults: {
            postId: null,
            post: null,
            attaches: [],
        },
    }),
    tslib_1.__metadata("design:paramtypes", [Store, PostsService, AttachService])
], PostEditorState);
export { PostEditorState };
