import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'tsa-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent implements OnInit {
  @Input()
  accept = 'image/*';

  @Output()
  select = new Subject<FileList>();

  constructor() {}

  ngOnInit() {}

  selectFiles(files: FileList) {
    if (files.length > 0) {
      this.select.next(files);
    }
  }
}
