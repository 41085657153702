import * as tslib_1 from "tslib";
import { Action, State } from '@ngxs/store';
import { AppAction } from './app.actions';
export class AppStateModel {
}
let AppState = class AppState {
    add(ctx, action) {
        const state = ctx.getState();
        ctx.setState({ items: [...state.items, action.payload] });
    }
};
tslib_1.__decorate([
    Action(AppAction),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AppAction]),
    tslib_1.__metadata("design:returntype", void 0)
], AppState.prototype, "add", null);
AppState = tslib_1.__decorate([
    State({
        name: 'app',
        defaults: {
            items: [],
        },
    })
], AppState);
export { AppState };
