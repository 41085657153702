/**
 * Telesite
 * Platform for fast start your site.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ParticipantResponse } from './participantResponse';
import { UserItem } from './userItem';


export interface SiteGetByIdResponse { 
    id: string;
    name: string;
    titleTemplate: string;
    subDomain: string;
    domain?: string;
    lang: string;
    logoUrl?: string;
    faviconUrl?: string;
    googleAnalitics?: string;
    yandexMetrica?: number;
    logoSvg?: string;
    showRecentNewsByTags: boolean;
    recentNewsByTagsCount: number;
    publicationTagsPosition: SiteGetByIdResponse.PublicationTagsPositionEnum;
    origin: string;
    pageTitle?: string;
    pageH1?: string;
    pageDescription?: string;
    optimizedTextEditorJs?: string;
    optimizedTextPosition?: SiteGetByIdResponse.OptimizedTextPositionEnum;
    nightModeButton: boolean;
    owner: UserItem;
    participants?: Array<ParticipantResponse>;
    siteRole: SiteGetByIdResponse.SiteRoleEnum;
}
export namespace SiteGetByIdResponse {
    export type PublicationTagsPositionEnum = 'top' | 'bottom';
    export const PublicationTagsPositionEnum = {
        Top: 'top' as PublicationTagsPositionEnum,
        Bottom: 'bottom' as PublicationTagsPositionEnum
    };
    export type OptimizedTextPositionEnum = 'top' | 'bottom';
    export const OptimizedTextPositionEnum = {
        Top: 'top' as OptimizedTextPositionEnum,
        Bottom: 'bottom' as OptimizedTextPositionEnum
    };
    export type SiteRoleEnum = 'trainee' | 'editor' | 'main_editor' | 'maintainer' | 'owner';
    export const SiteRoleEnum = {
        Trainee: 'trainee' as SiteRoleEnum,
        Editor: 'editor' as SiteRoleEnum,
        MainEditor: 'main_editor' as SiteRoleEnum,
        Maintainer: 'maintainer' as SiteRoleEnum,
        Owner: 'owner' as SiteRoleEnum
    };
}
