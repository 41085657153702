import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { PostsService } from '../../../api';
import { CurrentSiteState } from '../../state/current-site.state';

@Component({
  selector: 'tsa-queue-posts',
  templateUrl: './queue-posts.component.html',
  styleUrls: ['./queue-posts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueuePostsComponent implements OnInit {
  siteId = this.store.selectSnapshot(CurrentSiteState.id);
  posts$ = this.postsService.getAll(this.siteId, 'queue');

  constructor(private postsService: PostsService, private store: Store, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  remove(id) {
    if (confirm('Удалить черновик?')) {
      this.postsService.remove(id, this.siteId).subscribe(() => {
        this.posts$ = this.postsService.getAll(this.siteId, 'queue');
        this.cd.detectChanges();
      });
    }
  }

  unQueue(id) {
    if (confirm('Отменить публикацию?')) {
      this.postsService.unQueue(id, this.siteId).subscribe(() => {
        this.posts$ = this.postsService.getAll(this.siteId, 'queue');
        this.cd.detectChanges();
      });
    }
  }
}
