import { BehaviorSubject, Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class DragService {
    constructor() {
        this.blockMode = new BehaviorSubject(false);
        this.dragAttach = new Subject();
        this.attachChanges = new Subject();
        this.linkForPreview = new Subject();
    }
}
DragService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DragService_Factory() { return new DragService(); }, token: DragService, providedIn: "root" });
