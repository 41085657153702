/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inline-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./inline-loader.component";
var styles_InlineLoaderComponent = [i0.styles];
var RenderType_InlineLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InlineLoaderComponent, data: {} });
export { RenderType_InlineLoaderComponent as RenderType_InlineLoaderComponent };
export function View_InlineLoaderComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], strokeWidth: [1, "strokeWidth"] }, null)], function (_ck, _v) { var currVal_3 = 40; var currVal_4 = 4; _ck(_v, 2, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_InlineLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tsa-inline-loader", [], null, null, null, View_InlineLoaderComponent_0, RenderType_InlineLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i7.InlineLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InlineLoaderComponentNgFactory = i1.ɵccf("tsa-inline-loader", i7.InlineLoaderComponent, View_InlineLoaderComponent_Host_0, {}, {}, []);
export { InlineLoaderComponentNgFactory as InlineLoaderComponentNgFactory };
