import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { AttachItemResponse } from '../../../../api';
import { capitalize } from '../../../../lib/utils';
import { NgxFcbkService } from '../../../../shared/ngx-fcbk.service';
import { NgxInstService } from '../../../../shared/ngx-inst.service';
import { NgxTweetService } from '../../../../shared/ngx-tweet.service';
import { DeleteAttach, UpdateAttachMeta } from '../../../state/post-editor.actions';
import { PostEditorState } from '../../../state/post-editor.state';
import { DragService } from '../../drag.service';

type width = 'standard' | 'wide' | 'super_wide';

@Component({
  selector: 'tsa-attach-item',
  templateUrl: './attach-item.component.html',
  styleUrls: ['./attach-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachItemComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() attach: AttachItemResponse | any;

  form: FormGroup;

  destroy$ = new Subject();
  trustedUrl;

  attached$ = this.store.select(PostEditorState.post).pipe(
    takeUntil(this.destroy$),
    map(rs => {
      if (!rs.textOps) {
        return false;
      }
      let contents: any[];
      try {
        contents = JSON.parse(rs.textOps || '[]');
      } catch (e) {
        return true;
      }
      if (!contents) {
        return false;
      }
      let blotName = `attach${capitalize(this.attach.kind)}`;
      let _attachExists = contents.find(
        o => o.insert && o.insert[blotName] && o.insert[blotName].id == this.attach.id
      );
      return !!_attachExists;
    })
  );

  extra: any;

  constructor(
    public ds: DragService,
    private store: Store,
    public sanitizer: DomSanitizer,
    private twitter: NgxTweetService,
    private instagram: NgxInstService,
    private facebook: NgxFcbkService,
    private _elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnInit() {
    if (this.attach.kind === 'youtube') {
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/' + this.attach.embedId
      );
    }

    if (this.attach.kind === 'vimeo') {
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://player.vimeo.com/video/${this.attach.embedId}?color=ff9933&portrait=0`
      );
    }

    if (this.attach.kind === 'teleplay') {
      this.extra = JSON.parse(this.attach.embedExtra);
    }

    this.form = new FormGroup({
      caption: new FormControl(this.attach.caption || ''),
      copyright: new FormControl(this.attach.copyright || ''),
      width: new FormControl(this.attach.width || 'standard'),
    });
    if (this.attach.uploading) {
      this.form.disable({ emitEvent: false });
    }

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500)
      )
      .subscribe(rs => {
        this.store.dispatch(new UpdateAttachMeta(this.attach.id, this.form.value));

        //Todo: Можна взагалі видалили і юзти з стора
        const attachData = {
          attach: this.attach,
          data: rs,
        };
        this.ds.attachChanges.next(attachData);
      });
  }

  dragStart(e: DragEvent) {
    e.dataTransfer.setDragImage(this.createDragBackgroundCover(), 10, 10);
    this.ds.dragAttach.next({
      action: 'start',
      attach: this.attach,
    });
  }

  createDragBackgroundCover() {
    const dragIcon = document.createElement('img');
    dragIcon.src = './assets/drag-and-drop-cover.png';
    dragIcon.width = 200;
    let div = document.createElement('div');
    div.setAttribute('id', 'tsa-drag-image-block');
    div.appendChild(dragIcon);
    div.style.position = 'absolute';
    div.style.left = '-999px';
    div.style.backgroundColor = '#fff';
    document.querySelector('body').appendChild(div);
    // console.log('IMG', img);
    return div;
  }

  dragEnd(e) {
    document.body.querySelector('#tsa-drag-image-block').remove();
    this.ds.dragAttach.next({
      action: 'end',
      attach: this.attach,
    });
  }

  deleteItem() {
    this.store.dispatch(new DeleteAttach(this.attach.id));
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public ngAfterViewInit() {
    if (this.attach.kind === 'twitter') {
      this.twitter
        .loadScript()
        .pipe(takeUntil(this.destroy$))
        .subscribe(rs => {
          let host = this._elementRef.nativeElement.querySelector('.twitter-holder');
          if (host) {
            rs.widgets.createTweet(this.attach.embedId.split('/').pop(), host);
          }
        });
    }

    if (this.attach.kind === 'instagram') {
      let el = this._elementRef.nativeElement.querySelector('.inst-holder');
      el.innerHTML = JSON.parse(this.attach.embedExtra).html;
      this.instagram.reloadInstagramScript();
      this.instagram
        .loadScript()
        .pipe(takeUntil(this.destroy$))
        .subscribe(rs => {
          // console.log('instagram loadScript Loaded', rs);
        });
    }

    if (this.attach.kind === 'facebook') {
      let el = this._elementRef.nativeElement.querySelector('.fcbk-holder');
      el.innerHTML = JSON.parse(this.attach.embedExtra).html;
      this.facebook.loadScript();
      // this.facebook.initScript();
    }
  }
}
