/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./post-preview-full.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./post-preview-full.component";
import * as i3 from "@ngxs/store";
import * as i4 from "@angular/platform-browser";
var styles_PostPreviewFullComponent = [i0.styles];
var RenderType_PostPreviewFullComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PostPreviewFullComponent, data: {} });
export { RenderType_PostPreviewFullComponent as RenderType_PostPreviewFullComponent };
export function View_PostPreviewFullComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Desktop"])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "iframe", [["frameborder", "0"], ["height", "700"], ["width", "800"]], [[8, "src", 5]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["style", "text-align: center; margin-top: 32px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mobile"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "iframe", [["frameborder", "0"], ["height", "667"], ["style", "margin: 0 auto;display: block;"], ["width", "375"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.frameUrl; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.frameUrl; _ck(_v, 5, 0, currVal_1); }); }
export function View_PostPreviewFullComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tsa-post-preview-full", [], null, null, null, View_PostPreviewFullComponent_0, RenderType_PostPreviewFullComponent)), i1.ɵdid(1, 114688, null, 0, i2.PostPreviewFullComponent, [i3.Store, i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PostPreviewFullComponentNgFactory = i1.ɵccf("tsa-post-preview-full", i2.PostPreviewFullComponent, View_PostPreviewFullComponent_Host_0, {}, {}, []);
export { PostPreviewFullComponentNgFactory as PostPreviewFullComponentNgFactory };
