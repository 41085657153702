import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { FeedSettingsFormComponent } from './components/feed-settings-form/feed-settings-form.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { PostPreviewFeedComponent } from './components/post-preview-feed/post-preview-feed.component';
import { PostPreviewFullComponent } from './components/post-preview-full/post-preview-full.component';
import { PostTagsComponent } from './components/post-tags/post-tags.component';
import { SeoSettingsFormComponent } from './components/seo-settings-form/seo-settings-form.component';

@NgModule({
  declarations: [
    FeedSettingsFormComponent,
    FileUploaderComponent,
    SeoSettingsFormComponent,
    PostPreviewFeedComponent,
    PostPreviewFullComponent,
    PostTagsComponent,
  ],
  imports: [SharedModule],
  exports: [
    FeedSettingsFormComponent,
    SeoSettingsFormComponent,
    PostPreviewFeedComponent,
    PostPreviewFullComponent,
    PostTagsComponent,
  ],
})
export class PublicationEditorV2Module {}
