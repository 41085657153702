import { HttpErrorResponse } from '@angular/common/http';
import { CreateSiteDto, SiteCreateResponse, SitesListResponse } from '../../api';

export class LoadSites {
  static readonly type = '[Sites] Load';
}

export class LoadSitesSuccess {
  static readonly type = '[Sites] Load success';
  constructor(public payload: SitesListResponse) {}
}

export class LoadSitesFail {
  static readonly type = '[Sites] Load fail';
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateSite {
  static readonly type = '[Sites] Create';
  constructor(public payload: CreateSiteDto) {}
}

export class CreateSiteSuccess {
  static readonly type = '[Sites] Create success';
  constructor(public payload: SiteCreateResponse) {}
}

export class CreateSiteFail {
  static readonly type = '[Sites] Create fail';
  constructor(public payload: HttpErrorResponse) {}
}
