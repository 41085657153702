import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State } from '@ngxs/store';
import * as Sentry from '@sentry/browser';
import { UsersService } from '../../api';
import { SetAuthResult, SignOut, UserSignInSuccess } from './user.actions';
function setSentryContext(auth) {
    Sentry.configureScope(scope => {
        scope.setUser({
            displayName: auth.displayName,
            email: auth.email,
            id: String(auth.id),
        });
    });
}
let UserState = class UserState {
    constructor(api, router, ngZone) {
        this.api = api;
        this.router = router;
        this.ngZone = ngZone;
    }
    static getToken(state) {
        return state.token;
    }
    static getIsAuth(state) {
        return state.isAuth;
    }
    ngxsOnInit(ctx) {
        let auth = ctx.getState();
        if (auth.isAuth) {
            setSentryContext(auth);
        }
    }
    setAuthResult(ctx, { payload }) {
        ctx.setState(Object.assign({}, payload, { isAuth: true }));
        ctx.dispatch(new UserSignInSuccess());
    }
    loginSuccess() {
        return this.ngZone.run(() => this.router.navigate(['sites']));
    }
    singOut(ctx) {
        ctx.setState({ isAuth: false });
        return this.ngZone.run(() => this.router.navigate(['/']));
    }
};
tslib_1.__decorate([
    Action(SetAuthResult),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetAuthResult]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "setAuthResult", null);
tslib_1.__decorate([
    Action(UserSignInSuccess),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", []),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "loginSuccess", null);
tslib_1.__decorate([
    Action(SignOut),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "singOut", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState, "getToken", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState, "getIsAuth", null);
UserState = tslib_1.__decorate([
    State({
        name: 'user',
        defaults: {
            isAuth: false,
        },
    }),
    tslib_1.__metadata("design:paramtypes", [UsersService, Router, NgZone])
], UserState);
export { UserState };
