import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CurrentSiteState } from '../../state/current-site.state';

@Component({
  selector: 'tsa-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftMenuComponent implements OnInit {
  site = this.store.selectSnapshot(CurrentSiteState.data);
  origin = this.store.selectSnapshot(CurrentSiteState.origin);

  constructor(private store: Store) {}

  ngOnInit() {}
}
