import { Blot } from 'parchment/dist/src/blot/abstract/blot';
import Quill from 'quill';
import { Subject } from 'rxjs';

export interface DropLayerResult {
  node: HTMLElement;
  line: Blot;
  before: boolean;
}

export class DropLayer {
  drop = new Subject<DropLayerResult>();

  constructor(private container: HTMLElement, private quill: Quill) {
    this.createLayer();
  }

  deleteDropZoneLayer() {
    if (window['dontDropLayer']) {
      return;
    }
    let layer = this.container.querySelector('.drop-layer');
    if (layer) {
      layer.remove();
    }
    this.drop.complete();
  }

  _createLayerWrapper() {
    let container = this.container;
    let layer = document.createElement('div');
    layer.className = 'drop-layer';
    container.appendChild(layer);
    return layer;
  }

  createLayer() {
    // console.log('createDropZoneLayer');
    // .root element padding
    let paddingTop = 16;
    let blockMarginBottom = 16;
    let cssTop = paddingTop;
    let layer = this._createLayerWrapper();
    let layerHeight = 0;
    for (let line of this.quill.getLines()) {
      // console.log(line);
      let node = line.domNode as HTMLElement;
      if (node.tagName === 'LI') {
        if (line.prev === null) {
          node = line.parent.domNode;
          line = line.parent;
        } else {
          continue;
        }
      }

      let dropZoneItem = document.createElement('div');
      dropZoneItem.className = 'drop-target';
      dropZoneItem.style.top = cssTop + 'px';

      if (!line.prev) {
        // create drop zone for first child
        dropZoneItem.style.height = node.offsetHeight / 2 + cssTop + 'px';
        dropZoneItem.style.top = '0px';
        cssTop += node.offsetHeight / 2;
      } else {
        let prevNode = line.prev.domNode as HTMLElement;
        let height = node.offsetHeight + prevNode.offsetHeight + 2 * blockMarginBottom;
        dropZoneItem.style.height = height / 2 + 'px';
        cssTop += height / 2;
      }

      dropZoneItem.ondragenter = () => {
        // console.log('ondragenter, drop-zone-before')
        node.classList.remove('drop-zone-after');
        node.classList.add('drop-zone-before');
      };

      dropZoneItem.ondragleave = () => {
        // console.log('ondragleave, drop-zone-before')
        node.classList.remove('drop-zone-before');
      };

      dropZoneItem.ondragover = (ev: DragEvent) => {
        // console.log('ondragover, drop-zone-before')
        ev.preventDefault();
      };

      dropZoneItem.ondrop = ev => {
        ev.preventDefault();
        // console.log('drop before', line);
        // console.log('dragLine', dragLine);

        node.classList.remove('drop-zone-before');
        // node.classList.remove('drop-zone-after');
        this.drop.next({ node, line, before: true });
      };

      layer.appendChild(dropZoneItem);
      layerHeight += dropZoneItem.offsetHeight;
      if (!line.next) {
        // create drop zone for last child
        let dropZoneItem = document.createElement('div');
        dropZoneItem.style.height = node.offsetHeight / 2 + paddingTop + blockMarginBottom + 'px';
        dropZoneItem.style.top = cssTop + 'px';
        dropZoneItem.className = 'drop-target';

        dropZoneItem.ondragenter = () => {
          // console.log('ondrag enter', node.className);
          node.classList.remove('drop-zone-before');
          node.classList.add('drop-zone-after');
        };

        dropZoneItem.ondragleave = () => {
          // console.log('ondrag leave');
          node.classList.remove('drop-zone-after');
        };

        dropZoneItem.ondragover = (ev: DragEvent) => {
          ev.preventDefault();
        };

        dropZoneItem.ondrop = ev => {
          ev.preventDefault();
          // console.log('drop before', line);
          // console.log('dragLine', dragLine);
          this.drop.next({ node, line, before: false });
          node.classList.remove('drop-zone-after');
        };

        layer.appendChild(dropZoneItem);
        layerHeight += dropZoneItem.offsetHeight;
      }
    }

    layer.style.height = layerHeight + 'px';
  }
}
